/* eslint-disable no-unused-vars */
/*
File Name: Registration.js
Author: Dhiraj Rajput
Modified On: 11/12/2022
Description: Registration Form.
API's used:
1) /client/registration put
*/
import React, { useState, useEffect } from "react";
import { Input, Form, Row, Col, Image, Button, Select, message, Radio } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import constants from '../../../constants/constants';
import logo from '../../../logo-blue-black.svg';
import { fetchApi } from "../../../services/api";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { removeExtraSpaces,DOB } from "../../../globalFunctions/GlobalFunctions";
import { useHistory } from "react-router";
import { COOKIE, createCookie } from "../../../services/cookie";
const { Option } = Select;
const Registration = () => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [passwordStatus, setPasswordStatus] = useState("");
	const [confirmPasswordStatus, setConfirmPasswordStatus] = useState("");
	const [form] = Form.useForm();
	const [listCountries, setCountries] = useState([]);
	const [listStates, setListStates] = useState([]);
	const [tempState, setTempState] = useState([]);
	const [styles, setStyles] = useState(null);
	const [clientAdditionalDetails, setClientAdditionalDetails] = useState({});
	const [day, setDay] = useState(null);
	const [month, setMonth] = useState(null);
	const [year, setYear] = useState(null);
	const [gender, setGender] = useState("");
	const [messageApi, contextHolder] = message.useMessage();
	useEffect(() => {
		fetchCountries();
		fetchClientAdditionalData();
	}, []);
	//Function to validate password to check if it has 1 special character, 1 Upper case, 1 number and should have 8 characters
	const handlePasswordValidation = (rule, value, callback) => {
		try {
			if (value) {
				if (value.includes(" ")) {
					setPasswordStatus("error");
					throw new Error(constants.PasswordNoSpace);
				} else if (value.length < 2) {
					setPasswordStatus("error");
					throw new Error(constants.PsdTip);
				}else{
					setPasswordStatus("success");
				}
			} else {
				setPasswordStatus("error");
				throw new Error(constants.PasswordRegistration);
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	const handleDayChange = (value) => {
		setDay(value);
	};
	const handleMonthChange = (value) => {
		setMonth(value);
	};
	const handleYearChange = (value) => {
		setYear(value);
	};
	//To Submit the values from the registration form to the API.
	const onFinish = (values) => {
		let queryString = window.location.href;
		let token = queryString.substring(queryString.lastIndexOf('?') + 1);
		let enquiry_id = token ? atob(token) : "";
		let payload = {};
		payload = {
			firstname: removeExtraSpaces(values.firstName),
			lastname: removeExtraSpaces(values.lastName),
			contact_no: values.contactNo,
			email: values.email,
			login_password: values.password,
			confirm_password: values.confirmPassword,
			country_id: clientAdditionalDetails?.country_id,
			region_id: clientAdditionalDetails?.region_id,
			city: clientAdditionalDetails?.city,
			enquiry_id:enquiry_id,
			parent_name:clientAdditionalDetails?.parent_name ?clientAdditionalDetails?.parent_name:"",
			parent_email:clientAdditionalDetails?.parent_email ?clientAdditionalDetails?.parent_email:"",
			is_minor:clientAdditionalDetails?.is_minor,
			is_scholarship:clientAdditionalDetails?.is_scholarship,
			agreement_duration:clientAdditionalDetails?.agreement_duration,
			dob:`${year}-${month}-${day}`,
			client_type:clientAdditionalDetails?.client_type,
			gender:values?.gender,
			// gender_details:values?.gender_details
		}
		if(year && month && day && clientAdditionalDetails?.client_id === null){
			setLoading(true);
			fetchApi("/client/registration", "post", payload).then((res) => {
				setLoading(false);
				if (res && res.code && res.code === 200) {
					createCookie(COOKIE.ClientId, res?.data?.client_id, null, "/");
					createCookie(COOKIE.FirstName, res?.data?.firstname, null, "/");
					createCookie(COOKIE.LastName, res?.data?.lastname, null, "/");
					createCookie(COOKIE.Token, res?.token, null, "/");
					createCookie(COOKIE.LoginType, "0", null, "/");
					window.location.href = constants.BaseUrl + "/dashboard";
				} else {
					if (res.message) {
						if (res.message.firstname) {
							message.error(res.message.firstname);
						}
						if (res.message.lastname) {
							message.error(res.message.lastname);
						}
						// if (res.message.contact_no) {
						// 	message.error(res.message.contact_no);
						// }
						if (res.message.email) {
							message.error(res.message.email);
						}
						if (res.message.login_password) {
							message.error(res.message.login_password);
						}
						if (res.message.confirm_password) {
							message.error(res.message.confirm_password);
						}
						if (res.message.client_type) {
							message.error(res.message.client_type);
						}
						// if (res.message.city) {
						// 	message.error(res.message.city);
						// }
					}
				}
			});
		}else{
			if(clientAdditionalDetails?.client_id !== null){
				validateRegistrationMessage();
			}
			if(!year && !month && !day){
				message.error(constants.enterdob);
			}
		}
	};
	//Function to check validation errors on click of register button
	const onFinishFailed = (values) => {
		if ((values && values.values && values.values.contactNo === null) ||
			(values && values.values && values.values.contactNo === undefined) ||
			(values && values.values && values.values.contactNo === "")) {
			const stylesValidation = { border: '1px solid rgba(255, 77, 79)', };
			setStyles(stylesValidation);
		}
	};
	const handleValidateName = (rule, value, callback) => {
		try {
			if (value) {
				// eslint-disable-next-line no-useless-escape
				let nameValidate = /[`~@#$%^&*,<>;?:!"\/\[\]\|{}()=+\\]/;
				if (nameValidate.test(value) === true || value.length < 2) {
					throw new Error(constants.FirstNameInvalid);
				}
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	const handleValidateLastName = (rule, value, callback) => {
		try {
			if (value) {
				// eslint-disable-next-line no-useless-escape
				let nameValidate = /[`~@#$%^&*,<>;?:!"\/\[\]\|{}()=+\\]/;
				if (nameValidate.test(value) === true || value.length < 2) {
					throw new Error(constants.LastNameInvalid);
				}
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	//Validate email id
	// const handleCheckEmail = () => {
	// 	let payload = {};
	// 	if (emailIdValue) {
	// 		payload.email = emailIdValue;
	// 	}
	// 	fetchApi("/client/register/check-emails", "post", payload).then((res) => {
	// 		if (res && res.code && res.code === 200) {
	// 			setEmailValidateStatus("success");
	// 			let tempArray = validationErrormessages;
	// 			tempArray[2].IsError = false;
	// 			tempArray[2].errorMessage = "";
	// 			setValidationErrorMessages(tempArray);
	// 		} else {
	// 			setEmailValidateStatus("error");
	// 			message.error(res.message?.email);
	// 			let tempArray = validationErrormessages;
	// 			tempArray[2].IsError = true;
	// 			tempArray[2].errorMessage = res.message?.email;
	// 			setValidationErrorMessages(tempArray);
	// 		}
	// 	});
	// };
	const fetchCountries = () => {
		fetchApi(`/countries`, "get").then((res) => {
			if (res && res.data && res.data.length > 0) {
				setCountries(res.data);
			} else {
				setCountries([]);
			}
		});
	};
	const fetchStates = (country_code) => {
		fetchApi(`/states/${country_code}`, "get").then(
			(res) => {
				if (res?.data) {
					setTempState(res?.data);
					setListStates(res.data);
				} else {
					setListStates(null);
				}
			}
		);
	};
	const selectFilterOption = (input, option) => {
		return option.props.children.toLowerCase().indexOf(input.toLowerCase()) > -1 && option.props.children.toLowerCase().indexOf(input.toLowerCase()) < 1;
	};
	const onChangeCountry = (val,option) => {
		form.setFieldsValue({ contactNo:clientAdditionalDetails?.contact_no });
		fetchStates(option?.key);
		form.setFieldsValue({ state: null });
	};
	const handleValidatePhone = (rule, value, callback) => {
		try {
			if (value) {
				let phoneValidate = /^(?=.{5,})\S*$/;
				if (phoneValidate.test(value) === false) {
					const stylesValidation = { border: '1px solid rgba(255, 77, 79)', };
					setStyles(stylesValidation);
					throw new Error(constants.PhoneNoRegistration);
				} else {
					setStyles(null);
				}
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	//Function to check the contact number and set validation error using css
	const handleContactNo = (value) => {
		if (value === "" || value === null || value === undefined) {
			const stylesValidation = { border: '1px solid rgba(255, 77, 79)', };
			setStyles(stylesValidation);
		} else {
			setStyles(null);
		}
	};
	const handleChangeCity = (rule, value, callback) => {
		try {
			//space not allowed initial and fullstop and hyphen is allowed.
			if (value && !/^[-.]?\S+(\s+\S+)*$/.test(value)) {
				throw new Error(constants.CityRegistration);
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	const fetchClientAdditionalData = () => {
		let queryString = window.location.href;
		let token = queryString.substring(queryString.lastIndexOf('?') + 1);
		let decodetoken = token ? atob(token):"";
		fetchApi(`/parent-info/${decodetoken}`, "get").then((res) => {
			if (res && res.data) {
				let optionTemp = {};
				optionTemp.key = res?.data[0]?.country_code;
				onChangeCountry(null,optionTemp);
				form.setFieldsValue({
					firstName: res?.data[0]?.client_firstname,
					email: res?.data[0]?.client_email,
					contactNo: res?.data[0]?.contact_no,
					lastName: res?.data[0]?.client_lastname,
					city: res?.data[0]?.city,
					country: res?.data[0]?.country_id,
					state: res?.data[0]?.region_id,	
				});
				setClientAdditionalDetails(res?.data[0]);
			} else {
				setClientAdditionalDetails({});
			}
		});
	};
	const onChangeGender = (e) => {
		setGender(e.target.value);
	};

	const validateRegistrationMessage = () => {
    messageApi.open({
      type: 'warning',
      content: (
        constants.AlreadyRegisteredMessage
      ),
      duration: 30,
			style:{ fontSize:"18px" },
			icon:<InfoCircleOutlined className="!text-blue-500" />
    });
  };

	return (
		<div>
			<Row gutter={[0, 16]}>
				<Col xs={24} sm={24} lg={9} className="bg-amber-300 text-yellow-600 capitalize text-3xl font-semibold min-h-screen">
					<div className='mx-2 md:mx-6 md:mt-8'>
						<Image src={logo} alt="RoyCares & Associates" preview={false}/><br/>
						<span>{constants.AnyoneRecover}</span><br/>
						<span>{constants.Tagline}</span>
					</div>
				</Col>
				<Col xs={24} sm={24} lg={15}>
					<div className='mx-2 md:mx-6 md:mt-8'>
						<span className='text-xl lg:text-3xl font-semibold'>{constants.RegisterHeader} {constants.AppName}</span><br/><br/>
						<Form
							layout="vertical"
							className='label-style'
							form={form}
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
						>
							<Row gutter={[24, 0]}>
								<Col xs={24} lg={12}>
									<Form.Item
										name="firstName"
										label={constants.FirstName}
										rules={[
											{
												required: true,
												message: constants.FirstNameRegistration,
												whitespace: true
											},
											{ validator: handleValidateName },
										]}
									>
										<Input autoFocus placeholder={constants.FirstName}/>
									</Form.Item>
								</Col>
								<Col xs={24} lg={12}>
									<Form.Item
										label={constants.LastName}
										name="lastName"
										rules={[
											{
												required: true,
												message: constants.LastNameRegistration,
											},
											{ validator: handleValidateLastName },
										]}>
										<Input placeholder={constants.LastName}/>
									</Form.Item>
								</Col>
							</Row>
							<Form.Item
								name="email"
								hasFeedback
								label={constants.Email}
								rules={[
									{
										required: true,
										message: constants.EmailRegistration,
									},
									{
										type: 'email',
										message: constants.emailVaidationError,
									},
								]}
							>
								<Input
									placeholder={constants.EmailPlaceholder}
								/>
							</Form.Item>
							<Form.Item
								name="password"
								label={constants.RegPassword}
								validateStatus={passwordStatus}
								rules={[
									{
										required: true,
										message: " ",
									},
									{ validator: handlePasswordValidation },
								]}
								hasFeedback
								tooltip={constants.PsdTip}
							>
								<Input.Password placeholder={constants.Password}/>
							</Form.Item>
							<Form.Item
								validateStatus={confirmPasswordStatus}
								name="confirmPassword"
								label={constants.RegConfirmPassword}
								dependencies={["password"]}
								hasFeedback
								rules={[
									{
										required: true,
										message: "",
									},
									({ getFieldValue }) => ({
										validator(_, value) {
											if (getFieldValue("password") === value) {
												setConfirmPasswordStatus("success");
												return Promise.resolve();
											}
											setConfirmPasswordStatus("error");
											return Promise.reject(
												new Error(
													constants.PasswordNotMatch
												)
											);
										},
									}),
								]}
							>
								<Input.Password placeholder={constants.ConfirmPsd}/>
							</Form.Item>
							{/* <Form.Item
								label={constants.country}
								name="country"
								rules={[
									{
										required: true,
										message: constants.CountryRegistration,
									},
								]}
							>
								<Select
									onChange={onChangeCountry}
									placeholder={"Country"}
									className="w-100"
									showSearch
									allowClear
									filterOption={selectFilterOption}>
									{listCountries &&
										listCountries.map((listCountry) => (
											<Option key={listCountry.country_code} value={listCountry.country_id}>
												{listCountry.country_name}
											</Option>
										))}
								</Select>
							</Form.Item> */}
							{/* <Form.Item
								label={constants.state}
								name="state"
								rules={[
									{
										required: true,
										message: constants.StateRegistration,
									},
								]}
							>
								<Select
									placeholder={constants.state}
									allowClear
									showSearch
									filterOption={selectFilterOption}
								>
									{tempState ?
										// eslint-disable-next-line no-unused-vars
										Object.entries(tempState).map(([key, value]) => (
											<>
												{value &&
													value.states &&
													value.states.length > 0 &&
													value.states.map((state) => (
														<Option
															key={state.region_id}
															value={state.region_id}
															region={state.region_id}
														>
															{state.region_name}
														</Option>
													))}
											</>
										)) :
										listStates &&
										// eslint-disable-next-line no-unused-vars
										Object.entries(tempState ? tempState : listStates).map(([key, value]) => (
											<>
												{value &&
													value.states &&
													value.states.length > 0 &&
													value.states.map((state) => (
														<Option
															key={state.region_id}
															value={state.region_id}
															region={state.region_id}
														>
															{state.region_name}
														</Option>
													))}
											</>
										))
									}
								</Select>
							</Form.Item> */}
							{/* <Form.Item
								label={constants.city}
								name="city"
								rules={[
									{
										required: true,
										message: constants.CityRegistration,
									},
									{ validator: handleChangeCity }
								]}
							>
								<Input
									placeholder={constants.city}
									// onChange={handleChangeCity}
									type="text"
								// value={cityMessage}
								/>
							</Form.Item> */}
							{/* <Form.Item
								label={constants.phone}
								name="contactNo"
								rules={[
									{
										required: true,
										message: constants.PhoneNoRegistration,
									},
									{ validator: handleValidatePhone },
								]}
							>
								<PhoneInput preferredCountries={["us","in"]} className='w-full' country={"in"} style={styles} onChange={handleContactNo} placeholder="1(999)123-4567"/>
							</Form.Item> */}
							{DOB(month,handleMonthChange,day,handleDayChange,year,handleYearChange,constants.dob)}
							<Form.Item
								label={constants.Gender}
								name="gender"
								rules={[
									{
										required: true,
										message: constants.GenderVaidationMessage,
									}
								]}
							>
								<Radio.Group onChange={onChangeGender} value={gender}>
									<Radio value={constants.Female}>{constants.Female}</Radio>
									<Radio value={constants.Male}>{constants.Male}</Radio>
									{/* <Radio value={constants.Other}>{constants.Other}</Radio> */}
								</Radio.Group>
							</Form.Item>
							{/* {gender === "Other" && (
								<Form.Item
									name="gender_details"
								>
									<Input />
								</Form.Item>
							)} */}
							<Form.Item>
								<Button
									size="large"
									className='bg-sky-800 text-white uppercase'
									htmlType="submit"
									loading={loading}
								>
									{constants.RegisterBTN}
								</Button>
							</Form.Item>
						</Form>
					</div>
				</Col>
			</Row>
			{contextHolder}
		</div>
	);
};
export default Registration;
