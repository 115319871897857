import React, { useEffect, useRef, useState } from 'react';
import { Radio, Form, Input, Button, Checkbox, Card, Space, Popconfirm, message, Spin, Select, Divider, InputNumber } from 'antd';
import constants from '../../../constants/constants';
import { fetchAdminApi, fetchApi } from '../../../services/api';
import { DOB } from '../../../globalFunctions/GlobalFunctions';
import { COOKIE, getCookie } from '../../../services/cookie';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { PlusOutlined } from '@ant-design/icons';
import EditableCheatSheet from '../CheatSheet/CheatSheet';
import { useHistory } from "react-router";
import TextArea from "antd/es/input/TextArea";
const { Option } = Select;

// Custom components
const custom_select = (allowClear, placeholder, mode, options, onAdd, value, onChange, inputRef, style, onFieldChange) => {
    return (
        <Select
            allowClear={allowClear}
            placeholder={placeholder}
            mode={mode}
            style={style}
            onChange={onFieldChange}
            dropdownRender={(menu) => (
                <>
                    {menu}
                    <Divider style={{ margin: '8px 0' }} />
                    <Space style={{ padding: '0 8px 4px' }}>
                        <Input
                            placeholder="Please enter option."
                            ref={inputRef}
                            value={value}
                            onChange={onChange}
                            onKeyDown={(e) => e.stopPropagation()}
                        />
                        <Button type="text" icon={<PlusOutlined />} onClick={onAdd}>
                            Add
                        </Button>
                    </Space>
                </>
            )}
            options={options
                .slice()
                .sort((a, b) => a.localeCompare(b))
                .map((item) => ({
                    label: item,
                    value: item,
                }))}
        />
    );
};

const AddNote = (props) => {
    const [form] = Form.useForm();
    const history = useHistory();
    const loginType = getCookie(COOKIE.LoginType);
    const adminClientValuefromSession = JSON.parse(sessionStorage.getItem("adminClientValue"));
    const [loader, setLoader] = useState(false);
    const [enquiryClientDetails, setEnquiryClientDetails] = useState(JSON.parse(sessionStorage.getItem("enquiryDetails"))||[]);
    const [pronoun, setPronoun] = useState("She/He");
    const [possPronoun, setPossPronoun] = useState("His/Her");
    const [beVerb, setBeVerb] = useState("is");
    const [past, setPast] = useState("s");
    // Notes
    const [noteValue, setNoteValue] = useState(null);
    const [noteTypeValue, setNoteTypeValue] = useState("0");
    const [sessionChecked, setSessionChecked] = useState(false);
    const [isNoteAnswered, setIsNoteAnswered] = useState(false);
    const notesHeader = ["DATA:", "ASSESSMENT:", "PLAN:"];
    // Field state changes
    const [isDrug1Selected, setIsDrug1Selected] = useState(false);
    const [isDrug4Selected, setIsDrug4Selected] = useState(false);
    const [isFamily1Selected, setIsFamily1Selected] = useState(null);
    const [isFamily6Selected, setIsFamily6Selected] = useState(null);
    const [isFamily8Selected, setIsFamily8Selected] = useState(false);
    const [isFamily11Selected, setIsFamily11Selected] = useState(false);
    const [isFamily14Selected, setIsFamily14Selected] = useState(false);
    const [isEmotional1Selected, setIsEmotional1Selected] = useState(false);
    const [isEmotional9Selected, setIsEmotional9Selected] = useState(false);
    const [isEmotional10Selected, setIsEmotional10Selected] = useState(false);
    const [isEmotional11Selected, setIsEmotional11Selected] = useState(false);
    const [isEmotional12Selected, setIsEmotional12Selected] = useState(false);
    const [isMedical4Selected, setIsMedical4Selected] = useState(false);
    const [isMedical6Selected, setIsMedical6Selected] = useState(false);
    const [isMedical8Selected, setIsMedical8Selected] = useState(false);
    const [selectedSubstances, setSelectedSubstances] = useState({});
    const [selectedReasons, setSelectedReasons] = useState({});
    const [selectedAttempts, setSelectedAttempts] = useState({});

    // Select Options
    const [listAssociates, setListAssociates] = useState([]);
    const [maritalStatus, setMaritalStatus] = useState(['Single', 'Divorced', 'Married']);
    const [newMaritalStatus, setNewMaritalStatus] = useState('');
    const maritalStatusInputRef = useRef(null);
    const addMaritalStatus = () => {
        if (!newMaritalStatus) return;
        setMaritalStatus([...maritalStatus, newMaritalStatus]);
        setNewMaritalStatus('');
        setTimeout(() => maritalStatusInputRef.current?.focus(), 0);
    };
    const [ethnicity, setEthnicity] = useState(constants.EthnicityList);
    const [newEthnicity, setNewEthnicity] = useState('');
    const ethnicityInputRef = useRef(null);
    const addEthnicity = () => {
        if (!newEthnicity) return;
        setEthnicity([...ethnicity, newEthnicity]);
        setNewEthnicity('');
        setTimeout(() => ethnicityInputRef.current?.focus(), 0);
    };
    const [religion, setReligion] = useState(constants.ReligionList);
    const [newReligion, setNewReligion] = useState('');
    const religionInputRef = useRef(null);
    const addReligion = () => {
        if (!newReligion) return;
        setReligion([...religion, newReligion]);
        setNewReligion('');
        setTimeout(() => religionInputRef.current?.focus(), 0);
    };
    const [assessment, setAssessment] = useState(['CAGE', 'DSM-V', 'MMMH']);
    const [newAssessment, setNewAssessment] = useState('');
    const assessmentInputRef = useRef(null);
    const addAssessment = () => {
        if (!newAssessment) return;
        setAssessment([...assessment, newAssessment]);
        setNewAssessment('');
        setTimeout(() => assessmentInputRef.current?.focus(), 0);
    };
    const [disorder, setDisorder] = useState(['Substance Use Disorder', 'Pathological Gambling']);
    const [newDisorder, setNewDisorder] = useState('');
    const disorderInputRef = useRef(null);
    const addDisorder = () => {
        if (!newDisorder) return;
        setDisorder([...disorder, newDisorder]);
        setNewDisorder('');
        setTimeout(() => disorderInputRef.current?.focus(), 0);
    };
    const [angerDeppressed, setAngerDepressed] = useState(['Adjustment disorders with Depressed Mood', 'Adjustment disorders with Anxiety', 'Adjustment disorders with Mixed Anxiety and Depressed Mood', 'Adjustment disorders (Unspecified)', 'Parent Child Conflict', 'Partner Relational Conflict']);
    const [newAngerDeppressed, setNewAngerDeppressed] = useState('');
    const angerDeppressedInputRef = useRef(null);
    const addAngerDeppressed = () => {
        if (!newDisorder) return;
        setAngerDepressed([...disorder, newDisorder]);
        setNewAngerDeppressed('');
        setTimeout(() => disorderInputRef.current?.focus(), 0);
    };
    const [birthOrder, setBirthOrder] = useState(['oldest', 'youngest', 'only', 'middle child']);
    const [newBirthOrder, setNewBirthOrder] = useState('');
    const birthOrderInputRef = useRef(null);
    const addBirthOrder = () => {
        if (!newBirthOrder) return;
        setBirthOrder([...birthOrder, newBirthOrder]);
        setNewBirthOrder('');
        setTimeout(() => birthOrderInputRef.current?.focus(), 0);
    };
    const [living, setLiving] = useState(['living alone', 'with spouse/partner and children', 'in traditional joint family with partner and parents and siblings']);
    const [newLiving, setNewLiving] = useState('');
    const livingInputRef = useRef(null);
    const addLiving = () => {
        if (!newLiving) return;
        setLiving([...living, newLiving]);
        setNewLiving('');
        setTimeout(() => livingInputRef.current?.focus(), 0);
    };
    const [identity, setIdentity] = useState(['Heterosexual', 'Bisexual']);
    const [newIdentity, setNewIdentity] = useState('');
    const identityInputRef = useRef(null);
    const addIdentity = () => {
        if (!newIdentity) return;
        setIdentity([...identity, newIdentity]);
        setNewIdentity('');
        setTimeout(() => identityInputRef.current?.focus(), 0);
    };
    const [mentalD, setMentalD] = useState(constants.MentalHealth);
    const [newMentalD, setNewMentalD] = useState('');
    const mentalDInputRef = useRef(null);
    const addMentalD = () => {
        if (!newMentalD) return;
        setMentalD([...mentalD, newMentalD]);
        setNewMentalD('');
        setTimeout(() => mentalDInputRef.current?.focus(), 0);
    };
    const [symptoms, setSymptoms] = useState(['severe distress like negative impact on work, home and social life', 'loss of interest in activities', 'problems sleeping', 'loss of appetite', 'sadness', 'hopelessness', 'headaches', 'gastrointestinal problems', 'panic attacks', 'heart palpitations', 'cold sweats', 'worry', 'fatigue', 'nervousness']);
    const [newSymptoms, setNewSymptoms] = useState('');
    const symptomsInputRef = useRef(null);
    const addSymptoms = () => {
        if (!newSymptoms) return;
        setSymptoms([...symptoms, newSymptoms]);
        setNewSymptoms('');
        setTimeout(() => symptomsInputRef.current?.focus(), 0);
    };
    const attempts = [
        "cut down use of substances/gambling",
        "feeling angry if spoken to about use",
        "feeling guilty about use",
        "having used substances/gambled early morning in order to start the day",
    ];

    // Date of notes
    const current = new Date();
    const [noteDate, setNoteDate] = useState({
        date: current.getDate(),
        month: (current.getMonth() + 1).toString().padStart(2, '0'),
        year: current.getFullYear()
    });
    const handleNoteDayChange = (value) => {
        setNoteDate({
            ...noteDate,
            date: value
        });
    };
    const handleNoteMonthChange = (value) => {
        setNoteDate({
            ...noteDate,
            month: value
        });
    };
    const handleNoteYearChange = (value) => {
        setNoteDate({
            ...noteDate,
            year: value
        });
    };

    useEffect(() => {
        getForm(props && props.location && props.location.state && props.location.state.notes_type ? props.location.state.notes_type : noteTypeValue);
        if (adminClientValuefromSession?.clientId) {
            fetchClientDetails(adminClientValuefromSession?.clientId);
        } else {
            if(props?.path !== "/pre-assessment-summary") {
                fetchEnquiryDetails(adminClientValuefromSession?.enquiryId)
            };
        }
        getListAssociates();
    }, []);

    useEffect(() => {
        setFormFields(props && props.location && props.location.state && props.location.state.answers ? props.location.state.answers : null);
    }, []);

    const getForm = (formType) => {
        setNoteTypeValue(formType);
        setNoteValue(["", "", ""]);
        setFormFields(props && props.location && props.location.state && props.location.state.answers ? props.location.state.answers : null);
    };

    const setFormFields = (userans = null) => {
        if(userans !== null){
            handlePronounChange(userans?.gender);
            form.setFieldsValue(userans);
            setSelectedReasons(userans?.selectedreasons);
            setSelectedSubstances(userans?.selectedsubstances);
            setSelectedAttempts(userans?.selectedattempts);
            setIsEmotional1Selected(userans?.emotional_1 === "denies");
            setIsEmotional10Selected(userans?.emotional_10 === "never received");
            setIsEmotional11Selected(userans?.emotional_11 === "was not");
            setIsEmotional12Selected(userans?.emotional_12 === "no medications");
            setIsEmotional9Selected(userans?.emotional_9 === "denies");
            setIsDrug1Selected(userans?.drug_1 === 'denies any');
            setIsDrug4Selected(userans?.drug_4 === 'never been');
            setIsFamily1Selected(userans?.family_1 === 'only');
            setIsFamily6Selected(userans?.family_6 === "has no");
            setIsFamily8Selected(userans?.family_8 === 'current');
            setIsFamily11Selected(userans?.family_11 === "works as a");
            setIsFamily14Selected(userans?.family_14 === "does no");
            setIsMedical4Selected(userans?.medical_4 === "Allergies");
            setIsMedical6Selected(userans?.medical_6 === "illnesses/disease" || userans?.medical_7);
            setIsMedical8Selected(userans?.medical_8 === "medications/OTCs");
            if(userans?.medical_7) form.setFieldsValue({ medical_6: 'illnesses/disease' });
        }else{
            let type_source = adminClientValuefromSession?.clientId ? enquiryClientDetails?.enquiry_info : enquiryClientDetails;
            const sourceOption = constants.SourceOption.find(
                (option) => option.value === String(type_source?.source)
            );
            const sourceLabel = type_source?.source === "8" || type_source?.source === "9" ? type_source?.source_details : sourceOption?.label;
            form.setFieldsValue({
                first_name: enquiryClientDetails?.client_firstname,
                age: enquiryClientDetails?.age,
                location: enquiryClientDetails?.city + ", " + enquiryClientDetails?.region_name + ", " + enquiryClientDetails?.country_name,
                gender: enquiryClientDetails?.gender,
                source: sourceLabel,
            });
        }
    }

    // Fetching enquiry data
    const fetchEnquiryDetails = (enquiry_id) => {
        setLoader(true);
        let payload = { enquiry_id: enquiry_id };
        fetchApi(`/get-enquiry`, "post", payload)
            .then((res) => {
                if (res?.data) {
                    setEnquiryClientDetails(res.data);
                    setIsNoteAnswered(res?.data?.is_note_answered === 1);
                } else {
                    message.error(res.message);
                    setEnquiryClientDetails({});
                }
            })
            .catch(() => {
                message.error("Failed to fetch enquiry details");
                setEnquiryClientDetails({});
            })
            .finally(() => {
                setLoader(false);
            });
    };

    // Fetching client data
    const fetchClientDetails = (client_id) => {
        setLoader(true);
        fetchAdminApi(`/client-profile/${client_id}`, "get")
            .then((res) => {
                if (res?.data) {
                    setEnquiryClientDetails(res.data);
                    const gender = res?.data?.gender?.toLowerCase();
                    handlePronounChange(gender);
                    setIsNoteAnswered(res?.data?.is_note_answered === 1);
                } else {
                    setEnquiryClientDetails([]);
                }
            })
            .catch(() => {
                message.error("Failed to fetch client details");
                setEnquiryClientDetails([]);
            })
            .finally(() => {
                setLoader(false);
            });
    };

    const handlePronounChange = (value) => {
        if (value) {
            const gender = value?.toLowerCase();
            if (gender === "male") {
                setPronoun("He")
                setPossPronoun("His")
                setBeVerb("is")
                setPast("s")
            } else if (gender === "female") {
                setPronoun("She");
                setPossPronoun("Her")
                setBeVerb("is")
                setPast("s")
            } else {
                setPronoun("They");
                setPossPronoun("Their")
                setBeVerb("are")
                setPast("")
            }
        } else {
            setPronoun("She/He")
            setPossPronoun("His/Her")
            setBeVerb("is")
            setPast("")
        }
    }

    const lowPronoun = pronoun.toLowerCase();
    const lowPossPronoun = possPronoun.toLowerCase();

    const hideModal = () => {
        history.push({ pathname: '/notes' });
    };
    const onchangeNoteValue = (e, index) => {
        noteValue[index] = e;
    };
    const onSessionChange = (value) => {
        setSessionChecked(value);
    }
    const getListAssociates = () => {
        fetchAdminApi(`/view-associates`, "post").then((res) => {
            if (res?.data) {
                const firstItem = res?.data?.filter(obj => obj?.client_id === "1")[0];
                const remainingItems = res?.data?.filter(obj => obj?.client_id !== "1");
                let sortedData;
                if (firstItem) {
                    sortedData = [firstItem, ...remainingItems];
                } else {
                    sortedData = remainingItems
                }
                setListAssociates(sortedData);
            }
            else {
                setListAssociates([]);
            }
        });
    }
    const selectFilterOption = (input, option) => {
        return option.props.children.toLowerCase().indexOf(input.toLowerCase()) > -1 && option.props.children.toLowerCase().indexOf(input.toLowerCase()) < 1;
    };

    const createNote = (value, type=null, isPreAssessment = null, onSubmitComplete) => {
        let userAnswerValue = value;
        if (isPreAssessment === null) {
            setLoader(true);
            if (noteTypeValue !== "0") {
                if (selectedReasons) userAnswerValue.selectedreasons = selectedReasons;
                if (selectedSubstances) userAnswerValue.selectedsubstances = selectedSubstances;
                if (selectedAttempts) userAnswerValue.selectedattempts = selectedAttempts;
            }
        }
        let s_substance = isPreAssessment === null ? selectedSubstances : value?.selectedsubstances;
        let s_attempts = isPreAssessment === null ? selectedAttempts : value?.selectedattempts;
        let s_reasons = isPreAssessment === null ? selectedReasons : value?.selectedreasons;
        let n_type = isPreAssessment === null ? noteTypeValue : value?.patient_problem;
        const formValues = {
            first_name: value?.first_name || "_________",
            disorder: value?.disorder && value?.disorder?.length > 0 ? value?.disorder?.join(", ") : "Substance Use Disorder/Pathological Gambling",
            age: value?.age || "____",
            gender: value?.gender || "male/female",
            location: value?.location || "______________",
            source: value?.source || "______________",
            assignto: value?.assignto || "_________",
            plan_1: value?.plan_1 || "await the appointment/consider options",
            plan_2: value?.plan_2 && value?.plan_2?.length > 0 ? value?.plan_2?.join(", ") : `complete a medical or wellness checkup/detox/have a Psych assessment with Dr. Ashish Deshpande/sign and complete the Coaching Agreement and Payment process`,
            assess_1: value?.assess_1 || "ambivalent/motivated",
            assess_2: value?.assess_2 && value?.assess_2?.length > 0 ? value?.assess_2?.join(", ") : "CAGE/DSM-V/MMMH",
            assess_3: value?.assess_3 || "appears/does not appear",
            assess_4: value?.assess_4 || "appropriate/not appropriate",
            intro_1: value?.intro_1 || "single/divorced/married",
            intro_2: value?.intro_2 || "Asian Indian/White/Black/Hispanic",
            intro_3: value?.intro_3 || "Hindu/Muslim/Christian/Buddhist/Jewish _________ (other)",
            addiction_1: value?.addiction_1 || "denied/reported",
            addiction_2: value?.addiction_2 || "Denies/Reports",
            addiction_3: value?.addiction_3 && value?.addiction_3?.length > 0 && value?.addiction_3?.join(", "),
            drug_1: value?.drug_1 || "denies/reports",
            drug_2: value?.drug_2 && value?.drug_2?.length > 0 ? value?.drug_2?.join(", ") : `□ has felt the need to bet more and more money, and has □ lied to people about how much ${lowPronoun} gamble${past}`,
            drug_3: value?.drug_3 || "Denies/Reports",
            drug_4: value?.drug_4 || "been/never been",
            drug_5: value?.drug_5 || "_________",
            drug_6: value?.drug_6 || "_________",
            drug_7: value?.drug_7 || "no/some",
            drug_8: value?.drug_8 || "no/some",
            drug_9: value?.drug_9 || "_________ days/months/years",
            drug_10: value?.drug_10 || "_________",
            drug_11: value?.drug_11 && value?.drug_11?.length > 0 ? value?.drug_11?.join(", ") : "substance use/gambling",
            problem_1: value?.problem_1 || "online/in-person",
            problem_2: value?.problem_2 || "_________",
            problem_3: value?.problem_3 || "_________",
            emotional_1: value?.emotional_1 || "denies/reports",
            emotional_2: value?.emotional_2 && value?.emotional_2?.length > 0 ? value?.emotional_2?.join(", ") : "anxiety/depression/mood disorder/bipolar disorder _________ (other)",
            emotional_3: value?.emotional_3 || "_________",
            emotional_4: value?.emotional_4 || "_________",
            emotional_5: value?.emotional_5 || "_________",
            emotional_6: value?.emotional_6 || "_________ (details)",
            emotional_7: value?.emotional_7 || "_________",
            emotional_8: value?.emotional_8 && value?.emotional_8?.length > 0 ? value?.emotional_8?.join(", ") : "severe distress like negative impact on work, home and social life; loss of interest in activities; problems sleeping, loss of appetite, sadness, hopelessness, headaches, gastrointestinal problems, panic attacks, heart palpitations, cold sweats, worry, fatigue and nervousness",
            emotional_9: value?.emotional_9 || "denies/reports",
            emotional_10: value?.emotional_10 || "received/never received",
            emotional_11: value?.emotional_11 || "was/was not",
            emotional_12: value?.emotional_12 || "medication/no medications",
            // medical_1: value?.medical_1 || "Heterosexual/Bisexual/LGBTQ",
            medical_2: value?.medical_2 || "good/poor",
            medical_3: value?.medical_3 || "_________",
            medical_4: value?.medical_4 || "Allergies/no Allergies ______________",
            medical_5: value?.medical_5 || "______________",
            medical_6: value?.medical_6 || "no/______________ illnesses/disease",
            medical_7: value?.medical_7 || "______________",
            medical_8: value?.medical_8 || "no/______________ medications/OTCs",
            medical_9: value?.medical_9 || "______________",
            medical_10: value?.medical_10 && value?.medical_10?.length > 0 ? value?.medical_10?.join(" and ") : constants.DietaryHabbitsPlaceHolder,
            family_1: value?.family_1 || "_________",
            family_2: value?.family_2 || "____",
            family_3: value?.family_3 || "□living alone □with spouse/partner and children □ in traditional joint family with partner and parents and siblings",
            family_4: value?.family_4 || "fair/good/poor",
            family_5: value?.family_5 || "fair/good/poor",
            family_6: value?.family_6 || "has/has no",
            family_7: value?.family_7 || "_________",
            family_8: value?.family_8 || "no/current",
            family_9: value?.family_9 || "_________",
            family_10: value?.family_10 || "______________ (Education)",
            family_11: value?.family_11 || "is unemployed/retired/owns business/works as a _________",
            family_12: value?.family_12 || "______________",
            family_13: value?.family_13 || "active/inactive",
            family_14: value?.family_14 || "does/does no",
            family_15: value?.family_15 || "______________",
            children_name: value?.children_name,
            spouse_name: value?.spouse_name,
            parent_name: value?.parent_name,
        };

        const drugsPayload = Object.entries(s_substance)
            .filter(([, value]) => value.checked)
            .map(([substance, { age }]) => {
                const ageDisplay = age || "_____";
                return `<h3><b>${substance}</b> at age ${ageDisplay}</h3>`;
            })
            .join("");
        const drugPart1Payload = `<h3>${n_type !== "3" ? ", gradually increasing amounts to achieve the desired effect over a longer period of time than intended, despite problems in the past 12 months with" : "However, " + lowPronoun + " reported problems in the past 12 months with"}</h3>`;
        const reasonsPayload = constants.Reasons
            .filter((reason) => s_reasons[reason.key]?.checked)
            .map((reason) => {
                if (Array.isArray(constants.ReasonValues[reason.key])) {
                    const checkedCauses = constants.ReasonValues[reason.key]
                        .filter((cause) => s_reasons[reason.key]?.causes?.[cause]);
                    return `<h3><b>${reason.reason}</b>: ${checkedCauses.length ? checkedCauses.join(", ") : "______"}</h3>`;
                } else {
                    const cause = s_reasons[reason.key]?.cause || "______";
                    return `<h3><b>${reason.reason}</b>: ${cause}</h3>`;
                }
            })
            .join("");

        const drugPart4Payload = ', caused or exacerbated by use of substances, including reduced involvement in social activities.' + (Object.values(s_attempts).some(isChecked => isChecked) ? ' ' + pronoun + ' reported several attempts to' : '');
        const attemptsPayload = Object.entries(s_attempts)
            .filter(([, isChecked]) => isChecked)
            .map(([attempt]) => ` ${attempt}`)
            .join(",");
        const drugsEndPayload = `<p><br></p><h3>${formValues.first_name} ${formValues.drug_1} gambling activity${formValues.drug_1 === 'denies any' ? "" : " and " + formValues.drug_2}. ${formValues.drug_3} family of origin ${formValues.drug_11} problems. Client reports ${lowPronoun} has ${formValues.drug_4} previously treated for addiction${formValues.drug_4 !== "never been" ? ((formValues.drug_5 !== "_________" ? " at " + formValues.drug_5 : "") + (formValues.drug_5 !== "_________" && formValues.drug_6 !== "_________" ? " and" : "") + (formValues.drug_6 !== "_________" ? " with " + formValues.drug_6 : "")) : ""}; has ${formValues.drug_7} recovering friends; ${formValues.drug_8} prior 12-step program experience and longest period of abstinence is ${formValues.drug_9}.</h3>`;

        const planPayload = `<h3>Client was informed that ${lowPronoun} ${beVerb} required to ${formValues.plan_2} prior to the commencement of coaching. ${formValues.first_name} said ${lowPronoun} would complete the paperwork, ${formValues.plan_1}, and then call back.</h3>`;
        const assessmentPayload = `<h3>${formValues.first_name} presented as well-groomed and reported performing daily functions regularly. ${pronoun} ${beVerb} ${formValues.assess_1} about coaching and was informed that based on the ${formValues.assess_2} assessment screening instruments, ${lowPronoun} ${formValues.assess_3} to meet ICD criteria for ${formValues.disorder} disorder and is ${formValues.assess_4} for coaching and will be referred to ${formValues.assignto}.</h3>`;
        const addictionPayload = `<h3><em><u>Addiction/Recovery History:</u></em></h3><h3>${pronoun} ${formValues.addiction_1} personal history of substance abuse${formValues.addiction_3 ? (" (other than occasional " + formValues.addiction_3 + " use of alcohol)") : ""}. ${formValues.addiction_2} gambling activity. ${n_type !== "3" ? `${pronoun} stated that, to the best ${lowPossPronoun} recollection, ${lowPossPronoun} family member ${formValues.problem_2} had begun to use` : ""}</h3>${n_type !== "3" ? drugsPayload : ""}${drugPart1Payload}${reasonsPayload}${n_type !== "3" ? `<h3>${drugPart4Payload + attemptsPayload + (attemptsPayload ? "." : "")}</h3>${drugsEndPayload}` : ""}`;
        const introPayload = `<h3>${formValues.first_name} is a ${formValues.age}-year-old ${formValues.intro_1}, ${formValues.intro_2}, ${formValues.intro_3}, ${formValues.gender} from ${formValues.location}, who was referred for an assessment by ${formValues.source}.</h3>`;
        const problemPayload = `<h3><em><u>${n_type === "1" ? "Presenting Problem/Addiction/Recovery History:" : "Presenting Problem:"}</u></em></h3><h3>${n_type === "1" ? ("Client was seen " + formValues.problem_1 + ", and during " + lowPossPronoun + " assessment, outlined " + lowPossPronoun + " substance abuse history. " + formValues.first_name + " reported that " + lowPronoun + " had begun to use" + drugsPayload + drugPart1Payload + reasonsPayload + drugPart4Payload + attemptsPayload + drugsEndPayload) : ""}${n_type === "3" ? ("Client was seen " + formValues.problem_1 + ", and during " + lowPossPronoun + " assessment, stated concerns about " + lowPossPronoun + " emotional status and reported feeling angry, anxious, and depressed within the past 12 months.") : ""}${n_type === "2" ? ("Client was seen " + formValues.problem_1 + ", and during " + lowPossPronoun + " assessment, stated concerns about " + lowPossPronoun + " family member " + formValues.problem_2 + "'s " + formValues.disorder + ". " + pronoun + " report" + past + " feeling angry, anxious, and depressed about " + formValues.problem_2 + "'s " + formValues.disorder + " and report" + past + " several attempts to help " + formValues.problem_2 + " abstain within the past " + formValues.problem_3 + " and feel" + past + " frustrated at " + lowPossPronoun + " inability do so.") : ""}</h3>`;
        const emotionalPayload = `<h3><em><u>Mental/Emotional Health:</u></em></h3><h3>Client ${formValues.emotional_1} MH history${formValues.emotional_1 === 'denies' ? "" : (", was diagnosed with " + formValues.emotional_2 + " and " + formValues.emotional_10 + " mental health treatment " + (formValues.emotional_10 === "never received" ? "" : ("from " + formValues.emotional_3 + " ")) + "and " + formValues.emotional_11 + " hospitalized for mental illness " + (formValues.emotional_11 !== "was not" ? formValues.emotional_4 : "") + " and is on " + formValues.emotional_12 + (formValues.emotional_12 === "no medications" ? "" : (" (" + formValues.emotional_5 + ")")))}. ${formValues.first_name} ${formValues.emotional_9} a history of SI (Suicidal Ideation) or HI (Homicidal Ideation). ${formValues.emotional_9 === 'denies' ? "" : (formValues.emotional_6 + " ")}${(n_type !== "3" && n_type !== "1") ? ("However, " + lowPronoun + " reports feeling angry, anxious and depressed about " + formValues.emotional_7 + "’s " + formValues.disorder + " including symptoms of " + formValues.emotional_8 + ". " + pronoun + " reported that this has led to problems at home (arguments and differences of opinion). ") : (n_type !== "3" ? (pronoun + " reported that in the last 12 months, " + lowPronoun + " has experienced symptoms of severe distress like " + formValues.emotional_8 + ", which has led to interference and problems in daily life. ") : "")}</h3>`;
        const medicalPayload = `<h3><em><u>Medical History:</u></em></h3><h3>${formValues.first_name} identifies as ${formValues.medical_1} and is in ${formValues.medical_2} health, has a primary care physician and last physical was on: ${formValues.medical_3}. Reports ${formValues.medical_4} ${(formValues.medical_4 === "Allergies") ? ("(" + formValues.medical_5 + ") ") : ""}and ${formValues.medical_6}${(formValues.medical_6 === "illnesses/disease") ? (" (" + formValues.medical_7 + ")") : ""}. Currently taking ${formValues.medical_8}${(formValues.medical_8 === "medications/OTCs") ? (" (" + formValues.medical_9 + ")") : ""}. Reports eating ${formValues.medical_10}.</h3>`;
        
        const familyPayload =
         `<h3><em><u>Family/General:</u></em></h3><h3>Client is the ${formValues.family_1 === 'only' ? (formValues.family_1 + ' child') : (formValues.family_1 + " of " + formValues.family_2 + " children")} and is ${formValues.family_3}. ${pronoun} reports ${formValues.family_4} 
         relations with family of origin (parents/siblings) (${formValues?.parent_name}); ${formValues.family_1 === 'only' ? "" : (" and " + formValues.family_5 + ` relations with nuclear family (Spouse/partner) (${formValues?.spouse_name})`)} and ${formValues.family_6} children${formValues?.family_6 === "has no" ? "" : (" (" + formValues?.children_name + " "+formValues?.family_7 + ")")}.</h3><h3>${formValues.first_name} reports ${formValues.family_8} legal issues${(formValues.family_8 === "current") ? (" (" + formValues.family_9 + ")") : ""}; has completed ${formValues.family_10} and ${formValues.family_11} ${(formValues.family_11 === "works as a") ? formValues.family_12 : ""}. ${pronoun} is ${formValues.family_13} and ${formValues.family_14} exercise, hobbies, recreational interests, sports, leisure activities ${(formValues.family_14 === "does no") ? "" : "like " + formValues.family_15} in ${lowPossPronoun} free time.</h3>`;
        const dataPayload = `${introPayload}<p><br></p>${problemPayload}<p><br></p>${n_type !== "1" ? (addictionPayload + "<p><br></p>") : ""}${emotionalPayload}<p><br></p>${medicalPayload}<p><br></p>${familyPayload}`;

        let admin_id = getCookie(COOKIE.ClientId);
        let payload = {
            notes_content: noteValue[0] ? "<b><u>DATA:</u></b>" + " " + noteValue[0] : n_type !== "0" ? "<b><u>DATA:</u></b>" + " " + dataPayload : "",
            notes_assesment: noteValue[1] ? "<b><u>ASSESSMENT:</u></b>" + " " + noteValue[1] : n_type !== "0" ? "<b><u>ASSESSMENT:</u></b>" + " " + assessmentPayload : "",
            notes_plan: noteValue[2] ? "<b><u>PLAN:</u></b>" + " " + noteValue[2] : n_type !== "0" ? "<b><u>PLAN:</u></b>" + " " + planPayload : "",
            section_id: isPreAssessment === null ? null: "0",
            notes_type: n_type,
            dt_created: `${noteDate.year}-${noteDate.month}-${noteDate?.date}`,
            answers:userAnswerValue
        };
        if (isPreAssessment === null) {
            payload.admin_id = admin_id
            if (adminClientValuefromSession?.clientId) {
                payload.client_id = adminClientValuefromSession?.clientId;
            } else {
                payload.enquiry_id = adminClientValuefromSession?.enquiryId;
            }
        }else{
            payload.client_id = adminClientValuefromSession?.client_id;
            payload.enquiry_id = adminClientValuefromSession?.enquiry_info?.enquiry_id;
        }
        if (sessionChecked === true) {
            payload.session_checked = sessionChecked;
        }
        if (type === "esign") {
            payload.signed_by = admin_id;
        }
        let url, method;
        if(props?.path === "/edit-note"){
            url = "/edit-notes";
            method = "put";
            payload.notes_id = props && props.location && props.location.state && props.location.state.notes_id
        }else{
          url = "/add-notes";
          method = "post";
        }
        if (noteValue !== null) {
            fetchAdminApi(url, method, payload).then((res) => {
                if (res?.code && res.code === 200) {
                    if(isPreAssessment===null){
                        setNoteDate({
                            ...noteDate,
                            date: current.getDate(),
                            month: String(current.getMonth() + 1),
                            year: current.getFullYear()
                        });
                        message.success(res.message);
                        hideModal();
                        setLoader(false);
                    }else{
                        onSubmitComplete(true);
                    }
                } else {
                    if(isPreAssessment===null){
                        message.error(res.message);
                        setLoader(false);
                    }else{
                        onSubmitComplete(false);
                    }
                }
            });
        }
    };

    // Individual Field onChange functions
    const handleDrug1Change = (value) => {
        setIsDrug1Selected(value === 'denies any');
    };
    const handleDrug4Change = (value) => {
        setIsDrug4Selected(value === 'never been');
    };
    const handleFamily1Change = (value) => {
        setIsFamily1Selected(value === 'only');
    };
    const handleFamily6Change = (value) => {
        setIsFamily6Selected(value === "has no");
    };
    const handleFamily8Change = (value) => {
        setIsFamily8Selected(value === 'current');
    };
    const handleFamily11Change = (value) => {
        setIsFamily11Selected(value === "works as a");
    };
    const handleFamily14Change = (value) => {
        setIsFamily14Selected(value === "does no");
    };
    const handleEmotional1Change = (value) => {
        setIsEmotional1Selected(value === "denies");
    };
    const handleEmotional9Change = (value) => {
        setIsEmotional9Selected(value === "denies");
    };
    const handleEmotional10Change = (value) => {
        setIsEmotional10Selected(value === "never received");
    };
    const handleEmotional11Change = (value) => {
        setIsEmotional11Selected(value === "was not");
    };
    const handleEmotional12Change = (value) => {
        setIsEmotional12Selected(value === "no medications");
    };
    const handleMedical4Change = (value) => {
        setIsMedical4Selected(value === "Allergies");
    };
    const handleMedical6Change = (value) => {
        setIsMedical6Selected(value === "illnesses/disease");
    };
    const handleMedical8Change = (value) => {
        setIsMedical8Selected(value === "medications/OTCs");
    };
    // Handle checkbox change for substance
    const handleCheckboxSubChange = (substance, isChecked) => {
        setSelectedSubstances((prev) => ({
            ...prev,
            [substance]: { ...prev[substance], checked: isChecked },
        }));
    };
    // Handle checkbox change for reason
    const handleCheckboxReaChange = (reason, isChecked) => {
        setSelectedReasons((prev) => ({
            ...prev,
            [reason]: { ...prev[reason], checked: isChecked, cause: isChecked ? prev[reason]?.cause || constants.ReasonValues[reason] : "", },
        }));
    };
    // Handle checkbox change for attempts
    const handleCheckboxAttemptChange = (attempt, isChecked) => {
        setSelectedAttempts((prev) => ({
			...prev,
			[attempt]: { ...prev[attempt], checked: isChecked },
		}));
    };
    // Handle input change for age
    const handleAgeChange = (substance, age) => {
        setSelectedSubstances((prev) => ({
            ...prev,
            [substance]: { ...prev[substance], age },
        }));
    };
    const handleGamblingChange = (substance, value) => {
        setSelectedSubstances((prev) => (
		{
			...prev,
			[substance]: { ...prev[substance], textValue: value },
		}));
    };
    // Handle input change for cause
    const handleCauseChange = (reason, cause) => {
        setSelectedReasons((prev) => ({
            ...prev,
            [reason]: { ...prev[reason], cause },
        }));
    };
    const handleNestedCauseChange = (reason, cause, checked) => {
        setSelectedReasons((prevSelectedReasons) => ({
            ...prevSelectedReasons,
            [reason]: {
                ...prevSelectedReasons[reason],
                causes: {
                    ...prevSelectedReasons[reason]?.causes,
                    [cause]: checked
                }
            }
        }));
    };

    // Individual forms
    const introForm = () => {
        return (
            <Space wrap align="center" className="">
                <Form.Item name={"first_name"}>
                    <Input placeholder={constants.FirstName} />
                </Form.Item>
                {"is a"}
                <Form.Item name="age">
                    <Input type="number" placeholder="Age" />
                </Form.Item>
                {"year old"}
                <Form.Item name="intro_1">
                    {custom_select(true, "single/divorced/married", null, maritalStatus, addMaritalStatus, newMaritalStatus, (e) => setNewMaritalStatus(e.target.value), maritalStatusInputRef)}
                </Form.Item>
                {",\u00A0\u00A0"}
                <Form.Item name="intro_2">
                    {custom_select(true, "Asian Indian/White/Black/Hispanic", null, ethnicity, addEthnicity, newEthnicity, (e) => setNewEthnicity(e.target.value), ethnicityInputRef)}
                </Form.Item>
                {",\u00A0\u00A0"}
                <Form.Item name="intro_3">
                    {custom_select(true, constants.ReligionPlaceHolder, null, religion, addReligion, newReligion, (e) => setNewReligion(e.target.value), religionInputRef)}
                </Form.Item>
                {",\u00A0\u00A0"}
                <Form.Item name="gender">
                    <Select placeholder="Male/Female" allowClear onChange={handlePronounChange}>
                        <Option value="Male">Male</Option>
                        <Option value="Female">Female</Option>
                    </Select>
                </Form.Item>
                {"from"}
                <Form.Item name="location">
                    <Input placeholder="Location" className='min-w-[200px]' />
                </Form.Item>
                {"who was referred for an assessment by"}
                <Form.Item name="source">
                    <Input placeholder="Referral Source" />
                </Form.Item>
                {"."}
            </Space>
        )
    }

    const drugsForm = () => {
        return (
            <div className='flex flex-col'>
                {noteTypeValue !== "3" && (<Space direction="vertical" className="mb-3">
                    {constants.Substances.map((substance) => (
                        <Form.Item key={substance.key} className="mb-3">
                            <div className='h-[36px] flex items-center'>
                                <Checkbox
                                    onChange={(e) => handleCheckboxSubChange(substance.key, e.target.checked)}
                                    checked={selectedSubstances[substance.key]?.checked ? true : false }
                                >
                                    <p className='text-[14px]'><b>{substance.substances}</b></p>
                                </Checkbox>
                                {selectedSubstances[substance.key]?.checked && (
                                    <>
                                        <b className='text-[14px] font-normal mr-2'>{"at age"}</b>
                                        <InputNumber
                                            value={selectedSubstances[substance.key]?.age ? selectedSubstances[substance.key]?.age : ""}
                                            placeholder="Enter age"
                                            min={1}
                                            style={{ width: 100 }}
                                            onChange={(value) => handleAgeChange(substance.key, value)}
                                        />
                                    </>
                                )}
                            </div>
                            {substance.key === "gambling" &&<p className='text-[14px] ml-5'>Type of gambling:</p>}
                            {
								substance.key === "gambling" && selectedSubstances?.[substance.key]?.checked || substance.key === "other" && selectedSubstances?.[substance.key]?.checked ?
									<TextArea
										value={selectedSubstances?.[substance.key]?.textValue}
										onChange={(value) => handleGamblingChange(substance.key, value.target.value)}
									/> :
									<p className="text-blue-500 text-[14px]	ml-5">{substance.key === "gambling" || substance.key === "other" ? "":"-"}{constants.SubstancesAliases[substance.key]}</p>
							}
                        </Form.Item>
                    ))}
                </Space>)}
                <Space wrap align="center" className="mb-3">
                    {noteTypeValue !== "3" ? `, gradually increasing amounts to achieve the desired effect over a longer period of time than intended, despite problems in the past 12 months with` : `However, ${lowPronoun} reported problems in the past 12 months with`}
                </Space>
                <Space direction="vertical" className="mb-5">
                    {constants.Reasons.map((reason) => (
                        <Form.Item key={reason.key}>
                            <Checkbox
                                onChange={(e) => handleCheckboxReaChange(reason.key, e.target.checked)}
                                checked={selectedReasons[reason.key]?.checked ? true : false }
                            >
                                <p className="text-[14px]">{reason.reason}</p>
                            </Checkbox>
                            {selectedReasons[reason.key]?.checked && !Array.isArray(constants.ReasonValues[reason.key]) && (
                                <Input
                                    defaultValue={selectedReasons[reason.key]?.cause || constants.ReasonValues[reason.key]}
                                    value={selectedReasons[reason.key]?.cause ? selectedReasons[reason.key]?.cause : ""}
                                    placeholder="Enter cause"
                                    style={{ width: 600 }}
                                    onChange={(e) => handleCauseChange(reason.key, e.target.value)}
                                />
                            )}
                            {selectedReasons[reason.key]?.checked && Array.isArray(constants.ReasonValues[reason.key]) && (
                                <Space direction="vertical">
                                    {constants.ReasonValues[reason.key].map((cause) => (
                                        <Checkbox
                                            key={cause}
                                            checked={selectedReasons[reason.key]?.causes?.[cause] || false}
                                            onChange={(e) => handleNestedCauseChange(reason.key, cause, e.target.checked)}
                                        >
                                            <p className="text-[14px] font-normal">{cause}</p>
                                        </Checkbox>
                                    ))}
                                </Space>
                            )}
                        </Form.Item>
                    ))}
                </Space>

                {noteTypeValue !== "3" ? <>
                    <Space wrap align="center" className="mb-3">
                        {`, caused or exacerbated by use of substances, including reduced involvement in social activities. ${pronoun} reported several attempts to`}
                    </Space>
                    <Space wrap align="center" className="mb-7">
                        {constants.CageScreening.map((attempt) => (
                            <Form.Item key={attempt.key} >
                                <Checkbox
                                    checked={selectedAttempts?.[attempt.key] ? selectedAttempts[attempt.key] : false  || false}
                                    onChange={(e) => handleCheckboxAttemptChange(attempt.key, e.target.checked)}
                                >
                                    <p className="text-[14px] font-medium text-black">{attempt.label}</p>
                                </Checkbox>
                            </Form.Item>
                        ))}
                    </Space>
                    <Space wrap align="center" className="mb-3">
                        <Form.Item name={"first_name"}>
                            <Input placeholder={constants.FirstName} />
                        </Form.Item>
                        <Form.Item name="drug_1" >
                            <Select placeholder="denies/reports" allowClear onChange={handleDrug1Change}>
                                <Option value="denies any">denies</Option>
                                <Option value="reports">reports</Option>
                            </Select>
                        </Form.Item>
                        {`gambling activity`}
                        {!isDrug1Selected && (<>
                            {`and`}
                            <Form.Item name="drug_2">
                                <Select title={`has felt the need to bet more and more money, and has lied to people about how much ${lowPronoun} gamble${past}`} placeholder={`has felt the need to bet more and more money, and has lied to people about how much ${lowPronoun} gamble${past}`} mode="tags" style={{ minWidth: '400px', }}>
                                    <Option value="has felt the need to bet more and more money">has felt the need to bet more and more money</Option>
                                    <Option value={`has lied to people about how much ${lowPronoun} gamble${past}.`}>has lied to people about how much {lowPronoun} gamble{past}</Option>
                                </Select>
                            </Form.Item>
                        </>)}
                        {"."}
                    </Space>
                    <Space wrap align="center" className="mb-3">
                        <Form.Item name="drug_3">
                            <Select placeholder="Denies/Reports" allowClear>
                                <Option value="Denies">Denies</Option>
                                <Option value="Reports">Reports</Option>
                            </Select>
                        </Form.Item>
                        {`family of origin`}
                        <Form.Item name="drug_11">
                            <Select placeholder="substance use/gambling" mode="tags" style={{ minWidth: '200px', }}>
                                <Option value="substance use">substance use</Option>
                                <Option value="gambling">gambling</Option>
                            </Select>
                        </Form.Item>
                        {`problems.`}
                    </Space>
                    <Space wrap align="center" className="mb-3">
                        {`Client reports ${lowPronoun} has`}
                        <Form.Item name="drug_4">
                            <Select placeholder="been/never been" allowClear onChange={handleDrug4Change}>
                                <Option value="been">been</Option>
                                <Option value="never been">never been</Option>
                            </Select>
                        </Form.Item>
                        {`previously treated for addiction`}
                        {!isDrug4Selected && <>
                            {`at`}
                            <Form.Item name={"drug_5"}>
                                <Input placeholder={"Enter Rehab"} />
                            </Form.Item>
                            {`and with`}
                            <Form.Item name={"drug_6"}>
                                <Input placeholder={"Enter Therapist"} />
                            </Form.Item>
                        </>}
                        {`;\u00A0\u00A0 has`}
                        <Form.Item name="drug_7">
                            <Select placeholder="no/some" allowClear>
                                <Option value="no">no</Option>
                                <Option value="some">some</Option>
                            </Select>
                        </Form.Item>
                        {`recovering friends;`}
                        <Form.Item name="drug_8">
                            <Select placeholder="no/some" allowClear>
                                <Option value="no">no</Option>
                                <Option value="some">some</Option>
                            </Select>
                        </Form.Item>
                        {`prior 12-step program experience and longest period of abstinence is`}
                        <Form.Item name={"drug_9"}>
                            <Input placeholder={"days/months/years"} />
                        </Form.Item>
                        {"."}
                    </Space>
                </> : null}
            </div>
        )
    }

    const presentingProblemForm = () => {
        return (
            <>
                <div className='mt-5 mb-4 italic'><b><u>{noteTypeValue === "1" ? "Presenting Problem/Addiction/Recovery History:" : "Presenting Problem:"}</u></b></div>
                <Space wrap align="center" className="mb-3">
                    {noteTypeValue === "1" && <>
                        {"Client was seen"}
                        <Form.Item name={"problem_1"}>
                            <Select placeholder="online/in-person" allowClear>
                                <Option value="online">online</Option>
                                <Option value="in-person">in-person</Option>
                            </Select>
                        </Form.Item>
                        {`and during ${lowPossPronoun} assessment, outlined ${lowPossPronoun} substance abuse history.`}
                        <Form.Item name={"first_name"}>
                            <Input placeholder={constants.FirstName} />
                        </Form.Item>
                        {`reported that ${lowPronoun} had begun to use`}
                    </>
                    }
                    {noteTypeValue === "3" && <>
                        {`Client was seen`}
                        <Form.Item name={"problem_1"}>
                            <Select placeholder="online/in-person" allowClear>
                                <Option value="online">online</Option>
                                <Option value="in-person">in-person</Option>
                            </Select>
                        </Form.Item>
                        {`, and during ${lowPossPronoun} assessment, stated concerns about ${lowPossPronoun} emotional status`}
                        {`and reported feeling angry, anxious, and depressed within the past 12 months.`}
                    </>
                    }
                    {noteTypeValue === "2" && <>
                        {`Client was seen`}
                        <Form.Item name={"problem_1"}>
                            <Select placeholder="online/in-person" allowClear>
                                <Option value="online">online</Option>
                                <Option value="in-person">in-person</Option>
                            </Select>
                        </Form.Item>
                        {`, and during ${lowPossPronoun} assessment, stated concerns about ${lowPossPronoun} family member`}
                        <Form.Item name={"problem_2"}>
                            <Input placeholder={"Name or Relation"} />
                        </Form.Item>
                        {"'s"}
                        <Form.Item name="disorder">
                            {custom_select(false, "Substance Use Disorder/Pathological Gambling", "tags", disorder, addDisorder, newDisorder, (e) => setNewDisorder(e.target.value), disorderInputRef, { minWidth: '400px', })}
                        </Form.Item>
                        {"."}
                        {`${pronoun} report${past} feeling angry, anxious, and depressed about`}
                        <Form.Item name={"problem_2"}>
							{custom_select(false, "Angry/Anxious/Depressed", "tags", angerDeppressed, addAngerDeppressed, newAngerDeppressed, (e) => setNewAngerDeppressed(e.target.value), angerDeppressedInputRef, { minWidth: '400px', })}
                        </Form.Item>
                        {"'s"}
                        <Form.Item name="disorder">
                            {custom_select(false, "Substance Use Disorder/Pathological Gambling", "tags", disorder, addDisorder, newDisorder, (e) => setNewDisorder(e.target.value), disorderInputRef, { minWidth: '400px', })}
                        </Form.Item>
                        {`and report${past} several attempts to help`}
                        <Form.Item name={"problem_2"}>
                            <Input placeholder={"Name or Relation"} />
                        </Form.Item>
                        {'abstain within the past'}
                        <Form.Item name={"problem_3"}>
                            <Input placeholder={"days/months/years"} />
                        </Form.Item>
                        {`and feel${past} frustrated at ${lowPossPronoun} inability do so.`}
                    </>
                    }
                </Space>
                {noteTypeValue === "1" ? drugsForm() : null}
            </>
        )
    }

    const addictionForm = () => {
        return (
            <>
                <div className='mt-5 mb-4 italic'><b><u>Addiction/Recovery History:</u></b></div>
                <Space wrap align="center" className="mb-3">
                    {pronoun}
                    <Form.Item name="addiction_1">
                        <Select placeholder="denied/reported" allowClear>
                            <Option value="denied any">denied</Option>
                            <Option value="reported">reported</Option>
                        </Select>
                    </Form.Item>
                    {`personal history of substance abuse (other than occasional`}
                    <Form.Item name="addiction_3">
                        <Select placeholder="social/religious" mode='tags' style={{ minWidth: '150px', }}>
                            <Option value="social">social</Option>
                            <Option value="religious">religious</Option>
                        </Select>
                    </Form.Item>
                    {`use of alcohol).`}
                    <Form.Item name="addiction_2">
                        <Select placeholder="Denies/Reports" allowClear>
                            <Option value="Denies any">Denies</Option>
                            <Option value="Reports">Reports</Option>
                        </Select>
                    </Form.Item>

                    {`gambling activity.`}
                    {noteTypeValue !== "3" && (
                        <>
                            {`${pronoun} stated that, to the best ${lowPossPronoun} recollection, ${lowPossPronoun} family member`}
                            <Form.Item name="problem_2">
                                <Input placeholder="Name or Relation" />
                            </Form.Item>
                            {`had begun to use`}
                        </>
                    )}
                </Space>
                {drugsForm()}
            </>
        )
    }

    const emotionalForm = () => {
        return (
            <>
                <div className='mt-5 mb-4 italic'><b><u>Mental/Emotional Health:</u></b></div>
                <Space wrap align="center" className="mb-3">
                    {"Client"}
                    <Form.Item name="emotional_1">
                        <Select placeholder="denies/reports" allowClear onChange={handleEmotional1Change}>
                            <Option value="denies">denies</Option>
                            <Option value="reports">reports</Option>
                        </Select>
                    </Form.Item>
                    {"MH history"}
                    {!isEmotional1Selected && (<>{", was diagnosed with"}
                        <Form.Item name="emotional_2">
                            {custom_select(false, "anxiety/depression/mood disorder/bipolar disorder", 'tags', mentalD, addMentalD, newMentalD, (e) => setNewMentalD(e.target.value), mentalDInputRef, { minWidth: '200px', })}
                        </Form.Item>
                        {"and"}
                        <Form.Item name="emotional_10">
                            <Select placeholder="received/never received" allowClear onChange={handleEmotional10Change}>
                                <Option value="received">received</Option>
                                <Option value="never received">never received</Option>
                            </Select>
                        </Form.Item>
                        {"mental health treatment"}
                        {!isEmotional10Selected && (<>
                            {"from"}
                            <Form.Item name={"emotional_3"}>
                                <Input placeholder={"Therapist"} />
                            </Form.Item>
                        </>)}
                        {"and"}
                        <Form.Item name="emotional_11">
                            <Select placeholder="was/was not" allowClear onChange={handleEmotional11Change}>
                                <Option value="was">was</Option>
                                <Option value="was not">was not</Option>
                            </Select>
                        </Form.Item>
                        {"hospitalized for mental illness"}
                        {!isEmotional11Selected && (<Form.Item name={"emotional_4"}>
                            <Input placeholder={"mental illness"} />
                        </Form.Item>)}
                        {"and is on"}
                        <Form.Item name="emotional_12">
                            <Select placeholder="medication/no medications" allowClear onChange={handleEmotional12Change}>
                                <Option value="medication">medication</Option>
                                <Option value="no medications">no medications</Option>
                            </Select>
                        </Form.Item>
                        {!isEmotional12Selected && (<Form.Item name={"emotional_5"}>
                            <Input placeholder={"medication"} />
                        </Form.Item>)}
                    </>)}
                    {".\u00A0\u00A0"}
                </Space>
                <Space wrap align="center" className="mb-3">
                    <Form.Item name={"first_name"}>
                        <Input placeholder={constants.FirstName} />
                    </Form.Item>
                    <Form.Item name="emotional_9">
                        <Select placeholder="denies/reports" allowClear onChange={handleEmotional9Change}>
                            <Option value="denies">denies</Option>
                            <Option value="reports">reports</Option>
                        </Select>
                    </Form.Item>
                    {"a history of SI (Suicidal Ideation) or HI (Homicidal Ideation)."}
                    {!isEmotional9Selected && (<Form.Item name={"emotional_6"}>
                        <Input placeholder="Details" />
                    </Form.Item>)}
                </Space>
                {noteTypeValue !== "3" && (
                    noteTypeValue !== "1" ? (
                        <>
                            <Space wrap align="center" className="mb-3">
                                {`However, ${lowPronoun} reports feeling angry, anxious and depressed about`}
                                <Form.Item name={"emotional_7"}>
                                    <Input placeholder={"Name or Relation"} />
                                </Form.Item>
                                {`’s`}
                                <Form.Item name="disorder">
                                    {custom_select(false, "Substance Use Disorder/Pathological Gambling", "tags", disorder, addDisorder, newDisorder, (e) => setNewDisorder(e.target.value), disorderInputRef, { minWidth: '400px', })}
                                </Form.Item>
                                {`including symptoms of`}
                                <Form.Item name="emotional_8">
                                    {custom_select(false, "symptoms", "tags", symptoms, addSymptoms, newSymptoms, (e) => setNewSymptoms(e.target.value), symptomsInputRef, { minWidth: '200px', })}
                                </Form.Item>
                                {`.`}
                            </Space>
                            <Space wrap align="center" className="mb-3">
                                {`${pronoun} reported that this has led to problems at home (arguments and differences of opinion).`}
                            </Space>
                        </>
                    ) : (<Space wrap align="center" className="mb-3">
                        {`${pronoun} reported that in the last 12 months, ${lowPronoun} has experienced symptoms of severe distress like`}
                        <Form.Item name="emotional_8">
                            {custom_select(false, "symptoms", "tags", symptoms, addSymptoms, newSymptoms, (e) => setNewSymptoms(e.target.value), symptomsInputRef, { minWidth: '200px', })}
                        </Form.Item>
                        {", which has led to interference and problems in daily life."}
                    </Space>))}
            </>
        )
    }

    const medicalForm = () => {
        return (
            <>
                <div className='mt-5 mb-4 italic'><b><u>Medical History:</u></b></div>
                <Space wrap align="center" className="mb-6">
                    <Form.Item name={"first_name"}>
                        <Input placeholder={constants.FirstName} />
                    </Form.Item>
                    {"identifies as"}
                    {"and is in"}
                    <Form.Item name="medical_2">
                        <Select placeholder="good/poor" allowClear>
                            <Option value="good">good</Option>
                            <Option value="poor">poor</Option>
                        </Select>
                    </Form.Item>
                    {"health , has a primary care physician and last physical was on:"}
                    <Form.Item name={"medical_3"}>
                        <Input placeholder={"date"} />
                    </Form.Item>
                    {". Reports"}
                    <Form.Item name="medical_4">
                        <Select placeholder="Allergies/no Allergies" allowClear onChange={handleMedical4Change}>
                            <Option value="Allergies">Allergies</Option>
                            <Option value="no Allergies">no Allergies</Option>
                        </Select>
                    </Form.Item>
                    {isMedical4Selected && (
                        <Form.Item name={"medical_5"}>
                            <Input placeholder={"allergies"} />
                        </Form.Item>
                    )}
                    {"and"}
                    <Form.Item name="medical_6">
                        <Select placeholder="illnesses/no illnesses" allowClear onChange={handleMedical6Change}>
                            <Option value="illnesses/disease">illnesses/diseases</Option>
                            <Option value="no illnesses/disease">no illnesses/diseases</Option>
                        </Select>
                    </Form.Item>
                    {isMedical6Selected && (
                        <Form.Item name={"medical_7"}>
                            <Input placeholder={"illnesses/disease"} />
                        </Form.Item>
                    )}
                    {". Currently taking"}
                    <Form.Item name="medical_8">
                        <Select placeholder="no/medications/OTCs" allowClear onChange={handleMedical8Change}>
                            <Option value="medications/OTCs">medications/OTCs</Option>
                            <Option value="no medications/OTCs">no medications/OTCs</Option>
                        </Select>
                    </Form.Item>
                    {isMedical8Selected && (
                        <Form.Item name={"medical_9"}>
                            <Input placeholder={"medications/OTCs"} />
                        </Form.Item>
                    )}
                    {". Reports eating"}
                    <Form.Item name="medical_10">
                        <Select placeholder={constants.DietaryHabbitsPlaceHolder} mode="multiple" style={{ minWidth: '200px', }}>
                            {
                                constants.DietaryHabbits.map((diet) => {
                                    return <Option key={diet.key} value={diet.key}>{diet.label}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    {"."}
                </Space>
            </>
        )
    }

    const familyForm = () => {
        return (
            <>
                <div className='mt-5 mb-4 italic'><b><u>Family/General:</u></b></div>
                <Space wrap align="center" className="mb-3">
                    {"Client is the"}
                    <Form.Item name={"family_1"}>
                        {custom_select(true, "birth order", null, birthOrder, addBirthOrder, newBirthOrder, (e) => setNewBirthOrder(e.target.value), birthOrderInputRef, { minWidth: '200px', }, handleFamily1Change)}
                    </Form.Item>
                    {isFamily1Selected ? "child and is" : (<>
                        {'of'}
                        <Form.Item name={"family_2"}>
                            <InputNumber min='2'
                                placeholder={"total"}
                                formatter={(value) => (value === 0 ? "no" : value)}
                                parser={(value) => value === "no" ? 0 : Number(value)}
                            />
                        </Form.Item>
                        {"children and is"}
                    </>)}
                    <Form.Item name="family_3">
                        {custom_select(true, "living alone/with spouse/partner & children/joint family", null, living, addLiving, newLiving, (e) => setNewLiving(e.target.value), livingInputRef)}
                    </Form.Item>
                    {"."}
                </Space>

                <Space wrap align="center" className="mb-3">
                    {`${pronoun} reports`}
                    <Form.Item name="family_4">
                        <Select placeholder="fair/good/poor" allowClear>
                            <Option value="fair">fair</Option>
                            <Option value="good">good</Option>
                            <Option value="poor">poor</Option>
                        </Select>
                    </Form.Item>
                    {`relations with family of origin (parents/siblings);`}
                    <Form.Item name="parent_name">
                        <Input placeholder="Name of the parents/siblings" />
                    </Form.Item>
                    {!isFamily1Selected && (<>
                        {`and`}
                        <Form.Item name="family_5">
                            <Select placeholder="fair/good/poor" allowClear>
                                <Option value="fair">fair</Option>
                                <Option value="good">good</Option>
                                <Option value="poor">poor</Option>
                            </Select>
                        </Form.Item>
                        {`relations with nuclear family (spouse/partner)`}
                        <Form.Item name="spouse_name">
                            <Input placeholder="Name of the spouse/partner" />
                        </Form.Item>
                    </>)}
                    {`and`}
                    <Form.Item name="family_6">
                        <Select placeholder="has/has no" allowClear onChange={handleFamily6Change}>
                            <Option value="has">has</Option>
                            <Option value="has no">has no</Option>
                        </Select>
                    </Form.Item>
                    {`children`}
                    {!isFamily6Selected ? (<>
                        <Form.Item name="children_name">
                            <Input placeholder="Name of the children" />
                        </Form.Item>
                        <Form.Item name={"family_7"}>
                            <Input placeholder={"children's ages"} />
                        </Form.Item>
                    </>) : null}
                    {"."}
                </Space>
                <Space wrap align="center" className="mb-6">
                    <Form.Item name={"first_name"}>
                        <Input placeholder={constants.FirstName} />
                    </Form.Item>
                    {"reports"}
                    <Form.Item name="family_8">
                        <Select placeholder="no/current" allowClear onChange={handleFamily8Change}>
                            <Option value="no">no</Option>
                            <Option value="current">current</Option>
                        </Select>
                    </Form.Item>
                    {"legal issues"}
                    {isFamily8Selected && (
                        <Form.Item name={"family_9"}>
                            <Input placeholder={"legal issues"} />
                        </Form.Item>
                    )}
                    {";\u00A0\u00A0 has completed"}
                    <Form.Item name={"family_10"}>
                        <Input placeholder={"education"} />
                    </Form.Item>
                    {"and"}
                    <Form.Item name="family_11">
                        <Select placeholder="is unemployed/retired/owns business/works" allowClear onChange={handleFamily11Change}>
                            <Option value="is unemployed">is unemployed</Option>
                            <Option value="is retired">is retired</Option>
                            <Option value="owns business">owns business</Option>
                            <Option value="works as a">works as a</Option>
                        </Select>
                    </Form.Item>
                    {isFamily11Selected && (
                        <Form.Item name={"family_12"}>
                            <Input placeholder={"profession"} />
                        </Form.Item>
                    )}
                    {`. ${pronoun} is`}
                    <Form.Item name="family_13">
                        <Select placeholder="active/inactive" allowClear>
                            <Option value="active">active</Option>
                            <Option value="inactive">inactive</Option>
                        </Select>
                    </Form.Item>
                    {'and'}
                    <Form.Item name="family_14">
                        <Select placeholder="does/does no" allowClear onChange={handleFamily14Change}>
                            <Option value="does">does</Option>
                            <Option value="does no">does no</Option>
                        </Select>
                    </Form.Item>
                    {'exercise, hobbies, recreational interests, sports, leisure activities'}
                    {!isFamily14Selected && (
                        <>
                            {'like'}
                            <Form.Item name="family_15">
                                <Input placeholder="activities" />
                            </Form.Item>
                        </>
                    )}
                    {`in ${lowPossPronoun} free time.`}
                </Space>
            </>
        )
    }

    // Main Forms
    const formData = () => {
        return (
            <div className='text-[14px] mb-4'>
                <div className='text-lg mb-4'><b><u>{notesHeader[0]}</u></b></div>
                {introForm()}
                {presentingProblemForm()}
                {noteTypeValue !== "1" ? addictionForm() : null}
                {emotionalForm()}
                {medicalForm()}
                {familyForm()}
            </div>
        )
    }

    const formAssessment = () => {
        return (
            <div>
                <div className='text-lg mb-4'><b><u>{notesHeader[1]}</u></b></div>
                <Space wrap align="center" className='text-[14px] mb-6'>
                    <Form.Item name={"first_name"}>
                        <Input placeholder={constants.FirstName} />
                    </Form.Item>
                    {`presented as well-groomed and reported performing daily functions regularly. ${pronoun} ${beVerb}`}
                    <Form.Item name="assess_1">
                        <Select placeholder="ambivalent/motivated" allowClear>
                            <Option value="ambivalent">ambivalent</Option>
                            <Option value="motivated">motivated</Option>
                        </Select>
                    </Form.Item>
                    {"about coaching and was informed that based on the"}
                    <Form.Item name="assess_2">
                        {custom_select(false, "CAGE/DSM-V/MMMH", "tags", assessment, addAssessment, newAssessment, (e) => setNewAssessment(e.target.value), assessmentInputRef, { minWidth: '200px', })}
                    </Form.Item>
                    {`assessment screening instruments, ${lowPronoun}`}
                    <Form.Item name="assess_3">
                        <Select placeholder={`appears/does not appear`} allowClear>
                            <Option value={`appears`}>appears</Option>
                            <Option value={`does not appear`}>does not appear</Option>
                        </Select>
                    </Form.Item>
                    {"to meet ICD criteria for"}
                    <Form.Item name="disorder">
                        {custom_select(false, "Substance Use Disorder/Pathological Gambling", "tags", disorder, addDisorder, newDisorder, (e) => setNewDisorder(e.target.value), disorderInputRef, { minWidth: '400px', })}
                    </Form.Item>
                    {"and is"}
                    <Form.Item name="assess_4">
                        <Select placeholder="appropriate/not appropriate" allowClear>
                            <Option value="appropriate">appropriate</Option>
                            <Option value="not appropriate">not appropriate</Option>
                        </Select>
                    </Form.Item>
                    {"for coaching and will be referred to"}
                    <Form.Item name="assignto">
                        <Select
                            placeholder={constants.AssignedTo}
                            showSearch
                            allowClear
                            filterOption={selectFilterOption}
                            className='min-w-[200px]'
                        >
                            {
                                listAssociates?.map((associate) => <Option value={associate?.firstname + ' ' + associate?.lastname} key={associate?.client_id}>{associate?.firstname + ' ' + associate?.lastname}</Option>)
                            }
                        </Select>
                    </Form.Item>
                    {"."}
                </Space>
            </div>
        )
    };

    const formPlan = () => {
        return (
            <>
                <div className='text-lg mb-4'><b><u>{notesHeader[2]}</u></b></div>
                <Space wrap align="center" className='text-[14px]'>
                    {`Client was informed that ${lowPronoun} ${beVerb} required to`}
                    <Form.Item name="plan_2">
                        <Select
                            title="complete a medical or wellness checkup/detox/have a Psych assessment with Dr. Ashish Deshpande/sign and complete the Coaching Agreement and Payment process"
                            placeholder="complete a medical or wellness checkup/detox/have a Psych assessment with Dr. Ashish Deshpande/sign and complete the Coaching Agreement and Payment process"
                            mode="tags"
                            style={{ minWidth: '400px', }}
                        >
                            <Option value="complete a medical or wellness checkup">complete a medical or wellness checkup</Option>
                            <Option value="detox">detox</Option>
                            <Option value="have a Psych assessment with Dr. Ashish Deshpande">have a Psych assessment with Dr. Ashish Deshpande</Option>
                            <Option value="sign and complete the Coaching Agreement and Payment process">sign and complete the Coaching Agreement and Payment process</Option>
                        </Select>
                    </Form.Item>
                    {`prior to the commencement of coaching.`}
                    <Form.Item name={"first_name"}>
                        <Input placeholder={constants.FirstName} />
                    </Form.Item>
                    {`said ${lowPronoun} would complete the paperwork,`}
                    <Form.Item name="plan_1">
                        <Select placeholder="await the appointment/consider options" allowClear>
                            <Option value="await the appointment">await the appointment</Option>
                            <Option value="consider options">consider options</Option>
                        </Select>
                    </Form.Item>
                    {",\u00A0 and then call back."}
                </Space>
            </>
        )
    };

    const handleEsign = () => {
        let formvalue = form.getFieldsValue();
        createNote(formvalue, "esign");
    };

    const EditorForm = () => {
        const noteValueRefs = noteValue?.map(() => React.createRef());
        const handleFocus = (index) => {
            const quill = noteValueRefs[index].current.getEditor();
            const selection = quill.getSelection();
            if (!selection || selection.length === 0) {
                quill.format('header', 3);
            }
        };

        return (
            <>
                {noteValue?.map((value, index) => (
                    <div>
                        <div className='text-lg'><b><u>{notesHeader[index]}</u></b></div>
                        <ReactQuill
                            name={index}
                            key={index}
                            ref={noteValueRefs[index]}
                            className='admin-notes mb-2'
                            theme="snow"
                            onChange={(val) => onchangeNoteValue(val, index)}
                            value={value}
                            placeholder="Write your note here"
                            onFocus={() => handleFocus(index)}
                            onKeyPress={() => handleFocus(index)}
                        />
                    </div>
                ))}
                <div className='pb-3'>
                    <Space>
                        <Button className='bg-sky-800 text-white uppercase' onClick={hideModal}>{constants.Cancel}</Button>
                        <Button className='bg-sky-800 text-white uppercase' onClick={() => createNote(null, "add")} >{constants.Save}</Button>
                        <Popconfirm title="You will not be able to Edit after you Esign the Notes" onConfirm={handleEsign}>
                            <Button className='bg-sky-800 text-white uppercase'>{constants.Esign}</Button>
                        </Popconfirm>
                    </Space>
                </div>
            </>
        )
    }

    const renderForm = () => {
        return (
            <>
                {formData()}
                {formAssessment()}
                {formPlan()}
            </>
        )
    };
    return (
        <div className='add-note'>
            <div className='text-center bg-amber-300 text-lg font-semibold p-3'>{constants.Subtitle}
            <span className="py-0.5 px-3 ml-3 text-sm bg-white rounded-xl">{props?.path === "/edit-note" ? constants.EditNote : props?.path === "/pre-assessment-summary" ? constants.PreAssessmentSummy: constants.AddNote }</span>
            </div>
            {
                props?.path === "/pre-assessment-summary" && loginType === "0" ? <div className='p-2 mb-3'></div> :
                    <div className='bg-amber-100 text-base font-semibold p-2 mb-3'>
                        <div className="container mx-auto">
                            {
                                props?.path === "/edit-note" ?
                                    constants.EditNote :
                                    props?.path === "/pre-assessment-summary" && loginType !== "0" ?
                                        constants.PreAssessmentSummy :
                                        constants.AddNote}
                            {
                                loginType !== "0" ?
                                    ` - ${adminClientValuefromSession?.client_name ?
                                        adminClientValuefromSession?.client_name :
                                        ""
                                    }${adminClientValuefromSession?.client_serial_number ?
                                        ` (#${adminClientValuefromSession?.client_serial_number})`
                                        : ""
                                    }`
                                    :
                                    ""
                            }
                        </div>
                    </div>
            }
            <Spin spinning={loader} size="large">
                <div className='container mx-auto'>
                    {
                        props?.path !== "/pre-assessment-summary" ?
                            <div className='flex justify-between items-center pl-3 py-2 pr-7'>
                                <Radio.Group onChange={(e) => getForm(e.target.value)} value={noteTypeValue}>
                                    <Radio disabled={isNoteAnswered === false ? false : noteTypeValue === "2" ? false : true} value={"2"}>{constants.FamilyMember}</Radio>
                                    <Radio disabled={isNoteAnswered === false ? false : noteTypeValue === "1" ? false : true} value={"1"}>{constants.AddictsGamblers}</Radio>
                                    <Radio disabled={isNoteAnswered === false ? false : noteTypeValue === "3" ? false : true} value={"3"}>{constants.Emotional}</Radio>
                                    <Radio disabled={isNoteAnswered === false ? false : noteTypeValue === "0" ? false : true} value={"0"}>{constants.General}</Radio>
                                </Radio.Group>
                            </div>
                            : null
                    }
                    <div className="px-2 pb-2">
                        {
                            props?.path !== "/pre-assessment-summary" ?
                                <div><b className='text-sm'>{constants.InitialAssessmentGeneral}</b></div> :
                                <span className='text-lg font-bold mb-2'>{constants.AssessmentTitle}</span>
                        } 
                        <div className='flex gap-5 flex-wrap justify-between'>
                            {DOB(noteDate.month, handleNoteMonthChange, noteDate.date, handleNoteDayChange, noteDate.year, handleNoteYearChange, "Date", props?.path !== "/pre-assessment-summary"?false:true)}
                            {
                                props?.path !== "/pre-assessment-summary" ?
                                    <Checkbox onChange={(e) => onSessionChange(e.target.checked)} className='mb-6' checked={sessionChecked}>Session</Checkbox>
                                    : null
                            }
                        </div>
                        <Card>
                            {
                                props?.path === "/pre-assessment-summary" ? 
                                    <EditableCheatSheet
                                        customSelect={custom_select}
                                        onSubmit={createNote}
                                        handlePronounChange={handlePronounChange}
                                    /> 
                                    :
                                    noteTypeValue === "0" ? EditorForm() : (
                                        <Form
                                            layout="inline"
                                            form={form}
                                            onFinish={createNote}
                                            className='mb-10 text-black'>
                                            {renderForm()}
                                            <div className='pt-3'>
                                                <Space>
                                                    <Button className='bg-sky-800 text-white uppercase' onClick={hideModal}>{constants.Cancel}</Button>
                                                    <Button className='bg-sky-800 text-white uppercase' htmlType='submit' >{constants.Save}</Button>
                                                    <Popconfirm title="You will not be able to Edit after you Esign the Notes" onConfirm={handleEsign}>
                                                        <Button className='bg-sky-800 text-white uppercase'>{constants.Esign}</Button>
                                                    </Popconfirm>
                                                </Space>
                                            </div>
                                        </Form>
                                    )}
                        </Card>
                    </div>
                </div>
            </Spin>
        </div>
    );
};

export default AddNote;