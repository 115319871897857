import React, { useState, useRef, useEffect } from "react";
import { Card, Form, Input, Button, Select, Collapse, Divider, Checkbox, Row, Col, InputNumber, Space, Spin, message, Radio } from "antd";
import constants from '../../../constants/constants';
import { CaretRightOutlined } from "@ant-design/icons";
import { fetchAdminApi } from "../../../services/api";
import { COOKIE, getCookie } from "../../../services/cookie";
import { useHistory } from "react-router";
import TextArea from "antd/es/input/TextArea";

const { Panel } = Collapse;
const { Option } = Select;

const EditableCheatSheet = ({ customSelect, onSubmit, handlePronounChange }) => {
	const history = useHistory();
	const [activeKey, setActiveKey] = useState(["1"]);
	const [form] = Form.useForm();
	let adminClientValuefromSession = JSON.parse(sessionStorage.getItem("adminClientValue"));
	const first_name = getCookie(COOKIE.LoginType) === "0" ? `${adminClientValuefromSession?.client_firstname} ${adminClientValuefromSession?.client_lastname}` : "";
	const initialValues = {
		first_name: first_name,
		age: adminClientValuefromSession?.age,
		gender: adminClientValuefromSession?.gender,
		more_details: adminClientValuefromSession?.enquiry_info?.more_details,
		source: adminClientValuefromSession?.enquiry_info?.source,
		source_details: adminClientValuefromSession?.enquiry_info?.source_details,
		location: adminClientValuefromSession?.city + ", " + adminClientValuefromSession?.region_name + ", " + adminClientValuefromSession?.country_name,
	};
	const [selectedSubstances, setSelectedSubstances] = useState({});
	const [maritalStatus, setMaritalStatus] = useState(['Single', 'Divorced', 'Married']);
	const [newMaritalStatus, setNewMaritalStatus] = useState('');
	const maritalStatusInputRef = useRef(null);
	const [ethnicity, setEthnicity] = useState(constants.EthnicityList);
	const [newEthnicity, setNewEthnicity] = useState('');
	const ethnicityInputRef = useRef(null);
	const [religion, setReligion] = useState(constants.ReligionList);
	const [newReligion, setNewReligion] = useState('');
	const religionInputRef = useRef(null);
	const [selectedReasons, setSelectedReasons] = useState({});
	const [selectedMentalHealth, setSelectedMentalHealth] = useState([]);
	const [loader, setLoader] = useState(false);
	const [selectedAttempts, setSelectedAttempts] = useState({});
	const [showSourceDetails, setShowSourceDetails] = useState(adminClientValuefromSession?.enquiry_info?.source === "8" ? true : false);
	const [disorder, setDisorder] = useState(['Substance Use Disorder', 'Pathological Gambling']);
	const [newDisorder, setNewDisorder] = useState('');
	const disorderInputRef = useRef(null);
	const addDisorder = () => {
		if (!newDisorder) return;
		setDisorder([...disorder, newDisorder]);
		setNewDisorder('');
		setTimeout(() => disorderInputRef.current?.focus(), 0);
	};
	const [angerDeppressed, setAngerDepressed] = useState(['Adjustment disorders with Depressed Mood', 'Adjustment disorders with Anxiety', 'Adjustment disorders with Mixed Anxiety and Depressed Mood', 'Adjustment disorders (Unspecified)', 'Parent Child Conflict', 'Partner Relational Conflict']);
	const [newAngerDeppressed, setNewAngerDeppressed] = useState('');
	const angerDeppressedInputRef = useRef(null);
	const addAngerDeppressed = () => {
		if (!newDisorder) return;
		setAngerDepressed([...disorder, newDisorder]);
		setNewAngerDeppressed('');
		setTimeout(() => disorderInputRef.current?.focus(), 0);
	};
	const [isEmotional10Selected, setIsEmotional10Selected] = useState(false);
	const [isEmotional11Selected, setIsEmotional11Selected] = useState(false);
	const [isEmotional12Selected, setIsEmotional12Selected] = useState(false);
	const [isMedical8Selected, setIsMedical8Selected] = useState(false);
	const [living, setLiving] = useState(['living alone', 'with spouse/partner and children', 'in traditional joint family with partner and parents and siblings']);
	const [newLiving, setNewLiving] = useState('');
	const livingInputRef = useRef(null);
	const addLiving = () => {
		if (!newLiving) return;
		setLiving([...living, newLiving]);
		setNewLiving('');
		setTimeout(() => livingInputRef.current?.focus(), 0);
	};
	const [isFamily11Selected, setIsFamily11Selected] = useState(false);
	const [isFamily8Selected, setIsFamily8Selected] = useState(false);
	// Handle checkbox change for reason
	const handleCheckboxReaChange = (reason, isChecked) => {
		setSelectedReasons((prev) => ({
            ...prev,
            [reason]: { ...prev[reason], checked: isChecked, cause: isChecked ? prev[reason]?.cause || constants.ReasonValues[reason] : "", },
        }));
	};
	// Handle checkbox change for MENTAL HEALTH
	const handleCheckboxMentalHealth = (val) => {
		setSelectedMentalHealth(val);
	};

	const addReligion = () => {
		if (!newReligion) return;
		setReligion([...religion, newReligion]);
		setNewReligion('');
		setTimeout(() => religionInputRef.current?.focus(), 0);
	};

	const addEthnicity = () => {
		if (!newEthnicity) return;
		setEthnicity([...ethnicity, newEthnicity]);
		setNewEthnicity('');
		setTimeout(() => ethnicityInputRef.current?.focus(), 0);
	};

	const addMaritalStatus = () => {
		if (!newMaritalStatus) return;
		setMaritalStatus([...maritalStatus, newMaritalStatus]);
		setNewMaritalStatus('');
		setTimeout(() => maritalStatusInputRef.current?.focus(), 0);
	};

	const handleCheckboxSubChange = (substance, isChecked) => {
		setSelectedSubstances((prev) => ({
			...prev,
			[substance]: { ...prev[substance], checked: isChecked },
		}));
	};
	// Handle checkbox change for attempts
	const handleCheckboxAttemptChange = (attempt, isChecked) => {
		setSelectedAttempts((prev) => ({
			...prev,
			[attempt]: { ...prev[attempt], checked: isChecked },
		}));
	};

	const handleAgeChange = (substance, age) => {
		setSelectedSubstances((prev) => ({
			...prev,
			[substance]: { ...prev[substance], age },
		}));
	};

	const handleGamblingChange = (substance, value) => {
		setSelectedSubstances((prev) => (
			{
				...prev,
				[substance]: { ...prev[substance], textValue: value },
			}));
	};

	const validatePanel1 = async () => {
		try {
			await form.validateFields(["patient_problem"]);
			return true;
		} catch (error) {
			return false;
		}
	};

	const validatePanel2 = async () => {
		try {
			await form.validateFields(["first_name", "age", "gender", "intro_1", "intro_2", "intro_3", "source"]);
			return true;
		} catch (error) {
			return false;
		}
	};
	const validatePanel3 = () => Object.values(selectedSubstances).some(item => item.checked);
	const validatePanel4 = () => Object.values(selectedAttempts).some(item => item.checked);
	const validatePanel5 = () => Object.values(selectedReasons).some(item => item);
	const validatePanel6 = () => Object.values(selectedMentalHealth).some(item => item);
	const validatePanel7 = async () => {
		try {
			await form.validateFields(["medical_2", "medical_4", "medical_7", "medical_8"]);
			return true;
		} catch (error) {
			return false;
		}
	};
	const validatePanel8 = async () => {
		try {
			await form.validateFields(["family_4", "exercise", "family_11"]);
			return true;
		} catch (error) {
			return false;
		}
	};

	const handleSave = async () => {
		try {
			const panelValidationFunctions = [
				validatePanel1,
				validatePanel2,
				() => Promise.resolve(validatePanel3()), // Checkbox validation (no async needed)
				() => Promise.resolve(validatePanel4()), // Checkbox validation (no async needed)
				validatePanel5,
				validatePanel6,
				validatePanel7,
				validatePanel8
			];
			let invalidPanels = [];
			for (let i = 0; i < panelValidationFunctions.length; i++) {
				const isValid = await panelValidationFunctions[i]();
				if (!isValid) {
					invalidPanels.push((i + 1).toString());
				}
			}
			if (invalidPanels.length > 0) {
				setActiveKey([invalidPanels[0]]);
			} else {
				setLoader(true);
				let userAnswerValue = form.getFieldsValue();
				if(selectedMentalHealth.length>0){
					let index = selectedMentalHealth.findIndex((val)=>val === "Other");
					if(index>-1){
						selectedMentalHealth[index] = userAnswerValue.otherhealth;
					}
				}
				if (selectedReasons) userAnswerValue.selectedreasons = selectedReasons;
				if (selectedSubstances) userAnswerValue.selectedsubstances = selectedSubstances;
				if (selectedAttempts) userAnswerValue.selectedattempts = selectedAttempts;
				if (selectedMentalHealth) userAnswerValue.emotional_2 = selectedMentalHealth;
				const selectedOption = constants.SourceOption.find(
					(option) => option.value === userAnswerValue.source
				);
				userAnswerValue.source = selectedOption ? selectedOption.label : "";
				
				// Call Parent's function and pass child form data + callback
				onSubmit(userAnswerValue, null, true, (success) => {
					if (success) {
						setLoader(false);
						form.resetFields();
						setSelectedReasons({});
						setSelectedSubstances({});
						history.push("/dashboard");
						message.success("Pre-Assessment Summary saved successfully");
					}
					else {
						message.error("Pre-Assessment Summary failed");
						setLoader(false);
					}
				});
			}
		} catch (error) {
			console.error("Validation failed:", error);
		}
	};

	const drugsForm = () => {
		return (
			<div className='flex flex-col'>
				{(<Space direction="vertical" className="mb-3">
					{constants.Substances.map((substance) => (
						<Form.Item key={substance.key} className="mb-3">
							<div className='h-[36px] flex items-center'>
								<Checkbox
									onChange={(e) => handleCheckboxSubChange(substance.key, e.target.checked)}
									checked={selectedSubstances?.[substance.key]?.checked ? true : false}
								>
									<p className='text-[14px]'><b>{substance.substances}</b></p>
								</Checkbox>
								{selectedSubstances?.[substance.key]?.checked && (
									<>
										<b className='text-[14px] font-normal mr-2'>{"at age"}</b>
										<InputNumber
											value={selectedSubstances?.[substance.key]?.age ? selectedSubstances?.[substance.key]?.age : ""}
											placeholder="Enter age"
											min={1}
											style={{ width: 100 }}
											onChange={(value) => handleAgeChange(substance.key, value)}
										/>
									</>
								)}
							</div>
							{substance.key === "gambling" && <p className='text-[14px] ml-5'>Type of gambling:</p>}
							{
								substance.key === "gambling" && selectedSubstances?.[substance.key]?.checked || substance.key === "other" && selectedSubstances?.[substance.key]?.checked ?
									<TextArea
										className="mt-2 ml-5"
										value={selectedSubstances?.[substance.key]?.textValue}
										onChange={(value) => handleGamblingChange(substance.key, value.target.value)}
										rows={4}
									/> :
									<p className="text-blue-500 text-[14px]	ml-5">{substance.key === "gambling" || substance.key === "other" ? "" : "-"}{constants.SubstancesAliases[substance.key]}</p>
							}
						</Form.Item>
					))}
				</Space>)}
			</div>
		)
	};

	const panelTitle = (tittle) => {
		return <span className="text-base font-semibold">{tittle}</span>
	};

	useEffect(() => {
		setFormFields(initialValues);
		handlePronounChange(adminClientValuefromSession?.gender);
	}, []);

	const setFormFields = (value) => {
		form.setFieldsValue(value);
		if (value?.selectedreasons) setSelectedReasons(value?.selectedreasons);
		if (value?.selectedsubstances) setSelectedSubstances(value?.selectedsubstances);
	};

	const updatedReasons = constants.Reasons.map((reason) => ({
		...reason,
		reason: reason.reason.replace(/\slike$/, "") // Remove " like" at the end if it exists
	}));

	const onChangeCollapse = (val) => {
		setActiveKey(val)
	};

	const onChangeSourse = (val) => {
		setShowSourceDetails(val === "8" ? true : false);
	};

	// Handle input change for cause
	const handleCauseChange = (reason, cause) => {
		setSelectedReasons((prev) => ({
			...prev,
			[reason]: { ...prev[reason], cause },
		}));
	};

	const handleNestedCauseChange = (reason, cause, checked) => {
        setSelectedReasons((prevSelectedReasons) => ({
            ...prevSelectedReasons,
            [reason]: {
                ...prevSelectedReasons[reason],
                causes: {
                    ...prevSelectedReasons[reason]?.causes,
                    [cause]: checked
                }
            }
        }));
    };

	const handleEmotional10Change = (value) => {
        setIsEmotional10Selected(value === "received");
    };

	const handleEmotional11Change = (value) => {
        setIsEmotional11Selected(value === "was");
    };

	const handleEmotional12Change = (value) => {
        setIsEmotional12Selected(value === "medication");
    };

	const handleMedical8Change = (value) => {
        setIsMedical8Selected(value === "medications/OTCs");
    };

	const handleFamily11Change = (value) => {
        setIsFamily11Selected(value === "works as a");
    };

	const handleFamily8Change = (value) => {
        setIsFamily8Selected(value === 'current');
    };

	return (
		<Spin spinning={loader} size="large">
			<Form
				form={form}
				layout="vertical"
			>
				<Collapse
					activeKey={activeKey}
					className='pt-5'
					expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
					size="large" accordion
					onChange={onChangeCollapse}
				>
					<Panel header={panelTitle("Presenting Problem")} key="1">
						<Row gutter={16}>
							<Col xs={24} lg={24}>
								<Form.Item
									name="patient_problem"
									rules={[
										{
											required: true,
											message: "Please select one option!",
										},
									]}
								>
									<Radio.Group>
										<Radio value="2">{constants.FamilyMember}</Radio>
										<Radio value="1">{constants.AddictsGamblers}</Radio>
										<Radio value="3">{constants.Emotional}</Radio>
										<Radio value="0">{constants.General}</Radio>
									</Radio.Group>
								</Form.Item>
							</Col>
							<Col xs={24} lg={12}>
								<Form.Item
									name="problem_2"
									className='mb-0'
									label={"Angry/Anxious/Depressed"}
								>
									{customSelect(false, "Angry/Anxious/Depressed", "tags", angerDeppressed, addAngerDeppressed, newAngerDeppressed, (e) => setNewAngerDeppressed(e.target.value), angerDeppressedInputRef, { minWidth: '400px', })}
								</Form.Item>
							</Col>
							<Col xs={24} lg={12}>
								<Form.Item name="disorder" label={"Substance Use Disorder/Pathological Gambling"}>
									{customSelect(false, "Substance Use Disorder/Pathological Gambling", "tags", disorder, addDisorder, newDisorder, (e) => setNewDisorder(e.target.value), disorderInputRef, { minWidth: '400px', })}
								</Form.Item>
							</Col>
							<Col xs={24} lg={24}>
								<Form.Item
									name="more_details"
									className='mb-0'
									label={constants.MoreDetails}
								>
									<TextArea rows={2} />
								</Form.Item>
							</Col>
						</Row>
					</Panel>

					<Panel header={panelTitle("Basic Information")} key="2" forceRender={true}>
						<Row gutter={16}>
							<Col xs={24} lg={12}>
								<Form.Item
									label="Name"
									name="first_name"
									rules={[
										{
											required: true,
											message: "Enter name",
											whitespace: true
										},
									]}
								>
									<Input placeholder="Enter name" />
								</Form.Item>
							</Col>
							<Col xs={24} lg={12}>
								<Form.Item
									label="Age"
									name="age"
									rules={[
										{
											required: true,
											message: "Enter age",
										},
									]}
								>
									<InputNumber className="w-full" min={1} placeholder="Enter age" />
								</Form.Item>
							</Col>
							<Col xs={24} lg={12}>
								<Form.Item
									label={constants.Gender}
									name="gender"
									rules={[
										{
											required: true,
											message: "Select gender",
										},
									]}
								>
									<Select placeholder="Male/Female" allowClear onChange={handlePronounChange}>
										<Option value="Male">Male</Option>
										<Option value="Female">Female</Option>
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} lg={12}>
								<Form.Item
									label="City"
									name="location"
									rules={[
										{
											required: true,
											message: "Enter city",
										},
									]}
								>
									<Input placeholder="Enter city" />
								</Form.Item>
							</Col>
							<Col xs={24} lg={12}>
								<Form.Item
									name={"intro_1"}
									label={constants.Status}
									rules={[
										{
											required: true,
											message: "Select status",
										},
									]}
								>
									{
										customSelect(
											true,
											"Single/Divorced/Married",
											null,
											maritalStatus,
											addMaritalStatus,
											newMaritalStatus,
											(e) => setNewMaritalStatus(e.target.value),
											maritalStatusInputRef
										)
									}
								</Form.Item>
							</Col>
							<Col xs={24} lg={12}>
								<Form.Item
									label={constants.Ethniticity}
									name="intro_2"
									rules={[
										{
											required: true,
											message: "Select ethniticity",
										},
									]}
								>
									{customSelect(
										true,
										"Asian Indian/White/Black/Hispanic",
										null,
										ethnicity,
										addEthnicity,
										newEthnicity,
										(e) => setNewEthnicity(e.target.value),
										ethnicityInputRef
									)}
								</Form.Item>
							</Col>
							<Col xs={24} lg={12}>
								<Form.Item
									label={constants.Religion}
									name="intro_3"
									rules={[
										{
											required: true,
											message: "Select Religion",
										},
									]}
								>
									{customSelect(
										true,
										constants.ReligionPlaceHolder,
										null,
										religion,
										addReligion,
										newReligion,
										(e) => setNewReligion(e.target.value),
										religionInputRef
									)}
								</Form.Item>
							</Col>
							<Col xs={24} lg={12}>
								<Form.Item
									label={constants.Source}
									name={"source"}
									rules={[
										{
											required: true
										},
									]}
								>
									<Select
										size="middle"
										placeholder={constants.SourcePlaceHolder}
										options={constants.SourceOption}
										onChange={onChangeSourse}
									/>
								</Form.Item>
							</Col>
							{
								showSourceDetails ?
									<Col xs={24} lg={12}>
										<Form.Item
											name={"source_details"}
											label={"Source Details"}
										>
											<Input type="text" />
										</Form.Item>
									</Col> : null
							}
						</Row>
					</Panel>

					<Panel
						header={
							<div className="flex flex-col">
								{panelTitle("Substances/Gambling")}
								<span className="text-sm font-medium">Please check/tick whatever substance you have used and enter the age that you have started using.</span>
							</div>
						}
						key="3"
					>
						{
							!validatePanel3() && ( // Show error message if validation fails
								<span className="text-red-500 text-sm">Please select at least one substance.</span>
							)
						}
						{drugsForm()}
					</Panel>

					<Panel header={
						<div className="flex flex-col">
							{panelTitle("Has the substance use/gambling affected any of the following")}
							{/* <span className="text-sm font-medium"></span> */}
						</div>} key="5">
						{
							!validatePanel5() && ( // Show error message if validation fails
								<span className="text-red-500 text-sm">Please select at least one DSM Criteria.</span>
							)
						}
						<Row gutter={[12, 12]}>
							{updatedReasons.map((reason) => (
								<Col xs={24} lg={12} key={reason.key}>
									<Checkbox
										onChange={(e) => handleCheckboxReaChange(reason.key, e.target.checked)}
										checked={selectedReasons?.[reason.key]?.checked ? true : false}
									>
										<p className="text-[14px]">{reason.reason}</p>
									</Checkbox>
									{selectedReasons[reason.key]?.checked && !Array.isArray(constants.ReasonValues[reason.key]) && (
										<Input
											defaultValue={selectedReasons[reason.key]?.cause || constants.ReasonValues[reason.key]}
											value={selectedReasons[reason.key]?.cause ? selectedReasons[reason.key]?.cause : ""}
											placeholder="Enter cause"
											style={{ width: 600 }}
											onChange={(e) => handleCauseChange(reason.key, e.target.value)}
										/>
									)}
									{selectedReasons[reason.key]?.checked && Array.isArray(constants.ReasonValues[reason.key]) && (
										<Space direction="vertical">
											{constants.ReasonValues[reason.key].map((cause) => (
												<Checkbox
													key={cause}
													checked={selectedReasons[reason.key]?.causes?.[cause] || false}
													onChange={(e) => handleNestedCauseChange(reason.key, cause, e.target.checked)}
												>
													<p className="text-[14px] font-normal">{cause}</p>
												</Checkbox>
											))}
										</Space>
									)}
								</Col>
							))}
						</Row>
					</Panel>

					<Panel header={
						<div className="flex flex-col">
							{panelTitle("CAGE Screening")}
						</div>} key="4">
						{
							!validatePanel4() && ( // Show error message if validation fails
								<span className="text-red-500 text-sm">Have you experienced any of the following?</span>
							)
						}
						<Row gutter={[12, 12]}>
							{constants.CageScreening.map((cage) => (
								<Col xs={24} md={12} lg={12} key={cage.key}>
									<Checkbox
										onChange={(e) => handleCheckboxAttemptChange(cage.key, e.target.checked)}
										checked={selectedAttempts?.[cage.key] ? selectedAttempts[cage.key].checked : false || false}
									>
										<p className="text-[14px]">{cage.label}</p>
									</Checkbox>
								</Col>
							))}
						</Row>
					</Panel>

					<Panel header={
						<div className="flex flex-col">
							{panelTitle("Mental/Emotional Health")}
							<span className="text-sm font-medium">Have you ever been diagnosed with the following:</span>
						</div>
					} key="6">
						{
							!validatePanel6() && ( // Show error message if validation fails
								<div className="text-red-500 text-sm">Please select at least one Mental/Emotional Health.</div>
							)
						}
						<Checkbox.Group onChange={handleCheckboxMentalHealth}>
							<Row gutter={[12, 12]}>
								{constants.MentalHealth.map((health, index) => (
									<Col xs={24} sm={12} lg={12} key={index}>
										<Checkbox value={health}>
											<p className="text-[14px]">{health}</p>
										</Checkbox>
									</Col>
								))}
								<Col xs={24} sm={12} lg={12}>
									<Checkbox value={"Other"}>
										<p className="text-[14px]">Other</p>
									</Checkbox>
								</Col>
								{
									selectedMentalHealth.length > 0 && selectedMentalHealth.includes("Other") ?
										<Col xs={24} sm={12} lg={24}>
											<Form.Item name={"otherhealth"}>
												<TextArea placeholder={"Other Mental/Emotional Health..."} rows={4} />
											</Form.Item>
										</Col>
										: null
								}
								{selectedMentalHealth.includes("MH history") ?
								<>
									<Col xs={24} sm={12} lg={12}>
										<Form.Item name="emotional_10" label={"Treatment Received"}>
											<Select placeholder={constants.YesNo} allowClear onChange={handleEmotional10Change}>
												<Option value="received">{constants.Yes}</Option>
												<Option value="never received">{constants.No}</Option>
											</Select>
										</Form.Item>
									</Col>
									{isEmotional10Selected && (<Col xs={24} sm={12} lg={12}>
										<Form.Item name={"emotional_3"} label={"Therapist/Doctor"}>
											<Input placeholder={"Therapist"} />
										</Form.Item>
									</Col>)}
									<Col xs={24} sm={12} lg={12}>
										<Form.Item name="emotional_11" label={"Hospitalized for mental illness"}>
											<Select placeholder={constants.YesNo} allowClear onChange={handleEmotional11Change}>
												<Option value="was">{constants.Yes}</Option>
												<Option value="was not">{constants.No}</Option>
											</Select>
										</Form.Item>
									</Col>
									{isEmotional11Selected && (<Col xs={24} sm={12} lg={12}>
										<Form.Item name={"emotional_4"} label={"Mental illness"}>
											<Input placeholder={"Mental illness"} />
										</Form.Item>
									</Col>)}
									<Col xs={24} sm={12} lg={12}>
										<Form.Item name="emotional_12" label={"MEDICATION"}>
											<Select placeholder={constants.YesNo} allowClear onChange={handleEmotional12Change}>
												<Option value="medication">{constants.Yes}</Option>
												<Option value="no medication">{constants.No}</Option>
											</Select>
										</Form.Item>
									</Col>
									{isEmotional12Selected && (<Col xs={24} sm={12} lg={12}>
										<Form.Item name={"emotional_5"} label={"Medical Details"}>
											<Input placeholder={"medication"} />
										</Form.Item>
									</Col>)}
								</>:null}
							</Row>
						</Checkbox.Group>
					</Panel>

					<Panel header={panelTitle("Medical History")} key="7" forceRender={true}>
						<Row gutter={[12, 12]}>
							<Col xs={24} sm={12} lg={12}>
								<Form.Item
									label="Health Status"
									name={"medical_2"}
									rules={[
										{
											required: true,
											message: "Select health status",
										},
									]}
								>
									<Select placeholder="Good/Poor" allowClear>
										<Option value="good">Good</Option>
										<Option value="poor">Poor</Option>
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} lg={12}>
								<Form.Item
									label="Last Checkup Date"
									name={"medical_3"}
									rules={[
										{
											required: true,
											message: "Enter last checkup date",
										},
									]}
								>
									<Form.Item name={"medical_3"}>
										<Input placeholder={"Date"} />
									</Form.Item>
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} lg={12}>
								<Form.Item
									label="Allergies"
									name="medical_4"
									rules={[
										{
											required: true,
											message: "Select Allergies/no Allergies",
										},
									]}
								>
									<Select placeholder="Allergies/no Allergies" allowClear>
										<Option value="Allergies">Allergies</Option>
										<Option value="no Allergies">no Allergies</Option>
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} sm={12} lg={12}>
								<Form.Item
									label="Medications"
									name="medical_8"
									rules={[
										{
											required: true,
											message: "Select no/medications/OTCs",
										},
									]}
								>
									<Select placeholder="no/medications/OTCs" allowClear onChange={handleMedical8Change}>
										<Option value="medications/OTCs">Medications/OTCs</Option>
										<Option value="no medications/OTCs">no Medications/OTCs</Option>
									</Select>
								</Form.Item>
							</Col>
							{isMedical8Selected && (<Col xs={24} sm={12} lg={12}>
								<Form.Item name={"medical_9"} label={"Medications/OTCs Details"}>
									<Input placeholder={"medications/OTCs"} />
								</Form.Item>
							</Col>)}
							<Col xs={24} sm={24} lg={12}>
								<Form.Item
									name="medical_10"
									label={"Dietary Habbits"}
									rules={[
										{
											required: true,
											message: "Select Dietary Habbits",
										},
									]}
								>
									<Select placeholder={constants.DietaryHabbitsPlaceHolder} mode="multiple" style={{ minWidth: '200px', }}>
										{
											constants.DietaryHabbits.map((diet)=>{
												return <Option key={diet.key} value={diet.key}>{diet.label}</Option>
											})
										}
									</Select>
								</Form.Item>
							</Col>
												
							<Col xs={24} sm={24} lg={24}>
								<Form.Item
									label="Illness (Any Illness currently suffering with)"
									name="medical_7"
									rules={[
										{
											required: true,
											message: "Enter illnesses/disease",
										},
									]}
								>
									<TextArea placeholder={"illnesses/disease"} rows={4} />
								</Form.Item>
							</Col>
						</Row>
					</Panel>

					<Panel header={panelTitle("Family & Lifestyle")} key="8" forceRender={true}>
						<Row gutter={16}>
							<Col xs={24} lg={12}>
								<Form.Item
									label="Name of the Parent(s)"
									name="parent_name"
									rules={[
										{
											required: true,
											message: "Enter parent(s) name",
										},
									]}
								>
									<Input placeholder="Enter parent(s) name" />
								</Form.Item>
							</Col>
							<Col xs={24} lg={12}>
								<Form.Item
									label="Name of the spouse"
									name="spouse_name"
									rules={[
										{
											required: true,
											message: "Enter spouse name",
										},
									]}
								>
									<Input placeholder="Enter spouse name" />
								</Form.Item>
							</Col>
							<Col xs={24} lg={12}>
								<Form.Item
									label="Name of Children"
									name="children_name"
									rules={[
										{
											required: true,
											message: "Enter children name",
										},
									]}
								>
									<Input placeholder="Enter children name" />
								</Form.Item>
							</Col>
							<Col xs={24} lg={12}>
								<Form.Item
									label="Children age"
									name="family_7"
									rules={[
										{
											required: true,
											message: "Enter age",
										},
									]}
								>
									<InputNumber className ={"w-full"} placeholder="Enter age" min={1}/>
								</Form.Item>
							</Col>
							<Col xs={24} lg={12}>
								<Form.Item
									name="family_3"
									label="Residence Type"
									rules={[
										{
											required: true,
											message: "Enter residence type",
										},
									]}
								>
									{customSelect(true, "living alone/with spouse/partner & children/joint family", null, living, addLiving, newLiving, (e) => setNewLiving(e.target.value), livingInputRef)}
								</Form.Item>
							</Col>
							<Col xs={24} lg={12}>
								<Form.Item
									label="Family Relations"
									name="family_4"
									rules={[
										{
											required: true,
											message: "Select family relations",
										},
									]}
								>
									<Select placeholder="Fair/Good/Poor" allowClear>
										<Option value="fair">Fair</Option>
										<Option value="good">Good</Option>
										<Option value="poor">Poor</Option>
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} lg={12}>
								<Form.Item
									label="Exercise"
									name="exercise"
									rules={[
										{
											required: true,
											message: "Select exercise",
										},
									]}
								>
									<Select placeholder="Walks/Gym/Sports/None">
										<Option value="Walks">Walks</Option>
										<Option value="Gym">Gym</Option>
										<Option value="Sports">Sports</Option>
										<Option value="None">None</Option>
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} lg={12}>
								<Form.Item
									name={"family_8"}
									label={"Legal issues"}
									rules={[
										{
											required: true,
											message: "Enter legal issues",
										},
									]}
								>
									<Select placeholder="No/Current" allowClear onChange={handleFamily8Change}>
										<Option value="no">No</Option>
										<Option value="current">Current</Option>
									</Select>
								</Form.Item>
							</Col>
							{isFamily8Selected && (
								<Col xs={24} lg={12}>
									<Form.Item
										name={"family_9"}
										label={"Legal issue Details"}
									>
										<Input placeholder={"legal issue Details"} />
									</Form.Item>
								</Col>
							)}
							<Col xs={24} lg={12}>
								<Form.Item
									name={"family_10"}
									label={"Education"}
									rules={[
										{
											required: true,
											message: "Enter Education",
										},
									]}
								>
									<Input placeholder={"Education"} />
								</Form.Item>
							</Col>
							<Col xs={24} lg={12}>
								<Form.Item
									name="family_11"
									label="Occupation"
									rules={[
										{
											required: true,
											message: "Select occupation",
										},
									]}
								>
									<Select placeholder="Unemployed/Retired/Owns business/Works" allowClear onChange={handleFamily11Change}>
										<Option value="is unemployed">Unemployed</Option>
										<Option value="is retired">Retired</Option>
										<Option value="owns business">Owns business</Option>
										<Option value="works as a">Works as a</Option>
									</Select>
								</Form.Item>
							</Col>
							<Col xs={24} lg={12}>
								{isFamily11Selected && (
									<Form.Item name={"family_12"} label="Profession">
										<Input placeholder={"Profession"} />
									</Form.Item>
								)}
							</Col>
						</Row>
					</Panel>
				</Collapse>

				<Divider />
				<Form.Item>
					<Space className="float-right">
						<Button className='bg-sky-800 text-white uppercase' type='primary' /* htmlType="submit" */ onClick={handleSave}>
							{constants.Save}
						</Button>
					</Space>
				</Form.Item>
			</Form>
		</Spin>
	);
};

export default EditableCheatSheet;

