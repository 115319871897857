import React, { useEffect, useState } from 'react';
import html2pdf from 'html2pdf.js';
import { fetchApi } from '../../services/api';
import { Button, Table, Space, Switch, Spin, Image } from 'antd';
import constants from '../../constants/constants';
import logo_bw from '../../assets/logo-black-and-white.svg';
import logo_blue from '../../assets/logo-blue.svg';
import { COOKIE, getCookie } from "../../services/cookie";
import { templeteJarOutline } from './Templates';

const ChangeJar = () => {
    const [changeJarData, setChangeJarData] = useState([]);
    const [loader, setLoader] = useState(true);
    let colored = JSON.parse(localStorage.getItem("colorValue"));
    const [isColored, setIsColored] = useState(colored !==null ? colored.color : true);
    const [apiError, setApiError] = useState(false);
    const [pageHeader, setPageHeader] = useState(constants.ChangeJar);

    useEffect(() => {
        fetchData();

        const handleDownloadClick = () => {
            const element = document.getElementById('pdfContent');
            if (!element) {
                return;
            }

            const options = {
                margin: 0,
                filename: isColored ? 'Change Jar colored.pdf' : 'Change Jar B&W.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2, useCORS: true },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
                pagebreak: {
                    mode: ['avoid-all', 'css', 'legacy'],
                },
            };
            html2pdf().from(element).set(options).save().catch((error) => {
                console.error('Error generating PDF:', error);
            });
        };

        const downloadButtons = document.querySelectorAll('.downloadPdfButton');
        downloadButtons.forEach(button => {
            button.addEventListener('click', handleDownloadClick);
        });

        return () => {
            downloadButtons.forEach(button => {
                button.removeEventListener('click', handleDownloadClick);
            });
            localStorage.removeItem('colorValue');
        };
    }, [isColored]);

    const fetchData = () => {
        setLoader(true);
        setApiError(false);
        let adminClientValue = JSON.parse(sessionStorage.getItem("adminClientValue"));
        let clientId = null;
        if (adminClientValue?.isAdmin) {
            clientId = adminClientValue?.clientId;
            setPageHeader(`${constants.ChangeJar} - ${adminClientValue?.client_name} (#${adminClientValue?.client_serial_number})`);
        } else {
            clientId = getCookie(COOKIE.ClientId);
            setPageHeader(`My ${constants.ChangeJar}`);
        }
        fetchApi(`/get-opposites/${clientId}`, "get").then((res) => {
            if (res?.code === 200) {
                setChangeJarData(res.data);
                setLoader(false);
            } else {
                setChangeJarData([]);
                setLoader(false);
                setApiError(true);
            }
        }).catch((error) => {
            setChangeJarData([]);
            setLoader(false);
            setApiError(true);
        });
    };
    
    const onChangeSwitchButton = (ischecked) => {
        let payload = {
			color: ischecked,
		};
        localStorage.removeItem("colorValue")
		localStorage.setItem("colorValue", JSON.stringify(payload));
        setIsColored(ischecked);
    };

    return (
        <Spin spinning={loader} size="large">
            <div className='text-center bg-amber-300 text-lg font-semibold p-3'>{constants.Subtitle}
                <span className="py-0.5 px-3 ml-3 text-sm bg-white rounded-xl">{constants.ChangeJar}</span>
            </div>
            <div className='bg-amber-100 text-base font-semibold p-2 mb-3'>
                <div className="container mx-auto">
                    <Space className='flex justify-between mt-1'>
                        {pageHeader}
                        <Button
                            className='downloadPdfButton text-xs bg-sky-800 text-white font-semibold uppercase'
                            disabled={apiError || loader}
                            size='small'
                        >
                            {constants.DownloadPDF}
                        </Button>
                    </Space>
                </div>
            </div>

            <div className="container mx-auto">
                <div className='my-8'>
                    <div id="pdfContent">
                        {!loader ? templeteJarOutline(changeJarData,isColored, onChangeSwitchButton):null}
                    </div>
                    <div className='!mt-8 text-end'>
                        <Button
                            className='downloadPdfButton bg-sky-800 text-white uppercase'
                            disabled={apiError || loader}
                        >
                            {constants.DownloadPDF}
                        </Button>
                    </div>
                </div>
            </div>
        </Spin>
    );
};

export default ChangeJar;
