import React, { useEffect, useState } from 'react';
import { Layout, Image, Menu, message, Drawer, Button } from "antd";
import { 
	MailOutlined, 
	UserOutlined, 
	PoweroffOutlined, 
	LinkOutlined, 
	SolutionOutlined,
	FileImageOutlined, 
	ExceptionOutlined, 
	UnorderedListOutlined, 
	CheckCircleOutlined,
	CloseCircleOutlined,
	ReloadOutlined,
	IssuesCloseOutlined,
	MenuFoldOutlined,
	MenuUnfoldOutlined,
	TeamOutlined,
	ReadOutlined,
	LaptopOutlined,
	AccountBookOutlined,
	BookOutlined,
	FileDoneOutlined
} from '@ant-design/icons';
import "./index.css";
import logo from './logo.svg';
import constants from './constants/constants';
import { getCookie, COOKIE, deleteCookie } from './services/cookie';
import { fetchApi } from "../src/services/api";
import { useHistory, useLocation } from "react-router";
const { Header } = Layout;
const Headers = () => {
	let location = useLocation();
	const [firstName, setfirstName] = useState("");
	const [clientLogin, setClientLogin] = useState(false);
	const [current, setCurrent] = useState(location.pathname);
	const [open, setOpen] = useState(false);
	const [loginType] = useState(getCookie(COOKIE.LoginType));
	const history = useHistory();
	let queryString = window.location.href;
	let url = queryString.substring(queryString.lastIndexOf('/') + 1);
	let parentconcentPage =url.includes("parent-consent");
	let paymentagreementPage = url.includes("payment-agreement");
	useEffect(() => {
		if (location) {
			if( current !== location.pathname ) {
				setCurrent(location.pathname);
			}
		}
	}, [location, current]);
	useEffect(() => {
		let userIdFromCookies = getCookie(COOKIE.ClientId);
		let clientLogin = getCookie(COOKIE.LoginType);
		if (clientLogin === "0") {
			setClientLogin(true);
		} else {
			setClientLogin(false);
		}
		if (userIdFromCookies) {
			let firstName = getCookie(COOKIE.FirstName);
			setfirstName(firstName);
		}
	}, []);
	const handleLogout = () => {
		let userIdFromCookies = getCookie(COOKIE.ClientId);
		let payload = null;
		payload = { is_logged_in: 0, };
		fetchApi(`/logout/${userIdFromCookies}`, "post", payload).then((res) => {
			if (res && res.code && res.code === 200) {
				deleteCookie(COOKIE.ClientId, "/");
				deleteCookie(COOKIE.FirstName, "/");
				deleteCookie(COOKIE.LastName, "/");
				deleteCookie(COOKIE.LoginType, "/");
				deleteCookie(COOKIE.Token, "/");
				window.location.href = constants.BaseUrl + "/";
				sessionStorage.clear();
			} else {
				message.error(res.message);
			}
		});
	};
	const getUseLinkItems = (displaytype,route,label,icon) => {
		if (displaytype === "show") {
			return <Menu.Item
				key={route}
				icon={icon}
				onClick={() => {
					history.push(route);
				}}
			>
				{label}
			</Menu.Item>
		}
	};
	// Menu 
	const showDrawer = () => {setOpen(true);};
	const onClose = () => {setOpen(false);};
	const menuItems = [
		!clientLogin ? [
			<>
				{loginType !== "3" ? (<Menu.SubMenu key="client_list" title={<span className="text-amber-300">{constants.ClientList}</span>} icon={<SolutionOutlined className="!text-white text-lg" />}>
					<Menu.Item
						key="/client-list-open"
						icon={<CheckCircleOutlined/>}
						onClick={() => {
							history.push({ pathname:"/client-list-open" });
						}}
					>
						Open
					</Menu.Item>
					<Menu.Item
						key="/client-list-closed"
						icon={<CloseCircleOutlined/>}
						onClick={() => {
							history.push({ pathname:"/client-list-closed" });
						}}
					>
						Closed
					</Menu.Item>
					<Menu.SubMenu key="/client-enquiry-list" title={<span>{constants.ClientEL}</span>} icon={<ExceptionOutlined className="text-lg"/>}>
					<Menu.Item
						key="/client-enquiry-open-list"
						icon={<CheckCircleOutlined/>}
						onClick={() => {
							history.push({ pathname:"/client-enquiry-open-list" });
						}}
					>
						Open
					</Menu.Item>
					<Menu.Item
						key="/client-enquiry-forceclosed-list"
						icon={<IssuesCloseOutlined/>}
						onClick={() => {
							history.push({ pathname:"/client-enquiry-forceclosed-list" });
						}}
					>
						Force Closed
					</Menu.Item>
					</Menu.SubMenu>
				</Menu.SubMenu>
				) : null}
				{loginType === "1" || loginType === "2" || loginType === "3" ? (
					<Menu.SubMenu key="UsefulLinks" title={<span className="text-amber-300">{constants.UsefulLinks}</span>} icon={<LinkOutlined className="!text-white text-lg"/>}>
						{loginType === "2" || loginType === "1" || loginType === "3" ? getUseLinkItems("show", "/image-list", constants.ImageList, <FileImageOutlined />) : null}
						{loginType === "1"? getUseLinkItems("show","/associate-list",constants.Associates,<TeamOutlined/>): null}
						{loginType === "2" || loginType === "1" ? getUseLinkItems("show","/demo-client-list",constants.DemoClient,<LaptopOutlined/>): null}
						{loginType === "1" ? getUseLinkItems("show","/master-docs",constants.MasterDocs,<ReadOutlined />): null}
						{loginType === "2" || loginType === "1" ? getUseLinkItems("show","/client-docs",constants.ClientDocs,<FileDoneOutlined />): null}
						{loginType === "2" || loginType === "1" ? getUseLinkItems("show","/client-notes",constants.ClientNotes,<BookOutlined />): null}
						{loginType === "1" ? getUseLinkItems("show","/payment-statement",constants.PaymentStatement,<AccountBookOutlined/>): null}
					</Menu.SubMenu>
				):null}
			</>
		]:
			[
				<Menu.Item style={{ cursor: "default" }} key="mail" icon={<MailOutlined className="!text-white text-lg"/>}>
					<span className="text-amber-300">{constants.ContactEmail}</span>
				</Menu.Item>
			],
		<Menu.SubMenu key="SubMenu" className='hidden lg:list-item' title={<span className="text-amber-300">{firstName}</span>} icon={<UserOutlined className="!text-white text-lg"/>}>
			{(loginType === "1" || loginType === "2" || loginType === "3") && (
				<Menu.Item
					key="/my-profile"
					icon={<UserOutlined/>}
					onClick={() => {
						history.push("/my-profile");
					}}
				>
				My Profile
				</Menu.Item>)},
			<Menu.Item
				key="logout"
				icon={<PoweroffOutlined/>}
				onClick={handleLogout}
			>
			Logout
			</Menu.Item>
		</Menu.SubMenu>,
		// Mobile only menu items below. 
		(loginType === "1" || loginType === "2" || loginType === "3") && (
			<Menu.Item
				key="/my-profile"
				className="inline-block lg:!hidden"
				icon={<UserOutlined className="!text-white text-lg"/>}
				onClick={() => {
					history.push("/my-profile");
				}}
			>
				<span className="text-amber-300">My Profile</span>
			</Menu.Item>),
		<Menu.Item
			key="logout"
			className="inline-block lg:!hidden"
			icon={<PoweroffOutlined className="!text-white text-lg"/>}
			onClick={handleLogout}
		>
			<span className="text-amber-300">Logout</span>
		</Menu.Item>
	];
	return (
		<Header className='roycares-header flex justify-between items-center !px-3 lg:!px-12' id='top'>
			<Image src={logo} alt="RoyCares & Associates" preview={false} width={360}/>
			{(!parentconcentPage && !paymentagreementPage) && (
				<>
					<Button
						type="primary"
						shape="circle"
						size="large"
						className="flex lg:!hidden bg-black"
						icon={open ? <MenuFoldOutlined/> : <MenuUnfoldOutlined/>}
						onClick={showDrawer}
					/>
					<Menu mode="horizontal" className="bg-transparent hidden lg:block" disabledOverflow={true} selectedKeys={[current]}>
						{menuItems}
					</Menu>
					<Drawer 
						title={<span className='text-white'>{firstName}</span>} 
						placement="right" 
						width={250}
						headerStyle={{ background: '#001529' }}
						bodyStyle={{ 
							padding: 0, 
							background: '#001529' 
						}}
						onClose={onClose} 
						open={open}
						closeIcon={<CloseCircleOutlined className='text-white'/>}
					>
						<Menu mode="inline" theme="dark" disabledOverflow={true} selectedKeys={[current]}>
							{menuItems}
						</Menu>
					</Drawer>
				</>)}
			{/* <Menu.Item key="psettings" icon={<UserOutlined />}>Profile Settings</Menu.Item> */}
		</Header>
	);
};
export default Headers;
