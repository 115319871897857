/*eslint no-unused-vars: "error"*/
import React, { useEffect, useState } from 'react';
import { fetchAdminApi, fetchApi } from "../../../services/api";
import constants from '../../../constants/constants';
import { Popconfirm, InputNumber, Input, Form, Row, Col, Card, Button, Select, Radio, Table, Space, Tag, message, Spin, Checkbox, Divider, Modal, Typography, Collapse, Layout } from 'antd';
import {
	UserOutlined,
	FileUnknownTwoTone,
	ReloadOutlined,
	EyeOutlined,
	CheckOutlined,
	MailOutlined, RightOutlined, LeftOutlined
} from '@ant-design/icons';
import PhoneInput from "react-phone-input-2";
import moment from 'moment';
import { useHistory, useLocation } from "react-router";
import { DOB, notesModel, welcomeEmailPopup, } from "../../../../src/globalFunctions/GlobalFunctions";
import { COOKIE, getCookie } from "../../../services/cookie";
const { Sider, Content } = Layout;
const { Option } = Select;
const { TextArea } = Input;
const { Panel } = Collapse;
const EditClientProfile = (props) => {
	let location = useLocation();
	const [form] = Form.useForm();
	const [paymentForm] = Form.useForm();
	const [emailchangeForm] = Form.useForm();
	const history = useHistory();
	const [clientDetails, setClientDetails] = useState([]);
	const [tableData, setTableData] = useState([]);
	const [tempState, setTempState] = useState([]);
	const [styles, setStyles] = useState(null);
	const [listCountries, setCountries] = useState([]);
	const [listStates, setListStates] = useState([]);
	const [typeAddict, setTypeAddict] = useState(1);
	const [loader, setLoader] = useState(false);
	const [day, setDay] = useState(null);
	const [month, setMonth] = useState(null);
	const [year, setYear] = useState(null);
	const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
	const [actionType, setActionType] = useState("add");
	const [isEmailChangeModalOpen, setIsEmailChangeModalOpen] = useState(false);
	//currencyDropdown
	const [selectValue, setSelectValue] = useState("USD");
	const [inputValue, setInputValue] = useState(null);
	const [paymentDate, setPaymentDate] = useState({
		date: null,
		month: null,
		year: null
	});
	//validate status for email,dob,phone
	const [validateStatus, setValidateStatus] = useState({
		email: null,
		dob: null,
	});
	const [dateOfAddmission, setDateOfAddmission] = useState({
		date: null,
		month: null,
		year: null
	});
	const [dateOfEnquiry, setDateOfEnquiry] = useState({
		date: null,
		month: null,
		year: null
	});
	const current = new Date();
	const [noteDate, setNoteDate] = useState({
		date: current.getDate(),
		month: String(current.getMonth() + 1),
		year: current.getFullYear()
	});
	//notes
	const [notesVisible, setnotesVisible] = useState(false);
	const [noteValue, setNoteValue] = useState(null);
	const [noteTypeValue, setNoteTypeValue] = useState("0");
	const [sessionChecked, setSessionChecked] = useState(false);
	const [paymentReceived, setPaymentReceived] = useState(false);
	const [enquiryDetails, setEnquiryDetails] = useState({});
	let enquiryDetailsfromSession = JSON.parse(sessionStorage.getItem("enquiryDetails"));
	const [isForceClosed, setIsForceClosed] = useState(false);
	const [isPayChecked, setIsPayChecked] = useState(false);
	const [isWorkbookComplete, setIsWorkbookComplete] = useState(false);
	const [popOverDescription, setPopOverDescription] = useState("");
	const [gender, setGender] = useState("");
	// const [otherGender, setOtherGender] = useState("");
	const [checkEmailPsdChange, setCheckEmailPsdChange] = useState({
		email: false,
		password: false,
	});
	const [formValues, setformValues] = useState({});
	const admintype = getCookie(COOKIE.LoginType);
	const [isNoteAnswered, setIsNoteAnswered] = useState(false);
	const [listAssociates, setListAssociates] = useState([]);
	const [sharedAssociatesList, setSharedAssociatesList] = useState([]);
	const [selectedValue, setSelectedValue] = useState(null);
	const [collapsed, setCollapsed] = useState(false);
	const [summarySection,setSummarySection] = useState({
		summeryData:[],
		moreDetails:""
	});
	const [summeryLoader, setSummeryLoader] = useState(false);
	useEffect(() => {
		sessionStorage.removeItem('adminClientValueFam');
		fetchCountries();
		getSharedAssociateList();
		let adminClientValue = JSON.parse(sessionStorage.getItem("adminClientValue"));
		if (props && props.location && props.location.state && props.location.state.client_id || adminClientValue?.clientId) {
			fetchClientDetails(props && props.location && props.location.state && props.location.state.client_id || adminClientValue?.clientId);
		} else {
			fetchEnquiryDetails(props && props.location && props.location.state && props.location.state.enquiry_id || enquiryDetailsfromSession?.enquiry_id);
		}
		return () => {
			// Remove the session here
			sessionStorage.removeItem('adminClientValue');
			sessionStorage.removeItem('enquiryDetails');
		};
	}, []);
	const onCheckboxChange = (e, type) => {
		if (type === "email") {
			setCheckEmailPsdChange({
				...checkEmailPsdChange,
				email: e.target.checked,
			});
		} else {
			setCheckEmailPsdChange({
				...checkEmailPsdChange,
				password: e.target.checked,
			});
		}
	};
	const showSection = (sectionId) => {
		let payload = {
			isAdmin: true,
			clientId: clientDetails?.client_id,
			client_name: clientDetails?.client_firstname + " " + clientDetails?.client_lastname,
			client_serial_number: clientDetails?.client_serial_number
		};
		sessionStorage.setItem("latestSessionEnabled", "8");
		sessionStorage.setItem("adminClientValue", JSON.stringify(payload));
		if (sectionId === 100) {
			window.open(constants.BaseUrl + `/refresher-workbook`);
		} else {
			window.open(constants.BaseUrl + `/workbook-section-${sectionId}`);
		}
	};
	const columns = [
		{
			title: 'Section',
			dataIndex: 'section',
		},
		{
			title: 'Action',
			key: 'action',
			align: 'center',
			render: (record) => {
				if (record.viewSections) {
					return <EyeOutlined onClick={() => showSection(record?.sectionId)} />;
				}
				else if (record.is_clickable) {
					return <Button className="bg-amber-300" onClick={() => enableSection(record)} disabled={admintype === "2" && clientDetails?.payment_info === null ? true : record?.is_clickable === true ? false : true}>Enable</Button>;
				}
				else if (record.status === "1") {
					return <Button className="border-sky-800 text-sky-800">Enabled</Button>;
				}
			}
		},
		{
			title: 'Enabled On',
			dataIndex: 'date_enabled',
			// key: 'enabledOn',
			render: (record) => {
				if (record) {
					return <span>{moment(record).format(constants.dateformat)}</span>;
				} else {
					return <span>-</span>;
				}
			}
		},
		{
			title: 'Completed On',
			dataIndex: 'date_completed',
			// key: 'completedOn',
			render: (record) => {
				if (record) {
					return <span>{moment(record).format(constants.dateformat)}</span>;
				} else {
					return <span>-</span>;
				}
			}
		},
		{
			title: 'Status',
			dataIndex: 'status',
			// key: 'status',
			render: (record) => {
				if (record === "7") {
					return <Tag color={'orange'}>In Progress</Tag>;
				} else if (record === "1") {
					return <Tag color={'default'}>Enabled</Tag>;
				} else if (record === "2") {
					return <Tag color={'blue'}>Submitted</Tag>;
				} else if (record === "3") {
					return <Tag color={'green'}>Completed</Tag>;
				} else if (record === "4") {
					return <Tag color={'orange'}>Discussion In Progress{" "}</Tag>;
				} else {
					return <span>-</span>;
				}
			}
		},
	];
	const payHisCol = [
		{
			title: 'Date',
			dataIndex: 'dt_payment_received',
			key: 'dt_payment_received',
			render: (date) => {
				return <span>{moment(date).format(constants.dateformat)}</span>;
			},
			width: "20%"
		},
		{
			title: 'Payment Details',
			dataIndex: 'payment_details',
			key: 'payment_details',
			render: (details) => {
				const contentDiv = document.createElement('div');
				contentDiv.innerHTML = details?.replace(/\n/g, '<br/>');
				const formattedHtml = contentDiv.innerHTML;
				return <div dangerouslySetInnerHTML={{ __html: details ? formattedHtml : "" }} />;
			}
		},
		{
			title: constants.PaidBy,
			dataIndex: 'paid_by',
			key: 'paid_by',
			width: "20%",
			render: (paid_user) => {
				return <span>{paid_user === null ? "RoyCares" : paid_user === "1" ? constants.Caller : paid_user === "2" ? constants.Other : constants.Client}</span>;
			},
		},
		{
			title: constants.TransferTo,
			dataIndex: 'transfer_to',
			key: 'transfer_to',
			width: "20%",
			render: (transferred_to) => {
				if (transferred_to === null) {
					return <span className='block text-center'>-</span>;
				} else {
					return <span>{transferred_to}</span>;
				}
			},
		},
		{
			title: 'INR (₹)',
			key: 'rupee',
			width: "10%",
			align: "center",
			render: (record) => {
				if (record.currency === "1") {
					return <span className='text-right w-20 block m-auto'>{formatNumber(record?.amount, 'INR')}</span>;
				} else {
					return <span>-</span>;
				}
			}
		},
		{
			title: 'USD ($)',
			key: 'dollar',
			width: "10%",
			align: "center",
			render: (record) => {
				if (record.currency === "1") {
					return <span>-</span>;
				} else {
					return <span className='text-right w-20 block m-auto'>{formatNumber(record?.amount, 'USD')}</span>;
				}
			}
		},
	];
	const servicesCheckbox = [
		{ label: "Addiction", value: "1" },
		{ label: "Individual", value: "2" },
		{ label: "Trauma", value: "3" },
		{ label: "Family", value: "4" },
		{ label: "Youth", value: "5" },
		{ label: "Women", value: "6" },
		{ label: "Business Coaching", value: "7" },
		{ label: "Psychiatric", value: "8" },
	];
	const layout = {
		labelCol: { span: 8 },
		wrapperCol: { span: 16 },
	};
	const layout2 = {
		labelCol: { span: 6 },
		wrapperCol: { span: 18 },
	};
	const verticalLayout = {
		labelCol: { span: 24 },
		wrapperCol: { span: 24 }
	};
	const tailLayout = { wrapperCol: { span: 24 } };
	const hideModal = () => {
		setNoteTypeValue("0");
		setnotesVisible(false);
	};
	const onchangeNoteValue = (e, index) => {
		noteValue[index] = e;
	};
	const onSessionChange = (value) => {
		setSessionChecked(value);
	}
	const addNotes = (clientType) => {
		if ((clientType === "client" && clientDetails.is_note_answered === 0) || enquiryDetails.is_note_answered === 0 ) {
			let adminClientValue = JSON.parse(sessionStorage.getItem("adminClientValue"));
			let payload;
			if (clientType === "client") {
				payload = {
					clientId: props && props.location && props.location.state && props.location.state.client_id || clientDetails?.client_id,
					client_serial_number: clientDetails?.client_serial_number,
					client_name: clientDetails?.client_firstname + " " + clientDetails?.client_lastname
				};
			} else {
				payload = {
					enquiryId: props && props.location && props.location.state && props.location.state.enquiry_id || enquiryDetails?.enquiry_id,
					client_serial_number: enquiryDetails?.enquiry_id,
					client_name: enquiryDetails?.client_firstname + " " + enquiryDetails?.client_lastname
				};
			}
			if (adminClientValue === null) {
				sessionStorage.setItem("adminClientValue", JSON.stringify(payload));
			}
			window.open(constants.BaseUrl + "/add-note");
		} else {
			document.getElementById('top').scrollIntoView({ behavior: "smooth" });
			getForm(noteTypeValue);
			setnotesVisible(true);
		}
	};
	const createNote = (type) => {
		let payload;
		let admin_id = getCookie(COOKIE.ClientId);
		if (props && props.location && props.location.state && props.location.state.enquiry_id || enquiryDetailsfromSession?.enquiry_id) {
			payload = {
				enquiry_id: props && props.location && props.location.state && props.location.state.enquiry_id || enquiryDetailsfromSession?.enquiry_id,
				notes_content: noteValue[0] ? "<b><u>DATA:</u></b>" + " " + noteValue[0] : "",
				notes_assesment: noteValue[1] ? "<b><u>ASSESSMENT:</u></b>" + " " + noteValue[1] : "",
				notes_plan: noteValue[2] ? "<b><u>PLAN:</u></b>" + " " + noteValue[2] : "",
				section_id: null,
				admin_id: admin_id,
				notes_type: noteTypeValue,
				dt_created: `${noteDate.year}-${noteDate.month}-${noteDate?.date}`
			};
		} else {
			payload = {
				client_id: clientDetails?.client_id,
				notes_content: noteValue[0] ? "<b><u>DATA:</u></b>" + " " + noteValue[0] : "",
				notes_assesment: noteValue[1] ? "<b><u>ASSESSMENT:</u></b>" + " " + noteValue[1] : "",
				notes_plan: noteValue[2] ? "<b><u>PLAN:</u></b>" + " " + noteValue[2] : "",
				section_id: null,
				admin_id: admin_id,
				notes_type: noteTypeValue,
				dt_created: `${noteDate.year}-${noteDate.month}-${noteDate?.date}`
			};
		}
		if (sessionChecked === true) {
			payload.session_checked = sessionChecked;
		}
		if (type === "esign") {
			payload.signed_by = admin_id;
		}
		if (noteValue !== null) {
			fetchAdminApi(`/add-notes`, "post", payload).then((res) => {
				if (res && res.code && res.code === 200) {
					setNoteDate({
						...noteDate,
						date: current.getDate(),
						month: String(current.getMonth() + 1),
						year: current.getFullYear()
					});
					message.success(res.message);
				} else {
					message.error(res.message);
				}
			});
		}
		hideModal();
	};
	const handleSelectChange = (value) => {
		setSelectValue(value);
	};
	const handleInputChange = (value) => {
		setInputValue(value);
	};
	const fetchCountries = () => {
		fetchApi(`/countries`, "get").then((res) => {
			if (res && res.data && res.data.length > 0) {
				setCountries(res.data);
			} else {
				setCountries([]);
			}
		});
	};
	const handleDayChange = (value) => {
		setDay(value);
	};
	const handleMonthChange = (value) => {
		setMonth(value);
	};
	const handleYearChange = (value) => {
		setYear(value);
	};
	//payment date onchange
	const handlePaymentDayChange = (value) => {
		setPaymentDate({
			...paymentDate,
			date: value
		});
	};
	const handlePaymentMonthChange = (value) => {
		setPaymentDate({
			...paymentDate,
			month: value
		});
	};
	const handlePaymentYearChange = (value) => {
		setPaymentDate({
			...paymentDate,
			year: value
		});
	};
	//date of admission date onchange
	const handleDoaDayChange = (value) => {
		setDateOfAddmission({
			...dateOfAddmission,
			date: value
		});
	};
	const handleDoaMonthChange = (value) => {
		setDateOfAddmission({
			...dateOfAddmission,
			month: value
		});
	};
	const handleDoaYearChange = (value) => {
		setDateOfAddmission({
			...dateOfAddmission,
			year: value
		});
	};
	//date of notes date onchange
	const handleNoteDayChange = (value) => {
		setNoteDate({
			...noteDate,
			date: value
		});
	};
	const handleNoteMonthChange = (value) => {
		setNoteDate({
			...noteDate,
			month: value
		});
	};
	const handleNoteYearChange = (value) => {
		setNoteDate({
			...noteDate,
			year: value
		});
	};
	const fetchStates = (country_code) => {
		fetchApi(`/states/${country_code}`, "get").then(
			(res) => {
				if (res?.data) {
					setTempState(res?.data);
					setListStates(res.data);
				} else {
					setListStates(null);
				}
			}
		);
	};
	const fetchPayment = () => {
		let payPayload = {
			payment_details: "1",
			client_id: clientDetails?.client_id,
		};

		if (isPayChecked) {
			payPayload.transfer_to = "1";
		}

		let documentValue = {
			file_type: "payment-pdf",
			payment_payload: payPayload,
		};
		sessionStorage.setItem("documentValue", JSON.stringify(documentValue));
		window.open(constants.BaseUrl + `/view-document`);
	}
	const fetchEnquiryDetails = (enquiry_id) => {
		let payload = {
			enquiry_id: enquiry_id,
			// is_scholarship:clientRegistrationTypeDetails.is_scholarship,
			// agreement_duration:clientRegistrationTypeDetails.agreement_duration,
			// assigned_to:clientRegistrationTypeDetails.asign_to
		}
		fetchApi(`/get-enquiry`, "post", payload).then((res) => {
			if (res && res.data) {
				setValidateStatus({
					...validateStatus,
					email: res?.data?.client_email?.includes("@opspl.com") || res?.data?.client_email?.includes("@mailinator.com") ? "error" : "",
				});
				setFormValues(res.data, "enquiryDetails");
				setEnquiryDetails(res.data);
				sessionStorage.setItem("enquiryDetails", JSON.stringify(res?.data));
			} else {
				message.error(res.message);
				setEnquiryDetails({});
			}
		});
	};
	//fetching client data
	const fetchClientDetails =  (client_id) => {
		setLoader(true);
		fetchAdminApi(`/client-profile/${client_id}`, "get",).then((res) => {
			if (res && res.data) {
				getSummerySection(client_id);
				setLoader(false);
				setFormValues(res.data, "clientDetails");
				getAssociateList(res?.data?.agreement_duration, res?.data?.is_scholarship);
				setValidateStatus({
					...validateStatus,
					email: res?.data?.client_email.includes("@opspl.com") || res?.data?.client_email?.includes("@mailinator.com") ? "error" : "",
					dob: res?.data?.dob?.year === "1920" && res?.data?.dob?.month === "02" && res?.data?.dob?.day === "29" ? "error" : ""
				});
				const stylesValidation = { border: '1px solid rgba(250, 70, 72)', };
				setStyles(res?.data?.client_contact_number === "00000" ? stylesValidation : "");
				if (res.data.country_code === "US") {
					setSelectValue("USD");
				} else {
					setSelectValue("INR");
				}
				setClientDetails(res.data);
				let sectionData = res.data.sections_info;
				let planCompleted = res?.data?.plan_completed;
				let tempArray = [];
				// let loginType = getCookie(COOKIE.LoginType);
				for (let i = 1; i < 9; i++) {
					let index = sectionData?.findIndex((item) => parseInt(item.section) === i);
					if (index > -1) {
						tempArray.push({
							key: i,
							section: `Section ${i}`,
							date_enabled: sectionData[index].date_enabled,
							date_completed: sectionData[index].date_completed,
							is_edited: sectionData[index].is_edited,
							status: sectionData[index].status,
							is_clickable: false,
							viewSections: planCompleted === 1 || res?.data?.is_active === "2" ? true : false,
							sectionId: sectionData[index].section
						});
					} else {
						tempArray.push({
							key: i,
							section: `Section ${i}`,
							date_enabled: "",
							date_completed: "",
							is_edited: "",
							status: "",
							is_clickable: i === 8 || res?.data?.is_active === "2" && res?.data?.payment_info === null ? false : true, //disabling the enable button for section 7 and 8.When there is no sectionData 
							viewSections: false
						});
					}
				}
				//checking to enable section 7 and 8
				if (sectionData?.length > 0) {
					for (let index = 0; index < sectionData?.length; index++) {
						const element = sectionData?.[index];
						if (element?.section === "1" && element?.admin_completed === 1 && res?.data?.is_active !== "2") {
							tempArray[6].is_clickable = true;
						}
						if (element?.section === "7" && element?.admin_completed === 1 && res?.data?.is_active !== "2") {
							tempArray[7].is_clickable = true;
						}
					}
				} if (res?.data?.client_type === "0") {
					//disable section 2 when the user is non adict
					tempArray[1].is_clickable = false;
				}
				let refreshWB = [];
				if (res?.data?.is_active === "3" || res?.data?.is_refresher === 1 || res?.data?.initial_client_id !== res?.data?.client_id) {
					if (!res?.data?.sections_info) {
						refreshWB.push({
							date_completed: "",
							date_enabled: "",
							is_clickable: true,
							is_edited: "",
							key: 100,
							section: "Refresher Workbook",
							status: "",
							viewSections: planCompleted === 1 ? true : false,
							sectionId: 100
						});
					} else {
						let tableData = res?.data?.sections_info;
						tableData[0].viewSections = planCompleted === 1 ? true : false
						tableData[0].sectionId = 100
						refreshWB = tableData;
					}
				}
				setTableData(res?.data?.initial_client_id === res?.data?.client_id || res?.data?.initial_client_id === null ? tempArray : refreshWB);
			}
			else {
				setTableData([]);
				setLoader(false);
			}
		});
	};
	//To set Form Values
	const setFormValues = (value, type) => {
		let admin_id = getCookie(COOKIE.ClientId);
		if (type === "clientDetails") {
			if (value?.plan_completed === 1 || value?.is_active === "2") {
				setIsWorkbookComplete(true);
			} else {
				setIsWorkbookComplete(false);
			}
			if (value?.date_of_admission) {
				let dateOfAdmission = value?.date_of_admission;
				let dateParts = dateOfAdmission?.split("-");
				setDateOfAddmission({
					...dateOfAddmission,
					date: parseInt(dateParts[2]),
					year: parseInt(dateParts[0]),
					month: dateParts[1],
				});
			};
			if (value?.enquiry_info?.dt_created) {
				let dateofenquiry = value?.enquiry_info?.dt_created;
				let dateParts = dateofenquiry?.split("-");
				setDateOfEnquiry({
					...dateOfEnquiry,
					date: parseInt(dateParts[2]),
					year: parseInt(dateParts[0]),
					month: dateParts[1],
				});
			};
			setGender(value?.gender);
			// setOtherGender(value.gender_details);
			setPaymentReceived(value?.payment_info?.length > 0 ? true : false);
			setYear(parseInt(value?.dob?.year));
			setMonth(value?.dob?.month);
			setDay(parseInt(value?.dob?.day));
			let optionTemp = {};
			optionTemp.key = value.country_code;
			onChangeCountry(null,optionTemp);
			if(parseInt(value?.assigned_id) !== parseInt(admin_id) ){
				form.setFieldsValue({associate_name : value?.assigned_id})
			}
			const formatAmount = (amountSplit) => {
				if (!amountSplit) return '';
				const [currency, amount] = amountSplit?.split(' ');
				return `${currency} ${formatNumber(amount, currency)}`;
			};

			let Amount = formatAmount(value?.amount);
			let TotalAmt = formatAmount(value?.total_plan_amount);
			let CommAmt = formatAmount(value?.enquiry_info?.amount);
			form.setFieldsValue({
				firstName: value?.client_firstname,
				lastName: value?.client_lastname,
				email: value?.client_email,
				country: value?.country_id,
				state: value?.region_id,
				city: value?.city,
				contactNo: value?.client_contact_number,
				typeaddict: value?.client_type ? parseInt(value?.client_type) : typeAddict,
				fullcost: value?.is_scholarship,
				amount: Amount,
				totalAmount: TotalAmt,
				agreement_duration: value?.agreement_duration ? parseInt(value?.agreement_duration):"",
				date_of_admission: value?.date_of_admission ? moment(value?.date_of_admission).format(constants.dateformat) : "",
				is_minor: value?.is_minor,
				forceclosedetails: value?.enquiry_info?.closed_details,
				gender:value?.gender,
				// associate_name : value?.assigned_id,
				commitment: CommAmt,
				session: value?.enquiry_info?.session !== null ? value?.enquiry_info?.session : "6",
				shared_with:value?.shared_id
			});
		} else if (type === "enquiryDetails") {
			if (value?.dt_created) {
				let dateofenquiry = value?.dt_created;
				let dateParts = dateofenquiry?.split("-");
				setDateOfEnquiry({
					...dateOfEnquiry,
					date: parseInt(dateParts[2]),
					year: parseInt(dateParts[0]),
					month: dateParts[1],
				});
			};
			const serviceValues = value?.services?.split(',') || [];
			const serviceLabels = serviceValues.map(value => {
				const service = servicesCheckbox.find(service => service.value === value);
				return service ? service.label : null;
			}).filter(label => label !== null);
			setIsForceClosed(value?.closed_info?.is_closed === "t" ? true : false);
			setPaymentReceived(value?.payment_info?.length > 0 ? true : false);
			setYear(parseInt(value?.dob?.year));
			setMonth(value?.dob?.month);
			setDay(parseInt(value?.dob?.day));
			let optionTemp = {};
			optionTemp.key = value.country_code;
			onChangeCountry(null, optionTemp);
			const formatAmount = (amountSplit) => {
				if (!amountSplit) return '';
				const [currency, amount] = amountSplit?.split(' ');
				return `${currency} ${formatNumber(amount, currency)}`;
			};

			let Amount = formatAmount(value?.amount);
			let TotalAmt = formatAmount(value?.total_plan_amount);
			form.setFieldsValue({
				firstName: value?.client_firstname,
				lastName: value?.client_lastname,
				email: value?.client_email,
				client_contact_number: value?.client_contact_number,
				parentfirstName: value?.parent_firstname,
				parentlastName: value?.parent_lastname,
				parentEmail: value?.parent_email,
				parent_contact_number: value?.parent_contact_number,
				is_minor: value?.is_minor,
				forceclosedetails: value?.closed_info?.closed_details,
				city: value?.city,
				country: value?.country_id,
				state: value?.region_id,
				source: value?.source,
				is_self: value?.is_self,
				source_details: value?.source_details,
				more_details: value?.more_details,
				your_firstname: value?.your_firstname,
				your_lastname: value?.your_lastname,
				your_email: value?.your_email,
				your_contact_number: value?.your_contact_number,
				caller_firstname: value?.payment_firstname,
				caller_lastname: value?.payment_lastname,
				caller_email: value?.payment_email,
				caller_contact_number: value?.payment_contact_number,
				caller_relation: value?.payment_relation,
				interested_in: value?.interested_in,
				services: serviceLabels,
				session: value?.session !== null ? value?.session : "6",
				amount: Amount,
				totalAmount: TotalAmt,
				enquiryNotes: value?.enquiry_notes,
			});
		}
		if (props && props.location && props.location.state && props.location.state.enable) {
			document.getElementById('secTable').scrollIntoView({ behavior: "smooth" });
		}
	};
	const forceClosed = (type, forceclosedetails) => {
		let payload;
		payload = {
			is_closed: isForceClosed === true ? "t" : "f",
			closed_details: forceclosedetails
		};
		if (type === "enquiry") {
			payload.enquiry_id = enquiryDetails?.enquiry_id;
		} else {
			payload.client_id = clientDetails?.client_id;
		}
		fetchAdminApi(`/force-close-client`, 'post', payload).then((res) => {
			if (res && res.code && res.code === 200) {
				message.success(res.message);
				if (props && props.location && props.location.state && props.location.state.enquiry_id || enquiryDetailsfromSession?.enquiry_id) {
					fetchEnquiryDetails(enquiryDetails?.enquiry_id);
				} else {
					fetchClientDetails(clientDetails?.client_id);
				}
			} else {
				message.error(res.message);
			}
		});
	};
	//To Submit the values from the client profile and enquiry form to the API.
	const onFinish = (values) => {
		let nopopup = values?.email.includes("@opspl.com") || values?.email.includes("@mailinator.com") || isForceClosed || enquiryDetailsfromSession;
		if (nopopup) {
			saveClientAndEnquiryDetails(values);
			setIsEmailChangeModalOpen(false);
		} else {
			emailchangeForm.resetFields();
			setCheckEmailPsdChange({
				...checkEmailPsdChange,
				email: false,
				password: false
			});
			setformValues(values);
			setIsEmailChangeModalOpen(true);
		}
	};
	const saveClientAndEnquiryDetails = (values, popupvalue = null) => {
		let payload;
		let url;
		let method;
		if (props && props.location && props.location.state && props.location.state.enquiry_id || enquiryDetailsfromSession?.enquiry_id) {
			if (isForceClosed) {
				forceClosed("enquiry", values?.forceclosedetails);
			}
			url = `/edit-enquiry`;
			method = 'put';
			payload = {
				enquiry_id: enquiryDetails?.enquiry_id,
				client_firstname: values?.firstName,
				client_lastname: values?.lastName,
				client_contact_number: values?.client_contact_number,
				client_email: values?.email,
				is_self: values?.is_self,
				is_minor: values.is_minor,
				token: enquiryDetails?.token,
				parent_firstname: values?.parentfirstName,
				parent_lastname: values?.parentlastName,
				parent_email: values?.parentEmail,
				parent_contact_number: values?.parent_contact_number,
				city: values?.city,
				country_id: values?.country,
				region_id: values?.state,
				source: values?.source,
				source_details: values?.source_details,
				more_details: values?.more_details,
				your_firstname: values?.your_firstname,
				your_lastname: values?.your_lastname,
				your_email: values?.your_email,
				your_contact_number: values?.your_contact_number,
				payment_firstname: values?.caller_firstname,
				payment_lastname: values?.caller_lastname,
				payment_email: values?.caller_email,
				payment_contact_number: values?.caller_contact_number,
				payment_relation: values?.caller_relation,
				interested_in: values?.interested_in,
			};
		} else {
			if (isForceClosed) {
				forceClosed("client", values?.forceclosedetails);
			}
			url = `/client/${clientDetails?.client_id}`;
			method = 'put';
			payload = {
				firstname: values?.firstName,
				lastname: values?.lastName,
				contact_no: values?.contactNo,
				email: values?.email,
				country_id: values?.country,
				region_id: values?.state,
				city: values?.city,
				client_type: values?.typeaddict,
				is_scholarship: values?.fullcost,
				dob: `${year}-${month}-${day}`,
				agreement_duration: values?.agreement_duration,
				is_minor: values?.is_minor,
				dt_created: dateOfAddmission?.date !== null && dateOfAddmission?.month !== null && dateOfAddmission?.year ? `${dateOfAddmission.year}-${dateOfAddmission.month}-${dateOfAddmission.date}` : null,
				gender: values?.gender,
				// gender_details: otherGender,
				amount : values?.amount,
				referred_to: values?.associate_name,
				session:values?.session,
				shared_with:values?.shared_with
			};
			if (popupvalue !== null) {
				payload.login_password = popupvalue.password
			}
		};
		fetchAdminApi(url, method, payload).then((res) => {
			if (res && res.code && res.code === 200) {
				setLoader(false);
				message.success(res.message);
				if (props && props.location && props.location.state && props.location.state.enquiry_id || enquiryDetailsfromSession?.enquiry_id) {
					fetchEnquiryDetails(enquiryDetails?.enquiry_id);
				} else {
					fetchClientDetails(clientDetails?.client_id);
				}
				if (popupvalue !== null) {
					setIsEmailChangeModalOpen(false);
				}
			} else {
				setLoader(false);
				if (popupvalue !== null) {
					setIsEmailChangeModalOpen(true);
				}
				message.error(res.message);
			}
		});
	}
	//Function to check validation errors on click of register button
	const onFinishFailed = (values) => {
		if ((values && values.values && values.values.contactNo === null) ||
			(values && values.values && values.values.contactNo === undefined) ||
			(values && values.values && values.values.contactNo === "")) {
			const stylesValidation = { border: '1px solid rgba(255, 77, 79)', };
			setStyles(stylesValidation);
		}
	};
	const selectFilterOption = (input, option) => {
		return option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) > -1 && option?.props?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) < 1;
	};
	const onChangeCountry = (val, option) => {
		fetchStates(option.key);
		form.setFieldsValue({ state: null });
	};
	//Function to check the contact number and set validation error using css
	const handleContactNo = (value) => {
		if (value === "" || value === null || value === undefined) {
			const stylesValidation = { border: '1px solid rgba(255, 77, 79)', };
			setStyles(stylesValidation);
		} else {
			setStyles(null);
		}
	};
	const handleChangeCity = (rule, value, callback) => {
		try {
			//space not allowed initial and fullstop and hyphen is allowed.
			if (value && !/^[-.]?\S+(\s+\S+)*$/.test(value)) {
				throw new Error(constants.CityRegistration);
			}
			callback(); // < -- this
		} catch (err) {
			callback(err);
		}
	};
	const enableSection = (value) => {
		let payload = {};
		payload.section_id = value?.key;
		payload.flag = "1";
		payload.is_admin = "1";
		setLoader(true);
		fetchAdminApi(`/update-client-sections/${clientDetails?.client_id}`, "put", payload).then((res) => {
			if (res && res.code && res.code === 200) {
				fetchClientDetails(clientDetails?.client_id);
				message.success(res.message);
				setLoader(false);
			} else {
				message.error("Failed to enable section.");
				setLoader(false);
			}
		});
		document.getElementById('top').scrollIntoView({ behavior: "smooth" });
	};
	const onchangeTypeAddict = (e) => {
		setTypeAddict(e.target.value);
	};
	const redirectToClientDashboard = () => {
		// let route = clientDetails?.is_active === "1" ? "/dashboard" :"/refresher-workbook";
		let payload = {
			isAdmin: true,
			clientId: clientDetails?.client_id,
			client_name: clientDetails?.client_firstname + " " + clientDetails?.client_lastname,
			client_serial_number: clientDetails?.client_serial_number
		};
		sessionStorage.setItem("adminClientValue", JSON.stringify(payload));
		window.open(constants.BaseUrl + "/dashboard");
	};
	const routeToListNotes = (clientType) => {
		let adminClientValue = JSON.parse(sessionStorage.getItem("adminClientValue"));
		let payload;
		if (clientType === "client") {
			payload = {
				clientId: props && props.location && props.location.state && props.location.state.client_id || clientDetails?.client_id,
				client_serial_number: clientDetails?.client_serial_number,
				client_name: clientDetails?.client_firstname + " " + clientDetails?.client_lastname
			};
		} else {
			payload = {
				enquiryId: props && props.location && props.location.state && props.location.state.enquiry_id || enquiryDetails?.enquiry_id,
				client_serial_number: enquiryDetails?.enquiry_id,
				client_name: enquiryDetails?.client_firstname + " " + enquiryDetails?.client_lastname
			};
		}
		if (adminClientValue === null) {
			sessionStorage.setItem("adminClientValue", JSON.stringify(payload));
		}
		window.open(constants.BaseUrl + "/notes");
	};
	const onChangePaymentReceived = (e) => {
		setPaymentReceived(e.target.checked);
	};
	const showPaymentModal = (actionType) => {
		setActionType(actionType);
		paymentForm.resetFields();
		paymentForm.setFieldsValue({ paid_by: enquiryDetails?.paid_by ? enquiryDetails?.paid_by : clientDetails?.paid_by })
		let currentDate = moment().format('YYYY-MM-DD');
		let dateParts = currentDate?.split("-");
		setPaymentDate({
			...paymentDate,
			date: parseInt(dateParts[2]),
			month: dateParts[1],
			year: parseInt(dateParts[0])
		});
		setIsPaymentModalOpen(true);
	};
	const handleCancel = (e, type) => {
		if (type === "emailchangepopup") {
			setIsEmailChangeModalOpen(false);
		} else {
			setIsPaymentModalOpen(false);
		}
	};
	const onFinishPayment = (values) => {
		setLoader(true);
		let payload = {
			amount: values?.payment_amount,
			currency: selectValue === "USD" ? "0" : "1",
			payment_details: values?.payment_details,
			dt_payment_received: `${paymentDate.year}-${paymentDate.month}-${paymentDate?.date}`,
			paid_by: actionType === "transfer" ? null : values?.paid_by
		};
		if (clientDetails?.client_id) {
			payload.client_id = clientDetails?.client_id
			if (actionType === "transfer") {
				payload.transfer_to = selectedValue;
			}
		} else {
			payload.enquiry_id = enquiryDetails?.enquiry_id
			if (actionType === "transfer") {
				payload.transfer_to = selectedValue;
			}
		}
		fetchAdminApi(`/save-payment-details`, "post", payload).then((res) => {
			if (res && res.code && res.code === 200) {
				message.success(res.message);
				if (clientDetails?.client_id) {
					fetchClientDetails(clientDetails?.client_id);
				} else {
					fetchEnquiryDetails(enquiryDetails?.enquiry_id);
				}
				setLoader(false);
				setIsPaymentModalOpen(false);
			} else {
				message.error(res.message);
				setLoader(false);
				setIsPaymentModalOpen(true);
			}
		});
	};
	const redirectToFilesListing = () => {
		if (props && props.location && props.location.state && props.location.state.client_id || clientDetails?.client_id) {
			let adminClientValue = JSON.parse(sessionStorage.getItem("adminClientValue"));
			let payload = {
				clientId: props && props.location && props.location.state && props.location.state.client_id || clientDetails?.client_id,
				client_serial_number: clientDetails?.client_serial_number,
				client_name: clientDetails?.client_firstname + " " + clientDetails?.client_lastname
			};
			if (adminClientValue === null) {
				sessionStorage.setItem("adminClientValue", JSON.stringify(payload));
			}
			window.open(constants.BaseUrl + "/documents");
		} else {
			sessionStorage.removeItem("adminClientValue");
			window.open(constants.BaseUrl + "/documents");
		}
	};
	const redirectToFriendsFamilyListing = (clientType) => {
		let adminClientValueFam = JSON.parse(sessionStorage.getItem("adminClientValueFam"));
		let payload
		if (clientType === "client") {
			payload = {
				clientId: props && props.location && props.location.state && props.location.state.client_id || clientDetails?.client_id,
				client_serial_number: clientDetails?.client_serial_number,
				client_name: clientDetails?.client_firstname + " " + clientDetails?.client_lastname
			};
		} else {
			payload = {
				enquiryId: props && props.location && props.location.state && props.location.state.enquiry_id || enquiryDetails?.enquiry_id,
				client_serial_number: enquiryDetails?.enquiry_id,
				client_name: enquiryDetails?.client_firstname + " " + enquiryDetails?.client_lastname
			};
		}
		if (adminClientValueFam === null) {
			sessionStorage.setItem("adminClientValueFam", JSON.stringify(payload));
		}
		window.open(constants.BaseUrl + "/family-and-friends");
	};
	const handleResend = (client_id) => {
		setLoader(true);
		const payload = { client_id };

		fetchApi(`/client/register/resend-activation-link`, "post", payload)
			.then((res) => {
				const successMessage = res?.email_data?.message || "Failed to resend email. Please try again.";
				message[res?.email_data?.code === 200 && res?.code === 200 ? 'success' : 'error'](successMessage);
			})
			.catch((error) => {
				if (error?.response?.data) {
					const errorMessage = error.response.data.message || "Failed to resend email. Please try again.";
					message.error(errorMessage);
				} else {
					message.error('Failed to resend email. Please try again.');
				}
			})
			.finally(() => {
				setLoader(false);
			});
	};		
	const formItemsFnLnEID = (f_name, l_name, e_id, validateStatus, showIcon) => {
		return <>
			<Col xs={24} lg={8}>
				<Form.Item
					name={f_name}
					label={constants.FirstName}
					rules={[
						{
							required: true,
							message: constants.FirstNameRegistration,
						},
					]}
				>
					<Input autoFocus placeholder={constants.FirstName} />
				</Form.Item>
			</Col>
			<Col xs={24} lg={8}>
				<Form.Item
					name={l_name}
					label={constants.LastName}
					rules={[
						{
							required: true,
							message: constants.LastNameRegistration,
						},
					]}
				>
					<Input autoFocus placeholder={constants.LastName} />
				</Form.Item>
			</Col>
			<Col xs={24} lg={8}>
				<Form.Item
					name={e_id}
					label={
						<>
							{constants.Email}
							{showIcon && (
								<Button type="link" icon={<MailOutlined />} onClick={routeToRegistarionLink} />
							)}
						</>
					}
					validateStatus={validateStatus}
					rules={[
						{
							required: true,
							message: constants.EmailRegistration,
						}
					]}
				>
					<Input
						placeholder={constants.EmailPlaceholder}
					/>
				</Form.Item>
			</Col>
		</>;
	};
	const getStatus = (status) => {
		const mapStatus = {
			"0": "Pending",
			"1": "Active",
			"2": "Closed",
			"3": "Refresher",
		};
		const mapStatusColor = {
			"0": "red",
			"1": "blue",
			"2": "default",
			"3": "green",
		};
		return <Tag className='text-base' color={mapStatusColor[status]}>{mapStatus[status]}</Tag>;
	};
	const getEnquiryStatus = (status) => {
		const mapStatus = {
			"0": "New",
			"1": "Parent Consent Requested",
			"2": "Parent Consent Received",
			"3": "Registration Link Sent",
			"4": "Registered (Awaiting Docs)",
			"6": "Agreements Signed (Awaiting Payment)",
			"7": "Fee Plan Sent to Caller",
			"8": "Payment Received from Caller",
			"9": "Payment Received"
		};
		return <Tag className='text-base' color="blue">{mapStatus[status]}</Tag>;
	};
	const redirectToClosedEnquiry = () => {
		sessionStorage.setItem("enquiryDetails", JSON.stringify(clientDetails?.enquiry_info));
		window.open(constants.BaseUrl + "/edit-enquiry");
	};
	const headerForm = (type) => {
		let clientType;
		let label;
		let firstName;
		let lastName;
		let serialNo;
		let icon;
		if (props && props.location && props.location.state && props.location.state.enquiry_id || enquiryDetailsfromSession?.enquiry_id) {
			icon = <FileUnknownTwoTone className="text-2xl" />;
			label = constants.EditEnquiry;
			firstName = enquiryDetails?.client_firstname;
			lastName = enquiryDetails?.client_lastname;
			serialNo = enquiryDetails?.enquiry_id;
			clientType = "enquiryUser";
		} else {
			icon = clientDetails?.is_active === "3" || clientDetails?.is_refresher == 1 ? <ReloadOutlined className="text-blue-700 text-2xl custom-icon" /> : <UserOutlined className="text-blue-700 text-2xl custom-icon" />;
			label = constants.Profile;
			firstName = clientDetails?.client_firstname;
			lastName = clientDetails?.client_lastname;
			serialNo = clientDetails?.client_serial_number;
			clientType = "client";
		}
		if (type === "title") {
			return <>
				<Space>
					<span className='text-2xl font-semibold'>{icon} <span>{label} - {firstName} {lastName} (#{serialNo})</span></span>
					{clientDetails?.is_active && (getStatus(clientDetails?.is_active))}
					{enquiryDetails?.status && (getEnquiryStatus(enquiryDetails?.status))}
					{clientDetails?.plan_completed === 1 && (<span>{constants.PlanCompleted}</span>)}
				</Space>
			</>;
		} else {
			return <Space wrap>
				{history.length > 1 && (<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={() => history.goBack()}>{constants.BackBtn}</Button>)}
				<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={() => routeToListNotes(clientType)}>{constants.ViewNotes}</Button>
				<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={() => addNotes(clientType)}>{constants.AddNote}</Button>
				{
					props && props.location && props.location.state && props.location.state.enquiry_id || enquiryDetailsfromSession?.enquiry_id ?
						<>
							<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={redirectToFilesListing}>{constants.DocumentList}</Button>
							<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={() => redirectToFriendsFamilyListing(clientType)}>{constants.FamilyAndFriends}</Button>
						</> :
						<>
							<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={redirectToClientDashboard}>{clientDetails?.is_active === "1" ? constants.AdminWorkbook : constants.RefresherWorkSheet}</Button>
							<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={redirectToFilesListing}>{constants.DocumentList}</Button>
							{clientDetails?.is_active === "2" ?
								<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={redirectToPlanPage}>{constants.Refresher}</Button>
								: null}
							<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={redirectToClosedEnquiry}>{constants.Enquiry}</Button>
							<Button size='small' className='text-xs font-semibold bg-sky-800 text-white uppercase' onClick={() => redirectToFriendsFamilyListing(clientType)}>{constants.FamilyAndFriends}</Button>
						</>
				}
			</Space>
		}
	};
	const redirectToPlanPage = () => {
		let payload = {};
		payload = clientDetails;
		payload.userType = "refresher";
		sessionStorage.setItem("enquiryDetails", JSON.stringify(payload));
		window.open(constants.BaseUrl + "/registration-plans");
	};
	const onChangeForceClose = (e) => {
		setIsForceClosed(e.target.checked);
	};
	const selectAfter = (
		<Select
			style={{ width: 100 }}
			value={selectValue}
			onChange={handleSelectChange}
			placement="bottomLeft"
			className="select-item-bold"
		>
			<Option value="INR">INR (₹)</Option>
			<Option value="USD">USD ($)</Option>
		</Select>
	);
	const formatNumber = (value, currency) => {
		if (selectValue === 'INR' || currency === 'INR') {
			return `${value}`
				.replace(/(\d)(?=(\d\d)+\d(\.\d{0,2})?$)/g, '$1,');
		} else {
			return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		}
	};
	const parseNumber = (value) => {
		return value ? value.replace(/\$\s?|(,*)/g, '') : '';
	};
	const footerForm = (
		<Col xs={24} lg={24} className="mt-3.5">
			<Form.Item {...tailLayout}>
				<Space className="float-right">
					<Button
						className='bg-sky-800 text-white uppercase'
						onClick={() => { history.goBack(); }}
					>
						{constants.Cancel}
					</Button>
					<Button
						className='bg-sky-800 text-white uppercase'
						htmlType="submit"
					>
						{constants.Save}
					</Button>
				</Space>
			</Form.Item>
		</Col>
	);
	const forceClodsedComponant = (
		<Row>
			<Col xs={24} lg={8}>
				<Form.Item name="forceclosed" className="!mb-0">
					<Checkbox checked={isForceClosed} onChange={onChangeForceClose}>Force Closed</Checkbox>
				</Form.Item>
			</Col>
			<Col xs={24} lg={16}>
				{isForceClosed && (
					<div className="flex items-center gap-2 pb-3">
						<label>Reason</label>
						<Form.Item name="forceclosedetails" className="!mb-0 !w-full">
							<TextArea
								placeholder={"Reason"}
								type="text"
								autoSize={true}
								className='!w-full'
							/>
						</Form.Item>
					</div>
				)}
			</Col>
		</Row>
	);
	const onchangeWorkbookComplete = (e) => {
		if (e.target.checked === true) {
			setPopOverDescription("Are you sure you want to close the workbook section?");
		} else {
			setPopOverDescription("Are you sure you want to resume the workbook section?");
		}
		setIsWorkbookComplete(e.target.checked);
	};
	const routeToUser = (id) => {
		let payload = { clientId: id };
		sessionStorage.setItem("adminClientValue", JSON.stringify(payload));
		window.open(constants.BaseUrl + "/edit-client");
	};
	const getForm = (formType) => {
		setNoteTypeValue(formType);
		let payload = { note_type: formType };
		if (enquiryDetailsfromSession?.enquiry_id) {
			payload.enquiry_id = enquiryDetailsfromSession?.enquiry_id;
		} else {
			payload.client_id = clientDetails?.client_id;
		}
		fetchAdminApi("/notes", 'post', payload).then((res) => {
			if (res?.code === 200) {
				setNoteValue(res?.data?.data);
				if (res?.data?.is_note_answered === 1) {
					setIsNoteAnswered(true);
				} else {
					setIsNoteAnswered(false);
				}
			} else {
				setNoteValue([]);
			}
		});
	};
	const contactFormItem = (p_no, borderred) => {
		let borderColor;
		if (borderred === "border-red") {
			borderColor = { border: '1px solid rgba(250, 70, 72)', };
		}
		return <Col xs={24} lg={8}>
			<Form.Item
				label={constants.phone}
				name={p_no}
				rules={[
					{
						required: true,
						message: constants.PhoneNoRegistration,
					},
				]}
			>
				<PhoneInput preferredCountries={["us", "in"]} className='w-full' country={"in"} style={borderColor} onChange={handleContactNo} placeholder="1(999)123-4567" />
			</Form.Item>
		</Col>;
	};
	const routeToRegistarionLink = () => {
		let payload = {};
		payload = enquiryDetails;
		payload.client_name = enquiryDetails?.client_firstname + " " + enquiryDetails?.client_lastname;
		payload.userType = "new";
		payload.paidBy = "caller";
		sessionStorage.setItem("enquiryDetails", JSON.stringify(payload));
		// sessionStorage.setItem("openModal", "true");
		window.open(constants.BaseUrl + "/registration-plans");
	};
	const confirm = () => {
		setLoader(true);
		let payload = {
			client_id: clientDetails?.client_id,
			workbook_complete: isWorkbookComplete === true ? '1' : '0'
		};
		fetchAdminApi(`/workbook-complete`, "post", payload).then((res) => {
			if (res?.code === 200) {
				fetchClientDetails(clientDetails?.client_id);
				message.success(res.message);
				setLoader(false);
			} else {
				message.error(res.message);
				setLoader(false);
			}
		});
	};
	const cancel = () => {
		setIsWorkbookComplete(clientDetails?.plan_completed === 0 || clientDetails?.is_active !== "2" ? false : true);
	};
	const planCompletedComponant = (
		<Form.Item className='mt-2' name="plan_completed">
			<Popconfirm
				title={constants.PlanCompleted}
				description={popOverDescription}
				onConfirm={confirm}
				onCancel={cancel}
				okText="Yes"
				cancelText="No"
				icon={<CheckOutlined className='!text-sky-500' />}
			>
				<Checkbox /* disabled={clientDetails?.payment_info?.length > 0 ? false : true}*/ checked={isWorkbookComplete} onChange={onchangeWorkbookComplete} >{constants.PlanCompleted}</Checkbox>
			</Popconfirm>
		</Form.Item>
	);
	const onChangeGender = (e) => {
		setGender(e.target.value);
	};
	// const onChangeOtherGender = (e) => {
	// 	setOtherGender(e.target.value);
	// };
	// const openNewWindow = () => {
	//   // URL of the page you want to open in the new window
	//   const newPageUrl = constants.BaseUrl+location.pathname;
	//   // Open the new window
	// 	addNotes();
	//   window.open(newPageUrl, '_blank','width=800,height=600');
	// };
	const onFinishEmailChange = (value) => {
		setLoader(true);
		saveClientAndEnquiryDetails(formValues, value);
	};

	const getSharedAssociateList = () =>{
		fetchAdminApi(`/view-associates`, "post").then((res) => {
			if (res?.data) {
				let id = getCookie(COOKIE.ClientId);
				if(admintype === "1"){
					const { newArray } = removeItemsById(res?.data, ["1"]);
					setSharedAssociatesList(newArray);
				}else{
					const { newArray,removedItems } = removeItemsById(res?.data, ["1",id]);
					let tempArray = removedItems;
					let adminid = tempArray.findIndex((client)=>client.client_id === "1");
					//object to add on top
					let obj = tempArray[adminid];
					let updateArray = newArray;
					updateArray = [obj, ...updateArray];
					setSharedAssociatesList(adminid > -1 ?updateArray:newArray);
				}
			}else {
				setListAssociates([]);
			};
		});
	}
	const getAssociateList = (duration,scholarship) => {
		let payload = null
		if (duration !== null && scholarship !== null) {
			payload = {
				// is_scholarship: scholarship,
				duration: duration
			};
		}
		fetchAdminApi(`/view-associates`, "post", payload !== null ? payload : null).then((res) => {
			if (res?.data) {
				// let id = getCookie(COOKIE.ClientId);
				// if(admintype === "1"){
				// 	const { newArray } = removeItemsById(res?.data, ["1"]);
				// 	setListAssociates(newArray);
				// }else{
				// 	const { newArray,removedItems } = removeItemsById(res?.data, ["1",id]);
				// 	let tempArray = removedItems;
				// 	let adminid = tempArray.findIndex((client)=>client.client_id === "1");
				// 	//object to add on top
				// 	let obj = tempArray[adminid];
				// 	let updateArray = newArray;
				// 	updateArray = [obj, ...updateArray];
				// 	setListAssociates(adminid > -1 ?updateArray:newArray);
				// }
				const firstItem = res?.data?.filter(obj => obj?.client_id === "1")[0];
				// Filter out the remaining items excluding the one with client_id equal to "1"
				const remainingItems = res?.data?.filter(obj => obj?.client_id !== "1");
				// Concatenate the firstItem with the remainingItems
				let sortedData;
				if(firstItem){
					sortedData = [firstItem, ...remainingItems];
				}else{
					sortedData = remainingItems
				}
				setListAssociates(sortedData);
			}else {
				setListAssociates([]);
			};
		});
	};

	const removeItemsById = (array, idsToRemove) => {
		const removedItems = [];
		const newArray = array.filter(item => {
			if (idsToRemove.includes(item.client_id)) {
				removedItems.push(item);
				return false; // Exclude this item from the new array
			}
			return true; // Include all other items
		});
		return { newArray, removedItems };
	};
	const onChangeAgreementDrn = (option) => {
		getAssociateList(option, clientDetails?.is_scholarship);
	};
	const onChangePlan = (option) => {
		getAssociateList(clientDetails?.agreement_duration, option);
	};
	const getSummerySection = (id) => {
		setSummeryLoader(true);
		fetchAdminApi(`/view-client-summary/${id}`, "get").then((res) => {
			if (res.code === 200) {
				setSummeryLoader(false);
				setSummarySection({
					summeryData:res?.data?.summary,
					moreDetails:res?.data?.more_details
				});
			} else {
				setSummeryLoader(false);
			}
		});
	};
	return (
		<Spin spinning={loader} size="large">
			<Layout className="label-default">
				<Layout>
					<Content className='mx-2 md:mx-6 md:my-8'>
						<Form
							className='label-style'
							form={form}
							onFinish={onFinish}
							onFinishFailed={onFinishFailed}
							{...verticalLayout}
							initialValues={{ "forceclosed": isForceClosed }}
						>
							<div className={`${collapsed ? "grid place-items-center" : ""} mb-4`}>
								<Card
									title={<div><Space direction="vertical" className='py-5' size={'small'} wrap>
										{headerForm("title")}
										{headerForm("ertra")}
									</Space>
										<Button
											type="primary" shape="circle"
											className="bg-sky-800 text-white absolute right-[-13px] top-14"
											disabled={props?.location?.state?.enquiry_id ? true : false}
											icon={collapsed ? <LeftOutlined /> : <RightOutlined />}
											onClick={() => setCollapsed(!collapsed)}
										/>
									</div>}
									className={collapsed ? "lg:w-4/5" : "lg:w-full"}
								>
									<Row gutter={[24, 0]}>
										{props && props.location && props.location.state && props.location.state.enquiry_id || enquiryDetailsfromSession?.enquiry_id ?
											<>
												<Divider><span className="font-semibold">{constants.clientDetailFormheader} ({enquiryDetailsfromSession?.is_minor === "0" ? constants.Adult : constants.Minor})</span></Divider>
												{formItemsFnLnEID("firstName", "lastName", "email", enquiryDetails?.client_email?.includes("@opspl.com") || enquiryDetails?.client_email?.includes("@mailinator.com") ? "error" : "", true)}
												<Col xs={24} lg={8}>
													<Form.Item
														label={constants.country}
														name="country"
														rules={[
															{
																required: true,
																message: constants.CountryRegistration,
															},
														]}
													>
														<Select
															onChange={onChangeCountry}
															placeholder={"Country"}
															className="w-100"
															showSearch
															allowClear
															filterOption={selectFilterOption}>
															{listCountries &&
																listCountries.map((listCountry) => (
																	<Option key={listCountry.country_code} value={listCountry.country_id}>
																		{listCountry.country_name}
																	</Option>
																))}
														</Select>
													</Form.Item>
												</Col>
												<Col xs={24} lg={8}>
													<Form.Item
														label={constants.state}
														name="state"
														rules={[
															{
																required: true,
																message: constants.StateRegistration,
															},
														]}
													>
														<Select
															// value={u_id}
															placeholder={constants.state}
															allowClear
															showSearch
															// onChange={onChangeState}
															filterOption={selectFilterOption}
														>
															{tempState ?
																// eslint-disable-next-line no-unused-vars
																Object.entries(tempState).map(([key, value]) => (
																	<>
																		{value &&
																			value.states &&
																			value.states.length > 0 &&
																			value.states.map((state) => (
																				<Option
																					key={state.region_id}
																					value={state.region_id}
																				// region={state.region_id}
																				>
																					{state.region_name}
																				</Option>
																			))}
																	</>
																)) :
																listStates &&
																// eslint-disable-next-line no-unused-vars
																Object.entries(tempState ? tempState : listStates).map(([key, value]) => (
																	<>
																		{value &&
																			value.states &&
																			value.states.length > 0 &&
																			value.states.map((state) => (
																				<Option
																					key={state.region_id}
																					value={state.region_id}
																				// universityN={state.university_name}
																				// region={state.region_id}
																				>
																					{state.region_name}
																				</Option>
																			))}
																	</>
																))
															}
														</Select>
													</Form.Item>
												</Col>
												<Col xs={24} lg={8}>
													<Form.Item
														label={constants.city}
														name="city"
														rules={[
															{
																required: true,
																message: constants.CityRegistration,
															},
															{ validator: handleChangeCity }
														]}
													>
														<Input
															placeholder={constants.city}
															type="text"
														/>
													</Form.Item>
												</Col>
												{contactFormItem("client_contact_number", enquiryDetails?.client_contact_number === "00000" ? "border-red" : "")}
												<Col xs={24} lg={8}>
													<Form.Item
														label={"Calling For"}
														name="is_self"
														colon={true}
													>
														<Radio.Group >
															<Radio value={"1"}>{constants.Self}</Radio>
															<Radio value={"0"}>{constants.Other}</Radio>
														</Radio.Group>
													</Form.Item>
												</Col>
												<Col xs={24} lg={8}>
													<Form.Item
														label={"For"}
														name="is_minor"
														colon={true}
													>
														<Radio.Group >
															<Radio value={"0"}>Adult</Radio>
															<Radio value={"1"}>Minor</Radio>
														</Radio.Group>
													</Form.Item>
												</Col>
												<Col xs={24} lg={8}>
													<Form.Item
														label={constants.Source}
														name={"source"}
													>
														<Select
															size="middle"
															placeholder={constants.SourcePlaceHolder}
															options={constants.SourceOption}
														/>
													</Form.Item>
												</Col>
												{enquiryDetailsfromSession?.source === "8" &&
													<Col xs={24} lg={8}>
														<Form.Item
															name={"source_details"}
															label={"Source Details"}
														>
															<Input
																type="text"
															/>
														</Form.Item>
													</Col>}
												<Col span={collapsed ? 8 : 24}>
													{DOB(dateOfEnquiry.month, null, dateOfEnquiry.date, null, dateOfEnquiry.year, null, constants.Doe, true, null)}
												</Col>
												<Col xs={24} lg={8}>
													<Form.Item
														name={"interested_in"}
														label={constants.InterestIn}
													>
														<Input
															type="text"
															disabled={true} />
													</Form.Item>
												</Col>
												<Col xs={24} lg={8}>
													<Form.Item label={enquiryDetails?.agreement_duration === "0" ? constants.SessionCost:constants.PlanCost} name="amount">
														<Input
															placeholder={enquiryDetails?.agreement_duration === "0"? constants.SessionCost:constants.PlanCost}
															type="text"
															readOnly={true}
														/>
													</Form.Item>
												</Col>
												<Col xs={24} lg={8}>
													<Form.Item label={constants.Services} name="services">
														<Select
															mode="multiple"
															placeholder="Services"
															className="w-100"
															disabled={true}
														/>
													</Form.Item>
												</Col>
												{
													enquiryDetails?.agreement_duration === "0" ?
														<Col xs={24} lg={8}>
															<Form.Item
																className="mb-3"
																name={"session"}
																label={constants.Sessions}
																readOnly={true}
															>
																<InputNumber
																	min={1}
																	className="w-full"
																/>
															</Form.Item>
														</Col> : null
												}
												{
													enquiryDetails?.agreement_duration === "0" ?
														<Col xs={24} lg={8}>
															<Form.Item
																className="mb-3"
																name={"totalAmount"}
																label={constants.TotalCost}
																readOnly={true}
															>
																<InputNumber
																	min={1}
																	className="w-full"
																	disabled={true}
																/>
															</Form.Item>
														</Col> : null
												}
												<Col xs={24} lg={24}>
													<Form.Item
														className="mb-3"
														name={"enquiryNotes"}
														label={constants.NotesEditClientField}
														readOnly={true}
													>
														<TextArea
															autoSize
															disabled={true} />
													</Form.Item>
												</Col>
												<Col xs={24} lg={24}>
													<Form.Item
														name={"more_details"}
														label={constants.MoreDetails}
													>
														<TextArea rows={2} />
													</Form.Item>
												</Col>
												{enquiryDetailsfromSession?.is_minor === "1" && (
													<>
														<Divider><span className="font-semibold">Parent Details</span></Divider>
														{formItemsFnLnEID("parentfirstName", "parentlastName", "parentEmail", enquiryDetails?.parent_email?.includes("@opspl.com") || enquiryDetails?.parent_email?.includes("@mailinator.com") ? "error" : "")}
														{contactFormItem("parent_contact_number", enquiryDetails?.parent_contact_number === "00000" ? "border-red" : "")}
													</>
												)}
												{enquiryDetailsfromSession?.is_self === "0" && (
													<>
														<Divider><span className="font-semibold">{constants.OtherDetails}</span></Divider>
														{formItemsFnLnEID("your_firstname", "your_lastname", "your_email", enquiryDetails?.your_email?.includes("@opspl.com") || enquiryDetails?.your_email?.includes("@mailinator.com") ? "error" : "")}
														{contactFormItem("your_contact_number", enquiryDetails?.your_contact_number === "00000" ? "border-red" : "")}
														<Col xs={24} lg={24}>
														</Col>
														<Col xs={24} lg={24}>
														</Col>
													</>
												)}
												{enquiryDetailsfromSession?.paid_by === "2" && (
													<>
														<Divider><span className="font-semibold">{constants.callerDetailFormheader}</span></Divider>
														{formItemsFnLnEID("caller_firstname", "caller_lastname", "caller_email", enquiryDetails?.payment_email?.includes("@opspl.com") || enquiryDetails?.payment_email?.includes("@mailinator.com") ? "error" : "")}
														{contactFormItem("caller_contact_number", enquiryDetails?.payment_contact_number === "00000" ? "border-red" : "")}
														<Col xs={24} lg={8}>
															<Form.Item
																name="caller_relation"
																label={constants.Relation}
																rules={[
																	{
																		required: true,
																		message: constants.RequiredField,
																	},
																]}
															>
																<Input autoFocus placeholder={constants.Relation} />
															</Form.Item>
														</Col>
														<Col xs={24} lg={24}>
														</Col>
														<Col xs={24} lg={24}>
														</Col>
													</>
												)}
												<Col xs={24} lg={24}>{forceClodsedComponant}</Col>
												{footerForm}
												<Divider><span className="font-semibold">{constants.Payment}</span></Divider>
												<Col xs={24} lg={24}>
													<Form.Item name="payment_received">
														<Checkbox checked={paymentReceived} onChange={onChangePaymentReceived}>Payments</Checkbox>
													</Form.Item>
												</Col>
												{paymentReceived && (
													<Col xs={24} lg={24}>
														<div>
															<Space>
																<Button className='bg-sky-800 text-white uppercase mb-2.5' onClick={() => showPaymentModal("add")}>Add Payment</Button>
																<Button className='bg-sky-800 text-white uppercase mb-2.5' onClick={() => showPaymentModal("transfer")}>Transfer Payment</Button>
																{enquiryDetails?.status === "8" && enquiryDetails?.payment_info?.length > 0 ? <a className='text-base' onClick={routeToRegistarionLink}>Send Registration Link to Client</a> : null}
															</Space>
															<Table
															dataSource={enquiryDetails?.payment_info?.length > 0 ? enquiryDetails?.payment_info : []}
															columns={payHisCol}
															pagination={false}
															bordered
															scroll={{ x: 900 }}
															locale={{ emptyText: 'No Records Found' }}
															summary={() => {
																const rupeeTotal = enquiryDetails?.total?.Rupee_total ?? 0;
																const dolarTotal = enquiryDetails?.total?.Dollar_total ?? 0;
																return (
																	<>
																		<Table.Summary.Row>
																			<Table.Summary.Cell index={0} className='font-semibold'>Total</Table.Summary.Cell>
																			<Table.Summary.Cell index={1}>
																			</Table.Summary.Cell>
																			<Table.Summary.Cell index={2}>
																			</Table.Summary.Cell>
																			<Table.Summary.Cell index={3}>
																			</Table.Summary.Cell>
																			<Table.Summary.Cell 
																				index={4}
																				width ="20%"
																				align ="center"
																			>
																				<span className="text-right w-20 block m-auto font-semibold">{formatNumber(rupeeTotal, 'INR')}</span>
																			</Table.Summary.Cell>
																			<Table.Summary.Cell 
																				index={5}
																				width ="20%"
																				align ="center"
																			>
																				<span className="text-right w-20 block m-auto font-semibold">{formatNumber(dolarTotal, 'USD')}</span>
																			</Table.Summary.Cell>
																		</Table.Summary.Row>
																	</>
																);
															}}
														/>
														</div>
													</Col>
												)}
											</>
											:
											<>
												<Divider><span className="font-semibold">{constants.clientDetailFormheader} ({clientDetails?.is_minor === "1" ? constants.Minor : constants.Adult})</span></Divider>
												<Col xs={24} lg={8}>
													<Form.Item
														name="firstName"
														label={constants.FirstName}
														rules={[
															{
																required: true,
																message: constants.FirstNameRegistration,
															},
														]}
													>
														<Input autoFocus placeholder={constants.FirstName} />
													</Form.Item>
												</Col>
												<Col xs={24} lg={8}>
													<Form.Item
														label={constants.LastName}
														name="lastName"
														rules={[
															{
																required: true,
																message: constants.LastNameRegistration,
															},
														]}>
														<Input placeholder={constants.LastName} />
													</Form.Item>
												</Col>
												<Col xs={24} lg={8}>
													<Form.Item
														name="email"
														label={
															<>
																{constants.Email}
																<Button type="link" icon={<MailOutlined />} onClick={() => handleResend(clientDetails?.client_id)} />
															</>
														}
														validateStatus={validateStatus?.email}
														rules={[
															{
																required: true,
																message: constants.EmailRegistration,
															},
															{
																type: 'email',
																message: constants.emailVaidationError,
															},
														]}
													>
														<Input
															placeholder={constants.EmailPlaceholder}
														/>
													</Form.Item>
												</Col>
												<Col xs={24} lg={8}>
													<Form.Item
														label={constants.country}
														name="country"
														rules={[
															{
																required: true,
																message: constants.CountryRegistration,
															},
														]}
													>
														<Select
															onChange={onChangeCountry}
															placeholder={"Country"}
															className="w-100"
															showSearch
															allowClear
															filterOption={selectFilterOption}>
															{listCountries &&
																listCountries.map((listCountry) => (
																	<Option key={listCountry.country_code} value={listCountry.country_id}>
																		{listCountry.country_name}
																	</Option>
																))}
														</Select>
													</Form.Item>
												</Col>
												<Col xs={24} lg={8}>
													<Form.Item
														label={constants.state}
														name="state"
														rules={[
															{
																required: true,
																message: constants.StateRegistration,
															},
														]}
													>
														<Select
															// value={u_id}
															placeholder={constants.state}
															allowClear
															showSearch
															// onChange={onChangeState}
															filterOption={selectFilterOption}
														>
															{tempState ?
																// eslint-disable-next-line no-unused-vars
																Object.entries(tempState).map(([key, value]) => (
																	<>
																		{value &&
																			value.states &&
																			value.states.length > 0 &&
																			value.states.map((state) => (
																				<Option
																					key={state.region_id}
																					value={state.region_id}
																				// region={state.region_id}
																				>
																					{state.region_name}
																				</Option>
																			))}
																	</>
																)) :
																listStates &&
																// eslint-disable-next-line no-unused-vars
																Object.entries(tempState ? tempState : listStates).map(([key, value]) => (
																	<>
																		{value &&
																			value.states &&
																			value.states.length > 0 &&
																			value.states.map((state) => (
																				<Option
																					key={state.region_id}
																					value={state.region_id}
																				// universityN={state.university_name}
																				// region={state.region_id}
																				>
																					{state.region_name}
																				</Option>
																			))}
																	</>
																))
															}
														</Select>
													</Form.Item>
												</Col>
												<Col xs={24} lg={8}>
													<Form.Item
														label={constants.city}
														name="city"
														rules={[
															{
																required: true,
																message: constants.CityRegistration,
															},
															{ validator: handleChangeCity }
														]}
													>
														<Input
															placeholder={constants.city}
															type="text"
														/>
													</Form.Item>
												</Col>
												<Col xs={24} lg={10}>
													<Form.Item
														label={constants.phone}
														name="contactNo"
														rules={[
															{
																required: true,
																message: constants.PhoneNoRegistration,
															},
															// { validator: handleValidatePhone },
														]}
													>
														<PhoneInput preferredCountries={["us", "in"]} className='w-full custom-phone-input' country={"in"} style={styles} onChange={handleContactNo} placeholder="1(999)123-4567" />
													</Form.Item>
												</Col>
												<Col span={collapsed ? 8 : 24}>
													{DOB(month, handleMonthChange, day, handleDayChange, year, handleYearChange, constants.dob, null, validateStatus?.dob)}
												</Col>
												{/* <Col xs={24} lg={8}>
												<Form.Item
													label={"For :"}
													name="is_minor"
												>
													<Radio.Group >
														<Radio value={"0"}>Adult</Radio>
														<Radio value={"1"}>Minor</Radio>
													</Radio.Group>
												</Form.Item>
											</Col> */}
												<Col xs={24} lg={24}>
													<Form.Item
														label={constants.Gender}
														name="gender"
														rules={[
															{
																required: true,
																message: constants.GenderVaidationMessage,
															},
															// { validator: handleValidatePhone },
														]}
													>
														<Radio.Group onChange={onChangeGender} value={gender}>
															<Space wrap>
																<Radio value={constants.Male}>{constants.Male}</Radio>
																<Radio value={constants.Female}>{constants.Female}</Radio>
																{/* <Radio value={constants.Other}>
																	<Space>
																		{constants.Other}
																		{gender === constants.Other ? (
																			<Input
																				className='font-normal'
																				value={otherGender}
																				onChange={onChangeOtherGender}
																			/>
																		) : null}
																	</Space>
																</Radio> */}
															</Space>
														</Radio.Group>
													</Form.Item>
												</Col>
												<Divider><span className="font-semibold">{constants.ConfidentialInfo}</span></Divider>
												<Col span={collapsed ? 8 : 24}>
													{DOB(dateOfAddmission.month, handleDoaMonthChange, dateOfAddmission.date, handleDoaDayChange, dateOfAddmission.year, handleDoaYearChange, "Date of Admission", false)}
												</Col>
												<Col xs={24} lg={8}>
													<Form.Item label={constants.Program} name="agreement_duration">
														<Select placeholder={constants.Program} disabled={false} onChange={onChangeAgreementDrn}>
															{/* <Option
																key={"7"}
																value={"7"}
															>{constants.SevenDays}
															</Option>
															<Option
																key={"30"}
																value={"30"}
															>{constants.ThirtyDays}</Option>
															<Option
																key={"1"}
																value={"1"}
															>{constants.OneDay}</Option>
															<Option
																key={"0"}
																value={"0"}
															>{constants.Unlimited}</Option> */}
															{constants.AgreementOptions.map((option) => (
																<Option
																	key={option.value}
																	value={option.value}
																>
																	{option.label}
																</Option>
															))}
														</Select>
													</Form.Item>
												</Col>
												{/* <Col xs={24} lg={8}>
													<Form.Item name="fullcost" label={constants.Plan}>
														<Select placeholder={constants.Plan} disabled={false} onChange={onChangePlan}>
															<Option value={"0"}>{constants.FullCost}</Option>
															<Option value={"1"}>{constants.Scholarship}</Option>
															<Option value={"2"}>{constants.FriendsAndFamily}</Option>
															<Option value={"3"}>{constants.SpclScholarship}</Option>
															<Option value={"4"}>{constants.General}</Option>
														</Select>
													</Form.Item>
												</Col> */}
												{
													clientDetails?.agreement_duration === "0" ?
														<Col xs={24} lg={8}>
															<Form.Item name="fullcost" label={constants.Plan}>
																<Select placeholder={constants.Plan} disabled={false} onChange={onChangePlan}>
																	{
																		constants.GeneralPlanOptions.map((option) => (
																			<Option
																				key={option.value}
																				value={option.value}
																			>
																				{option.label}
																			</Option>
																		))}
																</Select>
															</Form.Item>
														</Col> :
														null
												}
												<Col xs={24} lg={8} className="pb-2">
													<Form.Item name="typeaddict">
														<Radio.Group value={typeAddict} onChange={onchangeTypeAddict} disabled={false}>
															<Radio value={1}>{constants.addict}</Radio>
															<Radio value={0}>{constants.NotAnAddict}</Radio>
														</Radio.Group>
													</Form.Item>
													{/* <br/> */}
												</Col>
												<Col xs={24} lg={8}>
													<Form.Item
														name="is_minor"
													>
														<Radio.Group disabled={false}>
															<Radio value={"0"}>Adult</Radio>
															<Radio value={"1"}>Minor</Radio>
														</Radio.Group>
													</Form.Item>
												</Col>
												<Col xs={24} lg={8}>
													<Form.Item label={clientDetails?.agreement_duration === "0" ? constants.SessionCost : constants.PlanCost} name="amount">
														<Input
															placeholder={clientDetails?.agreement_duration === "0" ? constants.SessionCost : constants.PlanCost}
															type="text"
															readOnly={true}
														/>
													</Form.Item>
												</Col>
												{
													clientDetails?.agreement_duration === "0" ?
														<Col xs={24} lg={8}>
															<Form.Item
																className="mb-3"
																name={"session"}
																label={constants.Sessions}
																readOnly={true}
															>
																<InputNumber
																	min={1}
																	className="w-full"
																	readOnly={true}
																/>
															</Form.Item>
														</Col> :
														null
												}
												{
													clientDetails?.agreement_duration === "0" ?
														<Col xs={24} lg={8}>
															<Form.Item
																className="mb-3"
																name={"totalAmount"}
																label={constants.TotalCost}
																readOnly={true}
															>
																<InputNumber
																	min={1}
																	className="w-full"
																	disabled={true}
																/>
															</Form.Item>
														</Col> : null
												}
												<Col span={collapsed ? 8 : 24}>
													{DOB(dateOfEnquiry.month, null, dateOfEnquiry.date, null, dateOfEnquiry.year, null, constants.Doe, true, null)}
												</Col>
												{
													clientDetails?.is_scholarship === "3" && (
														<Col xs={24} lg={8}>
															<Form.Item label={constants.SpecialCost} name="commitment">
																<Input
																	placeholder={constants.SpecialCost}
																	type="text"
																	readOnly={true}
																/>
															</Form.Item>
														</Col>
													)
												}
												<Col xs={24} lg={8}>
													<Form.Item label={constants.AssignedTo} name="associate_name">
														<Select
															placeholder={constants.AssignedTo}
															showSearch
															allowClear
															filterOption={selectFilterOption}
														>{
																listAssociates?.map((associate) => <Option value={associate?.client_id} key={associate?.client_id}>{associate?.firstname + ' ' + associate?.lastname}</Option>)
															}
														</Select>
													</Form.Item>
												</Col>
												<Col xs={24} lg={8}>
													<Form.Item label={constants.ShareWith} name="shared_with">
														<Select
															placeholder={constants.ShareWith}
															showSearch
															allowClear
															filterOption={selectFilterOption}
														>
															{sharedAssociatesList?.map((associate) => <Option value={associate?.client_id} key={associate?.client_id}>{associate?.firstname + ' ' + associate?.lastname}</Option>)}
														</Select>
													</Form.Item>
												</Col>
												<Col xs={24} lg={24}>{forceClodsedComponant}</Col>
												{footerForm}
												<Divider><span className="font-semibold">{constants.Payment}</span></Divider>
												<Col xs={24} lg={24}>
													<Form.Item name="payment_received">
														<Checkbox checked={paymentReceived} onChange={onChangePaymentReceived}>Payments</Checkbox>
													</Form.Item>
												</Col>
												{paymentReceived && (
													<Col xs={24} lg={24}>
														<div>
															<Space>
																<Button className='bg-sky-800 text-white uppercase mb-2.5' onClick={() => showPaymentModal("add")}>Add Payment</Button>
																<Button className='bg-sky-800 text-white uppercase mb-2.5' onClick={() => showPaymentModal("transfer")}>Transfer Payment</Button>
															</Space>
															<Space className="float-right items-start">
																<Form.Item>
																	<Checkbox onChange={(e) => setIsPayChecked(e.target.checked)}>Include Transfer Payment</Checkbox>
																</Form.Item>
																<Button className='bg-sky-800 text-white uppercase mb-2.5' onClick={fetchPayment}>Export</Button>
															</Space>
															<Table
																dataSource={clientDetails?.payment_info?.length > 0 ? clientDetails?.payment_info : []}
																columns={payHisCol}
																pagination={false}
																bordered
																scroll={{ x: 900 }}
																locale={{ emptyText: 'No Records Found' }}
																summary={() => {
																	const rupeeTotal = clientDetails?.total?.Rupee_total ?? 0;
																	const dolarTotal = clientDetails?.total?.Dollar_total ?? 0;
																	return (
																		<>
																			<Table.Summary.Row>
																				<Table.Summary.Cell index={0} className='font-semibold'>Total</Table.Summary.Cell>
																				<Table.Summary.Cell index={1}>
																				</Table.Summary.Cell>
																				<Table.Summary.Cell index={2}>
																				</Table.Summary.Cell>
																				<Table.Summary.Cell index={3}>
																				</Table.Summary.Cell>
																				<Table.Summary.Cell
																					index={4}
																					width="20%"
																					align="center"
																				>
																					<span className="text-right w-20 block m-auto font-semibold">{formatNumber(rupeeTotal, 'INR')}</span>
																				</Table.Summary.Cell>
																				<Table.Summary.Cell
																					index={5}
																					width="20%"
																					align="center"
																				>
																					<span className="text-right w-20 block m-auto font-semibold">{formatNumber(dolarTotal, 'USD')}</span>
																				</Table.Summary.Cell>
																			</Table.Summary.Row>
																		</>
																	);
																}}
															/>
														</div>
													</Col>
												)}
											</>
										}
									</Row>
								</Card>
							</div>
							{
								location.pathname === "/edit-client" ?
									clientDetails?.initial_client_id === clientDetails?.client_id || clientDetails?.initial_client_id === null ?
										// clientDetails?.is_active ==="3" || clientDetails?.initial_client_id !== clientDetails?.client_id?
										<>
											{
												admintype === "2" && clientDetails?.payment_info === null ?
													<div className='lg:w-4/5 !mx-auto'>
														<Space className='text-base font-semibold'>
															<span>You will be able to Enable sections once you complete the payment.</span>
														</Space>
													</div>
													:
													null
											}
											<div>
												<Collapse className='lg:w-4/5 !mx-auto mb-2.5'>
													<Panel header={<span className='font-semibold'>Section Details</span>} key="1">
														<Table
															dataSource={tableData}
															columns={columns}
															pagination={false}
															bordered
															id="secTable"
														/>
													</Panel>
												</Collapse>
											</div>
											<div className='lg:w-4/5 !mx-auto'>{planCompletedComponant}</div>
										</>
										:
										<div className='lg:w-4/5 !mx-auto mb-2.5'>
											<Collapse>
												<Panel header={<span className='font-semibold'>Section Details</span>} key="1">
													<Table
														dataSource={tableData}
														columns={columns}
														pagination={false}
														bordered
														id="secTable"
													/>
												</Panel>
											</Collapse>
											{planCompletedComponant}
											{clientDetails?.refresher_links?.length > 0 ?
												<Space split={<Divider className='bg-black' type="vertical" />}>
													{clientDetails?.refresher_links?.map((val) => {
														return <Typography.Link
															disabled={val?.client_id === clientDetails?.client_id ? true : false}
															key={val?.client_id}
															className='text-base'
															onClick={() => routeToUser(val?.client_id)}
														>
															{val?.client_type} {val?.dt_workbook_completed !== null ? `(${moment(val?.dt_workbook_completed, "YYYY-MM-DD").format(constants.dateformat)})` : null}
														</Typography.Link>;
													})}
												</Space>
												:
												null
											}
										</div>
									: null
							}
						</Form>
						{noteValue && notesModel(hideModal, notesVisible, onchangeNoteValue, createNote, getForm, noteValue, noteTypeValue, isNoteAnswered, DOB(noteDate.month, handleNoteMonthChange, noteDate.date, handleNoteDayChange, noteDate.year, handleNoteYearChange, "Date", false), onSessionChange, sessionChecked, false, null, null)}
						<Modal
							title={<div className="text-center">{actionType === "add" ? "Add Payment" : "Transfer Payment"}</div>}
							open={isPaymentModalOpen}
							footer={null}
							centered
							style={{ top: 20 }}
							width={700}
							className="client-details"
							onCancel={(e) => handleCancel(e, "paymentmodal")}
						>
							<Spin spinning={loader} size="large">
								<Form
									labelWrap
									form={paymentForm}
									onFinish={onFinishPayment}
									{...layout}
								>
									<Form.Item
										className="mb-3"
										name={"payment_amount"}
										label={"Amount"}
										rules={[
											{
												required: true,
												message: constants.RequiredField,
											},
										]}
									>
										<InputNumber
											min={1}
											className="w-full"
											value={inputValue}
											onChange={handleInputChange}
											addonBefore={selectAfter}
											formatter={formatNumber}
											parser={parseNumber}
										/>
								</Form.Item>
								<Form.Item
									label={"Payment Details"}
									name="payment_details"
									className="mb-3"
									rules={[
										{
											required: true,
											message: constants.RequiredField,
										},
									]}
								>
									<TextArea autoSize={true} />
								</Form.Item>
								{/* dob used for date picker  */}
								{DOB(paymentDate.month, handlePaymentMonthChange, paymentDate.date, handlePaymentDayChange, paymentDate.year, handlePaymentYearChange, "Date")}
								<Form.Item
									className={actionType === "transfer" ? "-mt-2" : "-mt-3"}
									label={constants.PaidBy}
									name={"paid_by"}
									rules={[
										{
											required: actionType === "add",
											message: constants.RequiredField,
										},
									]}
								>
									{actionType === "add" ? (
										<Radio.Group>
											{clientDetails?.your_firstname ? <Radio value={"1"}>{constants.Caller}</Radio> : null}
											<Radio value={"0"}>{constants.Client}</Radio>
											{clientDetails?.is_self !== "1" ? <Radio value={"2"}>{constants.Other}</Radio> : null}
										</Radio.Group>
									) : (
										<Input defaultValue="RoyCares" disabled />
									)}
								</Form.Item>
									{actionType === "transfer" && (
										<Form.Item
											name="transferred_to"
											label="Transferred to"
											className="-mt-2 mb-3"
											rules={[
												{
													required: true,
													message: constants.RequiredField,
												},
											]}
										>
											<Select
												value={selectedValue}
												onChange={(value) => {
													setSelectedValue(value);
												}}
												placeholder={constants.TransferTo}
												showSearch
												filterOption={selectFilterOption}
											>
												{sharedAssociatesList?.map((associate) => <Option value={associate?.client_id} key={associate?.client_id}>{associate?.firstname + ' ' + associate?.lastname}</Option>)}
											</Select>
										</Form.Item>
									)}
									<Form.Item {...tailLayout}>
										<Space className="float-right">
											<Button
												size="middle"
												onClick={(e) => handleCancel(e, "paymentmodal")}
											>
												{constants.CancelBTN}
											</Button>
											<Button
												size="middle"
												className='bg-sky-800 text-white'
												htmlType="submit"
											>
												{constants.Save}
											</Button>
										</Space>
									</Form.Item>
								</Form>
							</Spin>
						</Modal>
						{welcomeEmailPopup(isEmailChangeModalOpen,handleCancel,loader,emailchangeForm,onFinishEmailChange,onCheckboxChange,checkEmailPsdChange,layout2,true)}
						{/* <Button onClick={openNewWindow}>Open New Window</Button> */}
					</Content>
				</Layout>
				{!props?.location?.state?.enquiry_id && (
					<Sider className="!bg-transparent md:mt-8 mb-4" trigger={null} collapsible collapsed={collapsed} theme="light" width="50%" collapsedWidth={0}>
						<Spin spinning={summeryLoader} size="large">
							<div className="md:mr-6 p-3 rounded-lg !bg-sky-100 h-full">
								<p className='mb-3'>
									<b>{constants.MoreDetails}</b>: {summarySection?.moreDetails}
								</p>
								<div>
									<h1 className="text-center"><strong>Summary</strong></h1>
									{
										summarySection.summeryData?.length > 0 ?
											<div className='h-[1612px] overflow-y-auto'>{summarySection.summeryData.map((data) => {
												return <div className='mt-2 px-2'	key={data?.item_id}>
													<div className='mt-3 font-semibold' dangerouslySetInnerHTML={{ __html: data?.heading }} />
													<TextArea
														className={"bg-gray-200"}
														disabled={true}
														autoSize={true}
														readOnly={true}
														value={data?.response}
													/>
												</div>
											})}</div>: <div>No summary data</div>}
								</div>
							</div>
						</Spin>
					</Sider>
				)}
			</Layout>
		</Spin>
	);
};
export default EditClientProfile;